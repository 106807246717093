// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import MDBox from 'components/MDBox';
import Link from '@mui/material/Link';
import marubeniLogoPrimary from 'assets/images/logos/marubeni/CorporateLogoPrimary.png';

function DefaultNavbar({ brand, transparent }) {
  return (
    <Container>
      <MDBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={1}
        mx={0}
        width="calc(100% - 48px)"
        borderRadius="lg"
        shadow={transparent ? 'none' : 'md'}
        color={'white'}
        position="absolute"
        left={-50}
        zIndex={99}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(background.sidenav, 0.8),
          backdropFilter: transparent ? 'none' : `saturate(200%) blur(30px)`,
        })}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <MDBox
            py={transparent ? 1.5 : 0.75}
            lineHeight={1}
            pl={{ xs: 0, lg: 1 }}>
            <MDBox display="flex" alignItems="center">
              {/* <MDBox component="img" src={brandWhite} alt="Brand" width="5rem" mr={1}/>
              <MDTypography variant="h4" fontWeight="bold" color="white">
                {brand}
              </MDTypography>
              <Grid item xs={1} />
              <MDBox component="img" src={marubeniLogoPrimary} alt='marubeni-logo-primary' width='18rem' mr={1} /> */}
              <MDBox component={Link} href="https://www.marubeni-sys.com/cvic/">
                <MDBox
                  component="img"
                  src={marubeniLogoPrimary}
                  alt="marubeni-logo-primary"
                  width="18rem"
                  mr={1}
                />
              </MDBox>

              {/* <Grid item xs={1} />
              <MDBox component="img" src={brandWhite} alt="Brand" width="12rem" mr={1} pb={1}/> */}
              {/* <MDTypography variant="h2" fontWeight="bold" color="white">
                {brand}
              </MDTypography> */}
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Container>
  );
}

// Declaring default props for DefaultNavbar
DefaultNavbar.defaultProps = {
  // brand: "株式会社 I.W.G ",
  brand: 'I.W.G',
  transparent: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  brand: PropTypes.string,
  transparent: PropTypes.bool,
};

export default DefaultNavbar;

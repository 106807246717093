import intl from 'react-intl-universal';
import localData from 'shared/utils/localData';

export const generateInvoiceDetails = (invoiceIssuesData) => {
  const allPageData = [];

  for (let i = 0; i < invoiceIssuesData.length; i += 25) {
    const pageData = [];
    for (let j = i; j < i + 25 && j < invoiceIssuesData.length; j++) {
      pageData.push({
        index: j + 1,
        title: invoiceIssuesData[j].title,
        modality: invoiceIssuesData[j].modality,
        bodypart: invoiceIssuesData[j].bodypart,
        emergency: invoiceIssuesData[j].emergency,
        currentPrice: invoiceIssuesData[j].currentPrice,
      });
    }
    allPageData.push(pageData);
  }

  return allPageData;
};

export const generateInvoiceTo = (orgName) => {
  return {
    orgName: orgName,
  };
};

const getUserName = () => {
  if (localData.get('userPreferredLanguage') === 'ja-JP') {
    return localData.get('userLastName') + ' ' + localData.get('userFirstName');
  } else {
    return localData.get('userFirstName') + ' ' + localData.get('userLastName');
  }
};

export const generateInvoiceFrom = (invoiceDstOrg) => {
  const contact = invoiceDstOrg.contact;

  return {
    orgName: invoiceDstOrg.name,
    zipCode: contact.zipCode,
    addressLine1: contact.addressLine1,
    addressLine2: contact.addressLine2,
    tel: contact.tel,
    fax: contact.fax,
    whoInChargeEmail: localData.get('userEmail'),
    whoInChargeName: getUserName(),
  };
};

export const generateInvoiceTotalAmount = (invoiceIssuesData) => {
  let totalPrice = 0;
  invoiceIssuesData.forEach((issue) => {
    totalPrice += issue.currentPrice;
  });

  totalPrice = parseInt(totalPrice * 1.1);

  return {
    totalPrice: totalPrice,
    tax: '10%',
  };
};

export const generateInvoiceAmountListItems = (invoiceIssuesData) => {
  let totalPrice = 0;
  invoiceIssuesData.forEach((issue) => {
    totalPrice += issue.currentPrice;
  });

  const invoiceAmountListItems = [];
  invoiceAmountListItems.push({
    item: intl.get('billing_invoice_text_item'),
    unitPrice: totalPrice,
    count: 1,
    totalPrice: totalPrice,
  });

  for (let idx = 0; idx < 9; idx++) {
    invoiceAmountListItems.push({
      item: null,
      unitPrice: null,
      count: null,
      totalPrice: null,
    });
  }

  return invoiceAmountListItems;
};

export const generateInvoicePaymentMethod = (invoiceDstOrg) => {
  const paymentMethod = invoiceDstOrg.paymentMethod;

  return {
    bank: paymentMethod.bank,
    branch: paymentMethod.branch,
    accountType: paymentMethod.accountType,
    accountNo: paymentMethod.accountNo,
    accountName: paymentMethod.accountName,
  };
};

export const generateInvoiceRemarks = (remarksContent) => {
  return {
    text: remarksContent,
  };
};

export const generateInvoiceNo = (
  invoiceSrcOrgId,
  invoiceDstOrgId,
  invoiceIssueDate,
  invoiceVersion
) => {
  const srcOrgId = invoiceSrcOrgId.toString().padStart(6, '0');
  const dstOrgId = invoiceDstOrgId.toString().padStart(6, '0');
  const yyyy = invoiceIssueDate.getFullYear();
  const mm = ('00' + (invoiceIssueDate.getMonth() + 1)).slice(-2);
  const dd = ('00' + invoiceIssueDate.getDate()).slice(-2);
  const version = invoiceVersion.toString().padStart(2, '0');

  return srcOrgId + dstOrgId + yyyy + mm + dd + version;
};

export const convertDate = (date) => {
  const yyyy = date.getFullYear();
  const mm = ('00' + (date.getMonth() + 1)).slice(-2);
  const dd = ('00' + date.getDate()).slice(-2);

  return yyyy + '-' + mm + '-' + dd;
};

import React from 'react';

// ************** codes for rendering PDF manual (currently not in use) **************
// import { Document, Page, pdfjs } from 'react-pdf';
// import manual from 'assets/documents/manual/manual-ja-jp_1.0.0.4_20220815.pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const DashboardManualJaJP = () => {

//   const ref = useRef(null);

//   const [pageNumbers, setPageNumbers] = useState([]);
//   const [height, setHeight] = useState(0);
//   const [width, setWidth] = useState(0);

//   useEffect(() => {
//     setHeight(ref.current.offsetHeight);
//     setWidth(ref.current.offsetWidth);
//   }, []);

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setPageNumbers(Array.from({length: numPages}, (_, i) => i+1));
//   }

//   return (
//     <div ref={ref} height={height}>
//       <Document file={manual} onLoadSuccess={onDocumentLoadSuccess}>
//         {
//           pageNumbers.map((pageNumber) => {
//             return <Page pageNumber={pageNumber} width={width}  />
//           })
//         }
//       </Document>
//     </div>
//   );
// };
// ************** END: codes for rendering PDF manual (currently not in use) **************

class DashboardManualJaJP extends React.Component {
  onIframeRef = (node) => {
    if (!node) {
      return;
    }
    node.contentWindow.addEventListener('load', () => {
      node.contentWindow.document.body.style =
        'margin: auto; -moz-transform: scale(2); zoom: 2;';
    });

    this.Iframe = node;
  };

  render() {
    return (
      <iframe
        title="Dashboard Manual"
        src="static/manual/DoCloud_1.0.0.6.html"
        ref={this.onIframeRef}
        width="100%"
        height="100%"
        // minHeight="800px"
        frameBorder={0}
      />
    );
  }
}

export default DashboardManualJaJP;

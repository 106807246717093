import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import { Grid } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
// import useApi from 'shared/hooks/api';
import api from 'shared/utils/api';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import FormField from 'pages/parts/FormField';
import FormFieldHelperText from 'pages/parts/FormFieldHelperText';
import pxToRem from 'assets/theme/functions/pxToRem';

import localData from 'shared/utils/localData';
import { ConfirmModal } from 'shared/modals/confirmModal';

const propTypes = {
  userInfo: PropTypes.shape({
    firstName: PropTypes.string,
    username: PropTypes.string,
    userType: PropTypes.string,
    lastName: PropTypes.string,
    avataUrl: PropTypes.string,
    preferredLanguage: PropTypes.string,
    preferredTimezone: PropTypes.string,
  }),
};

const getLanguageOptions = () => {
  return [
    { label: 'English', id: 'en-US' },
    { label: '日本語', id: 'ja-JP' },
    // {label: "简体中文", id: 'zh-CN'},
  ];
};

const getPreferredTimezoneOptions = () => {
  return [
    // {label: intl.get('shared_text_china_standard_time'), id: 'CST'},
    { label: intl.get('shared_text_japan_standard_time'), id: 'JST' },
  ];
};

const DashboardPersonalSettingsPreference = ({ userInfo }) => {
  const [showConfirmBeforeSend, setShowConfirmBeforeSend] = useState(false);
  const [warnUserNameEmpty, setWarnUserNameEmpty] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [formState, setFormState] = useState({
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    avataUrl: userInfo.avataUrl,
    preferredLanguage: userInfo.preferredLanguage,
    preferredTimezone: userInfo.preferredTimezone,
  });

  const preferredLanguage = localData.get('userPreferredLanguage');

  const handleChange = (key) => async (event) => {
    const newFormState = { ...formState, [key]: event.target.value };
    setFormState({ ...newFormState });
  };

  const handleChange2 = (key, newValue) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  const handleUpdateClick = async (event) => {
    if (formState.firstName.length === 0 || formState.lastName.length === 0) {
      let emptyFields = [
        formState.lastName.length === 0
          ? intl.get('personal_settings_label_last_name')
          : null,
        formState.firstName.length === 0
          ? intl.get('personal_settings_label_first_name')
          : null,
      ];
      emptyFields = emptyFields.filter(function (e) {
        return e;
      });
      setEmptyFields(emptyFields);
      setWarnUserNameEmpty(true);
    } else {
      setShowConfirmBeforeSend(true);
    }
  };

  const handleConfirmClick = async (event) => {
    setShowConfirmBeforeSend(false);
    await sendToServer();
  };

  const generateConfirmWindowContents = () => {
    const getPreferredLanguage = (lang) => {
      switch (lang) {
        case 'en-US':
          return 'English';
        case 'ja-JP':
          return '日本語';
        case 'zh-CN':
          return '简体中文';
        default:
          return '';
      }
    };

    const getPreferredTimezone = (tz) => {
      switch (tz) {
        case 'CST':
          return intl.get('shared_text_china_standard_time');
        case 'JST':
          return intl.get('shared_text_japan_standard_time');
        default:
          return '';
      }
    };

    return (
      <Grid spacing={3} container style={{ marginTop: '15px' }}>
        <Grid xs={6} item>
          <MDTypography>
            {intl.get('personal_settings_label_first_name')}:{' '}
            {userInfo.firstName + ' ==> ' + formState.firstName}
          </MDTypography>
        </Grid>
        <Grid xs={6} item>
          <MDTypography>
            {intl.get('personal_settings_label_last_name')}:{' '}
            {userInfo.lastName + ' ==> ' + formState.lastName}
          </MDTypography>
        </Grid>
        <Grid xs={6} item>
          <MDTypography>
            {intl.get('personal_settings_label_preferred_language')}:{' '}
            {getPreferredLanguage(userInfo.preferredLanguage) +
              ' ==> ' +
              getPreferredLanguage(formState.preferredLanguage)}
          </MDTypography>
        </Grid>
        <Grid xs={6} item>
          <MDTypography>
            {intl.get('personal_settings_label_preferred_timezone')}:{' '}
            {getPreferredTimezone(userInfo.preferredTimezone) +
              ' ==> ' +
              getPreferredTimezone(formState.preferredTimezone)}
          </MDTypography>
        </Grid>
      </Grid>
    );
  };

  const sendToServer = async () => {
    const variables = {
      data: {
        firstName: formState.firstName,
        lastName: formState.lastName,
        preferredLanguage: formState.preferredLanguage,
        preferredTimezone: formState.preferredTimezone,
      },
    };
    api.put('/api/user/personal_info', variables).then(
      (data) => {
        window.location.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <MDBox mt={8}>
          <FormField
            id="username"
            name="username"
            label={intl.get('personal_settings_label_username')}
            variant="outlined"
            value={userInfo.username}
          />
        </MDBox>
      </Grid>
      <Grid item xs={6}>
        <MDBox mt={8}>
          <FormField
            id="userType"
            name="userType"
            label={intl.get('personal_settings_label_user_type')}
            variant="outlined"
            value={userInfo.userType}
          />
        </MDBox>
      </Grid>
      {preferredLanguage === 'en-US' && (
        <Fragment>
          <Grid item xs={6}>
            <MDBox mt={8}>
              <FormField
                id="userFirstName"
                name="userFirstName"
                label={intl.get('personal_settings_label_first_name')}
                variant="outlined"
                value={formState.firstName}
                inputProps={{ maxLength: 17 }}
                onChange={handleChange('firstName')}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={8}>
              <FormField
                id="lastName"
                name="lastName"
                label={intl.get('personal_settings_label_last_name')}
                variant="outlined"
                value={formState.lastName}
                inputProps={{ maxLength: 17 }}
                onChange={handleChange('lastName')}
              />
            </MDBox>
          </Grid>
        </Fragment>
      )}
      {preferredLanguage !== 'en-US' && (
        <Fragment>
          <Grid item xs={6}>
            <MDBox mt={8}>
              <FormField
                id="lastName"
                name="lastName"
                label={intl.get('personal_settings_label_last_name')}
                variant="outlined"
                value={formState.lastName}
                inputProps={{ maxLength: 17 }}
                onChange={handleChange('lastName')}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={8}>
              <FormField
                id="userFirstName"
                name="userFirstName"
                label={intl.get('personal_settings_label_first_name')}
                variant="outlined"
                value={formState.firstName}
                inputProps={{ maxLength: 17 }}
                onChange={handleChange('firstName')}
              />
            </MDBox>
          </Grid>
        </Fragment>
      )}

      <Grid item xs={6}>
        <MDBox mt={8}>
          <Autocomplete
            id="select_preferred_language"
            value={
              getLanguageOptions().filter(
                (value) => value.id === formState.preferredLanguage
              )[0]
            }
            onChange={(event, newValue) => {
              if (newValue && newValue.id)
                handleChange2('preferredLanguage', newValue.id);
            }}
            options={getLanguageOptions()}
            renderInput={(params) => (
              <FormField
                {...params}
                variant="outlined"
                label={intl.get('personal_settings_label_preferred_language')}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          <FormFieldHelperText>
            {intl.get('personal_settings_helper_select_preferred_language')}
          </FormFieldHelperText>
        </MDBox>
      </Grid>

      <Grid item xs={6}>
        <MDBox mt={8}>
          <Autocomplete
            id="select_preferred_timezone"
            value={
              getPreferredTimezoneOptions().filter(
                (value) => value.id === formState.preferredTimezone
              )[0]
            }
            onChange={(event, newValue) => {
              if (newValue && newValue.id)
                handleChange2('preferredTimezone', newValue.id);
            }}
            options={getPreferredTimezoneOptions()}
            renderInput={(params) => (
              <FormField
                {...params}
                variant="outlined"
                label={intl.get('personal_settings_label_preferred_timezone')}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          <FormFieldHelperText>
            {intl.get('personal_settings_helper_select_preferred_timezone')}
          </FormFieldHelperText>
        </MDBox>
      </Grid>

      <Grid item xs={12}>
        <MDButton
          variant="gradient"
          color="info"
          fullWidth
          onClick={handleUpdateClick}
          style={{
            color: 'white',
            fontSize: pxToRem(18),
            marginTop: '25px',
            marginBottom: '15px',
            width: '50%',
            marginLeft: '25%',
          }}>
          {intl.get('personal_settings_button_update')}
        </MDButton>
      </Grid>

      <ConfirmModal
        isOpened={warnUserNameEmpty}
        onConfirm={() => setWarnUserNameEmpty(false)}
        title={intl.get('new_modal_text_warning_title')}
        bodyText={intl.get('new_modal_text_warning_text_empty')}
        confirmButtonText={intl.get(
          'organization_settings_client_settings_modal_text_confirm_title'
        )}>
        <MDBox>
          <br />
          {emptyFields &&
            emptyFields.map((field, index) => (
              <p style={{ color: 'red' }} key={{ index }}>
                {field}
              </p>
            ))}
          <br />
          <MDTypography mb={1}>
            {intl.get('new_modal_text_warning_text_fill')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      <ConfirmModal
        isOpened={showConfirmBeforeSend}
        onCancel={() => setShowConfirmBeforeSend(false)}
        onConfirm={handleConfirmClick}
        title={intl.get(
          'personal_settings_preference_modal_text_confirm_title'
        )}
        bodyText={intl.get(
          'personal_settings_preference_modal_text_confirm_hint'
        )}
        confirmButtonText={intl.get(
          'personal_settings_preference_modal_button_submit'
        )}
        cancelButtonText={intl.get(
          'personal_settings_preference_modal_button_cancel'
        )}>
        <MDBox>
          <Grid
            spacing={3}
            container
            justifyContent="flex-end"
            alignItems="flex-end">
            <Grid xs={12} item>
              {showConfirmBeforeSend && generateConfirmWindowContents()}
            </Grid>
          </Grid>
        </MDBox>
      </ConfirmModal>
    </Grid>
  );
};

DashboardPersonalSettingsPreference.propTypes = propTypes;

export default withRouter(DashboardPersonalSettingsPreference);

import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Card from '@mui/material/Card';
import { PageLoader, PageError } from 'components';

import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { getUserType } from 'shared/constants/users';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';

import { generateLogContents } from './utils';

import CustomSearchRender from 'components/CustomSearchRender';

const propTypes = {
  history: PropTypes.object,
};

const DashboardOrganizationLogs = ({ history }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const preferredLanguage = localData.get('userPreferredLangage');

  const [{ data, error }] = useApi.get('/api/logs', {
    params: { organizationId: localData.get('currentOrganizationId') },
  });
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const handleViewLog = (link) => {
    // history.push(`${match.path}/issues/${issueId}`);
    if (link !== '') {
      history.push(link);
    }
  };

  data.sort((log1, log2) => {
    const createdTime1 = new Date(log1.createdAt);
    const createdTime2 = new Date(log1.createdAt);
    if (createdTime1 > createdTime2) return 1;
    if (createdTime1 < createdTime2) return -1;
    return 0;
  });

  const tableData = data.map((log) => {
    const lgoUserFullName =
      preferredLanguage === 'en-US'
        ? log.user.firstName + ' ' + log.user.lastName
        : log.user.lastName + ' ' + log.user.firstName;
    return [
      log.createdAt,
      // log.user.firstName + ' ' + log.user.lastName,
      lgoUserFullName,
      log.user.userType,
      log.ip,
      { type: log.type, contents: log.contents },
      log.link,
    ];
  });
  const columns = [
    {
      name: 'createdAt',
      label: intl.get('logs_label_column_created_at'),
      options: {
        filter: false,
        sort: true,
        sortDirection: 'asc',
        customBodyRender: (value, tableMeta, updateValue) => {
          const date = new Date(value);
          return date.toString();
        },
      },
    },
    {
      name: 'name',
      label: intl.get('logs_label_column_user_real_name'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'userType',
      label: intl.get('logs_label_column_user_type'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const userType = getUserType(value);
          return userType;
        },
      },
    },
    {
      name: 'ip',
      label: intl.get('logs_label_column_ip_address'),
      options: {
        filter: false,
      },
    },
    {
      name: 'content',
      label: intl.get('logs_label_column_content'),
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const contents = generateLogContents(value.type, value.contents);
          return contents;
        },
      },
    },
    {
      name: 'view',
      label: intl.get('logs_label_column_details'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const link = value;
          return link !== '' ? (
            <div>
              <Button
                variant="contained"
                color="primary"
                disable={link === ''}
                onClick={() => {
                  handleViewLog(link);
                }}>
                {intl.get('logs_button_view')}
              </Button>
            </div>
          ) : (
            <div></div>
          );
        },
      },
    },
  ];
  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'None',
    filterType: 'textField',
    responsive: 'stacked',
    rowsPerPageOptions: [10, 20, 50],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={intl.get('logs_text_organization_logs')}
        />
      );
    },
  };

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <ThemeProvider theme={getTablesTheme(darkMode)}>
          <MUIDataTable
            title={intl.get('logs_text_organization_logs')}
            data={tableData}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </Card>
    </Fragment>
  );
};

DashboardOrganizationLogs.propTypes = propTypes;

export default withRouter(DashboardOrganizationLogs);

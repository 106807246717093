import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import intl from 'react-intl-universal';
// import { makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Card from '@mui/material/Card';

import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import PropTypes from 'prop-types';
import { updateArrayItemById } from 'shared/utils/javascript';
import { getReceivedTableColumns } from './ReceivedTableColumns';
// import useStyles from './styles';
import { PageLoader, PageError, Modal } from 'components';
import IssueDetails from '../Kanban/IssueDetails';

// components and parts
import MDBox from 'components/MDBox';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import CustomSearchRender from 'components/CustomSearchRender';

const propTypes = {
  match: PropTypes.obj,
  history: PropTypes.obj,
};

const DashboardReceivedHistory = ({ match, history }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [{ data, error, setLocalData }] = useApi.get('/api/issues/received', {
    params: { organizationId: localData.get('currentOrganizationId') },
  });
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { receivedIssues, users } = data;

  const updateLocalProjectIssues = (issueId, updatedFields) => {
    setLocalData((currentData) => ({
      receivedIssues: updateArrayItemById(
        currentData.receivedIssues,
        issueId,
        updatedFields
      ),
      users: users,
    }));
  };

  const handleOpenIssueDetails = (issueId) => {
    history.push(`${match.path}/issues/${issueId}`);
  };

  const preferredLanguage = localData.get('userPreferredLanguage');
  const tableData = receivedIssues.map((issue) => {
    const doctors = users.filter((user) => issue.userIds.includes(user.id));
    var doctorList = [];
    for (var index = 0; index < doctors.length; index++) {
      const doctor = doctors[index];
      doctorList.push(
        preferredLanguage === 'ja-JP'
          ? doctor.lastName + ' ' + doctor.firstName
          : doctor.firstName + ' ' + doctor.lastName
      );
    }

    return [
      issue.title,
      // getIssueStatusInFigures(issue.status),
      issue.status,
      issue.state,
      issue.priority,
      issue.deadline,
      issue.createdAt,
      issue.srcOrgName,
      // issue.modality,
      // issue.bodypart,
      doctorList.length > 0
        ? doctorList
        : [intl.get('case_list_value_doctor_unassigned')],
      issue.id,
    ];
  });

  const columns = getReceivedTableColumns(handleOpenIssueDetails);

  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'None',
    // filterType: 'textField',
    responsive: 'stacked',
    // rowsPerPage: 20,
    rowsPerPageOptions: [10, 20, 50],
    // page: 1,
    // onRowClick: (rowData, rowState) => {
    //   let _id = rowData[9];
    //   history.push({
    //     pathname: `/viewer/${_id}`,
    //   });
    // },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={intl.get('case_list_text_received_table_title')}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
        // columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MUIDataTable
              title={intl.get('case_list_text_received_table_title')}
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </MDBox>
      </Card>
      {/* <Route 
        path={`${match.path}/issues/:issueId`}
        render={routeProps => (
          <Modal 
            isOpen 
            testId="modal:issue-details"
            width={1040}
            withCloseIcon={false}
            onClose={()=>history.push(match.url)}
            renderContent={modal => (
              <IssueDetails 
                issueId={routeProps.match.params.issueId}
                trigger={routeProps.location.state ? routeProps.location.state.trigger : 'byClick'}
                projectUsers={users}
                updateLocalProjectIssues={updateLocalProjectIssues}
                modalClose={modal.close}
              />
            )}
          />
        )}
      /> */}
      {/* {modalIssueDetailsOpen && <Modal 
        isOpen 
        testId="modal:issue-details"
        width={1040}
        withCloseIcon={false}
        onClose={()=>setModalIssueDetailsOpen(false)}
        renderContent={modal => (
          <IssueDetails 
            issueId={selectedIssueId}
            trigger={'byClick'}
            projectUsers={users}
            updateLocalProjectIssues={updateLocalProjectIssues}
            modalClose={modal.close}
          />
        )}
      />} */}
      <Route
        path={`${match.path}/issues/:issueId`}
        render={(routeProps) => (
          // Irregular
          <Modal
            isOpen
            testId="modal:issue-details"
            width={1540}
            withCloseIcon={false}
            onClose={() => history.push(match.url)}
            renderContent={(modal) => (
              <IssueDetails
                issueId={routeProps.match.params.issueId}
                trigger={'byClick'}
                projectUsers={users}
                updateLocalProjectIssues={updateLocalProjectIssues}
                modalClose={modal.close}
              />
            )}
          />
        )}
      />
    </Fragment>
  );
};

DashboardReceivedHistory.propTypes = propTypes;

export default withRouter(DashboardReceivedHistory);

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modalInvoiceInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalInvoice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalInvoicePaper: {
    backgroundColor: '#FFFFF3',
    border: '2px solid #000',
    position: 'absolute',
    maxWdith: '60%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default useStyles;

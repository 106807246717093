import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Avatar, Select, Icon } from 'components';
import { SectionTitle } from '../Styles';
import { User, Username, Quota } from './Styles';

import { IssueState, IssueStatus } from 'shared/constants/issues';
import localData from 'shared/utils/localData';

const propTypes = {
  issue: PropTypes.object.isRequired,
  updateIssue: PropTypes.func,
  user_quotas: PropTypes.array.isRequired,
  projectUsers: PropTypes.array.isRequired,
  updateLocalIssueDetails: PropTypes.func,
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
};

const ProjectBoardIssueDetailsAssignees = ({
  issue,
  updateIssue,
  user_quotas,
  projectUsers,
  updateLocalIssueDetails,
  setConfirmModalInfo,
  setConfirmModalOpen,
  setConfirmModalUpdateData,
}) => {
  // const getUserById = (userId) =>
  //   projectUsers.find((user) => user.id === userId);

  const getUserById2 = (userId) =>
    user_quotas.find((user) => user.id === userId); // todo: need further work

  const handleAssignDoctor = (issue, userIds) => {
    const users = userIds.map(getUserById2);
    const updatedFields = {
      userIds,
      users: users,
      status: issue.status,
      state: issue.state,
    };
    const doctorUsers = issue.users.filter(
      (user) => user.userType === 'doctor'
    );
    if (
      doctorUsers.length === 0 &&
      issue.state === IssueState.NORMAL &&
      issue.status === IssueStatus.CHECKREADY
    ) {
      updatedFields.status = IssueStatus.DOCTORASSIGNED;
    }
    if (
      users.length === 0 &&
      [
        IssueStatus.DOCTORASSIGNED,
        IssueStatus.CHECKREADY,
        IssueStatus.REPORTREADY,
        IssueStatus.SENDBACK,
        IssueStatus.DONE,
      ].includes(issue.status)
    ) {
      updatedFields.state = IssueState.WARNING;
    }
    // updateLocalIssueDetails(updatedFields);
    setConfirmModalInfo({
      destination: updatedFields.status,
      issueState: updatedFields.state,
      AssignType: doctorUsers.length < users.length ? 'assign' : 'unassign',
    });
    setConfirmModalUpdateData(updatedFields);
    setConfirmModalOpen(true);
    // updateIssue(updatedFields);
  };

  const userOptions = user_quotas.map((user) => ({
    value: user.id,
    label: user.username,
    used: user.used,
    quota: user.quota,
  }));

  return (
    <Fragment>
      <SectionTitle>
        {intl.get('issue_details_text_doctor_in_charge')}
      </SectionTitle>
      <Select
        isMulti
        variant="empty"
        dropdownWidth={343}
        placeholder={intl.get('issue_details_unassigned')}
        name="assignees"
        value={issue.userIds}
        options={userOptions}
        onChange={(userIds) => {
          handleAssignDoctor(issue, userIds);
        }}
        renderValue={({ value: userId, removeOptionValue }) =>
          renderUser(getUserById2(userId), true, removeOptionValue)
        }
        renderOption={({ value: userId }) =>
          renderUserWithQuota(getUserById2(userId), false)
        }
      />
    </Fragment>
  );
};

const renderUser = (user, isSelectValue, removeOptionValue) => {
  const preferredLanguage = localData.get('userPreferredLanguage');
  return (
    <User
      key={user.id}
      isSelectValue={isSelectValue}
      withBottomMargin={!!removeOptionValue}
      onClick={() => removeOptionValue && removeOptionValue()}>
      {/* <Avatar avatarUrl={user.avatarUrl} name={user.username} size={24} /> */}
      <Avatar name={user.firstName} size={24} />
      <Username>
        {preferredLanguage === 'en-US'
          ? user.firstName + ' ' + user.lastName
          : user.lastName + ' ' + user.firstName}
      </Username>
      {removeOptionValue && <Icon type="close" top={1} />}
    </User>
  );
};

const renderUserWithQuota = (user, isSelectValue) => {
  const isFull = user.used === user.quota;
  const preferredLanguage = localData.get('userPreferredLanguage');
  return (
    <User
      key={user.id}
      isSelectValue={isSelectValue}
      withBottomMargin={false}
      onClick={() => {}}>
      <Avatar avatarUrl={user.avatarUrl} name={user.username} size={24} />
      <Username style={isFull ? { color: 'lightgrey' } : {}}>
        {' '}
        {preferredLanguage === 'en-US'
          ? user.firstName + ' ' + user.lastName
          : user.lastName + ' ' + user.firstName}{' '}
      </Username>
      <Quota style={isFull ? { color: 'lightgrey' } : {}}>
        {' '}
        {`${user.used}/${user.quota}`}{' '}
      </Quota>
    </User>
  );
};

ProjectBoardIssueDetailsAssignees.propTypes = propTypes;

export default ProjectBoardIssueDetailsAssignees;

import React, { Fragment, useRef } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import CommentTextEditor from './CommentTextEditor';
import { Actions, FormButton, FormSelect } from './Styles';

const propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isWorking: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  allowReport: PropTypes.bool.isRequired,
  isClient: PropTypes.bool.isRequired,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  quoteAttachmentName: PropTypes.string,
  refreshQuote: PropTypes.number,
  commentEditorRef: PropTypes.shape({
    current: PropTypes.instanceOf(HTMLInputElement),
  }),
  handleAddAttachmentButtonClick: PropTypes.func,
};

const ProjectBoardIssueDetailsCommentsBodyForm = ({
  isClient,
  allowReport,
  isWorking,
  formState,
  setFormState,
  onSubmit,
  onCancel,
  quoteAttachmentName,
  refreshQuote,
  commentEditorRef,
  handleAddAttachmentButtonClick,
}) => {
  const $textareaRef = useRef();
  // const attachmentRef = useRef();  // do not delete

  const handleChange = (key) => (val) => {
    const newFormState = { ...formState, [key]: val };
    setFormState({ ...newFormState });
  };

  const handleSubmit = () => {
    onSubmit();
  };

  // do not delete
  // const selectAttachments = (event) => {
  //   const file = event.target.files[0];
  //   const newFormState = { ...formState };
  //   newFormState.file = file;
  //   newFormState.fileName = file.name;
  //   newFormState.progressInfos = { val: [] };
  //   setFormState({ ...newFormState });
  // };

  const renderSubmitType = (submitType) => {
    if (submitType === 'global') {
      return <div>{intl.get('issue_details_option_comment_type_global')}</div>;
    } else if (submitType === 'local') {
      return <div>{intl.get('issue_details_option_comment_type_local')}</div>;
    } else if (submitType === 'report') {
      return <div>{intl.get('issue_details_option_comment_type_report')}</div>;
    }
    return <div>{''}</div>;
  };

  return (
    <Fragment>
      <CommentTextEditor
        autoFocus
        placeholder={
          formState.commentBody && formState.commentBody !== ''
            ? formState.commentBody
            : intl.get('issue_details_placeholder_add_a_comment')
        }
        value={formState.commentBody}
        onChange={handleChange('commentBody')}
        ref={$textareaRef}
        quoteAttachmentName={quoteAttachmentName}
        refreshQuote={refreshQuote}
        commentEditorRef={commentEditorRef}
      />

      {/* // DO NOT DELETE */}
      {/* <input type="file" ref={attachmentRef} onChange={selectAttachments} /> */}

      <Actions>
        {!isClient && (
          <FormSelect
            variant="empty"
            withClearValue={false}
            dropdownWidth={140}
            name="submitType"
            value={formState.submitType}
            options={
              allowReport
                ? [
                    {
                      value: 'global',
                      label: intl.get(
                        'issue_details_option_comment_type_local'
                      ),
                    },
                    {
                      value: 'local',
                      label: intl.get(
                        'issue_details_option_comment_type_global'
                      ),
                    },
                    {
                      value: 'report',
                      label: intl.get(
                        'issue_details_option_comment_type_report'
                      ),
                    },
                  ]
                : [
                    {
                      value: 'global',
                      label: intl.get(
                        'issue_details_option_comment_type_local'
                      ),
                    },
                    {
                      value: 'local',
                      label: intl.get(
                        'issue_details_option_comment_type_global'
                      ),
                    },
                  ]
            }
            onChange={handleChange('submitType')}
            renderValue={({ value: submitType }) =>
              renderSubmitType(submitType)
            }
            renderOption={({ value: submitType }) =>
              renderSubmitType(submitType)
            }
          />
        )}
        <FormButton
          variant="primary"
          isWorking={isWorking}
          onClick={handleSubmit}>
          {intl.get('issue_details_button_save')}
        </FormButton>
        <FormButton variant="empty" onClick={onCancel}>
          {intl.get('issue_details_button_cancel')}
        </FormButton>
      </Actions>
    </Fragment>
  );
};

ProjectBoardIssueDetailsCommentsBodyForm.propTypes = propTypes;

export default ProjectBoardIssueDetailsCommentsBodyForm;

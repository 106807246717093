import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  setStoredAuthToken,
  setStoredAccessToken,
  getStoredAuthToken,
} from 'shared/utils/authToken';
import Welcome from 'pages/Welcome';
import PasswordRecovery from 'pages/PasswordRecovery';
import Dashboard from 'pages/Dashboard';
import IWGDashboard from 'pages/IWGDashboard';
import NormalizeStyles from './NormalizedStyles';
import BaseStyles from './BaseStyles';
import './App.css';
import './fontStyles.css';
import localData from 'shared/utils/localData';
import { SnackbarProvider } from 'notistack';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Memo:  More Work needed
// Authorization is applied to "/{proxy+} - ANY " in AWS API Gateway, and ANY includes OPTIONS (Prefilght).
// Therefore Prefight will fail, resulting in a 'Network Error', and in axios response interceptor the
// error.response will be undefined;
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      !error.response ||
      error.response.status === 0 ||
      error.response.status === 401
    ) {
      const currentSession = await Auth.currentSession();
      setStoredAuthToken(currentSession.idToken.jwtToken);
      setStoredAccessToken(currentSession.accessToken.jwtToken);
      originalRequest.headers.authorization = getStoredAuthToken();
    }

    // TODO: will retry regardless of failure reason; some failure reason should have no retry;
    if (originalRequest.retryCount) {
      originalRequest.retryCount++;
    } else {
      originalRequest.retryCount = 1;
    }
    if (originalRequest.retryCount > 3) {
      return Promise.reject(error);
    }

    await sleep(2 ** originalRequest.retryCount * 1000);
    return axios.request(originalRequest);
  }
);

const AppCustomSignIn = () => {
  const [currentUser, setCurrentUser] = useState('NotChecked');
  const [userGroups, setUserGroups] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (result) {
        setCurrentUser(result);
        const groups =
          result.signInUserSession.accessToken.payload['cognito:groups'];
        setUserGroups(groups);
      })
      .catch(function (error) {
        console.log(error);
        setCurrentUser('NotSignedIn');
      });
  }, []);

  if (currentUser === 'NotChecked') {
    return <div></div>; // temporary work-around;
  } else if (currentUser === 'NotSignedIn') {
    return (
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Switch>
          <Route exact path="/forgot-password">
            <PasswordRecovery />
          </Route>
          <Route path="/">
            <Welcome />
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
      </SnackbarProvider>
    );
  } else if (userGroups && userGroups.includes('IWG')) {
    localData.set('username', 'IWG');
    return (
      <div>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <NormalizeStyles />
          <BaseStyles />
          <IWGDashboard />
        </SnackbarProvider>
      </div>
    );
  } else {
    return (
      <div>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <NormalizeStyles />
          <BaseStyles />
          <Dashboard currentUser={currentUser} />
        </SnackbarProvider>
      </div>
    );
  }
};

const App = () => {
  return (
    <div>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <NormalizeStyles />
        <BaseStyles />
        <Dashboard />
      </SnackbarProvider>
    </div>
  );
};

const Result =
  process.env.REACT_APP_SIGN_IN_PAGE === 'custom' ? AppCustomSignIn : App;

export default Result;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// custom styles for the NotificationItem
import menuItem from 'pages/parts/Items/NotificationItem/styles';

const NotificationItem = forwardRef(
  (
    {
      darkMode,
      textColor,
      icon,
      clicked,
      text,
      index,
      handleMenuItemClick,
      ...rest
    },
    ref
  ) => (
    <MenuItem
      {...rest}
      ref={ref}
      sx={(theme) => menuItem(theme)}
      onClick={
        handleMenuItemClick
          ? (event) => handleMenuItemClick(event, index)
          : null
      }>
      {/* <MDBox component={Link} py={0.5} display="flex" alignItems="center" lineHeight={1}>  */}
      <MDBox component={Link} py={0.5} display="flex" lineHeight={1}>
        <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
          {icon}
        </MDTypography>
        <MDTypography
          variant="button"
          ontWeight="regular"
          style={{ whiteSpace: 'normal' }}
          sx={{ ml: 1 }}
          color={clicked ? 'text' : 'info'}
          noWrap
          // color={clicked ? "info" : "light"}
          // color={() => textColor(darkMode, clicked)}
        >
          {text}
        </MDTypography>
      </MDBox>
    </MenuItem>
  )
);
NotificationItem.displayName = 'NotificationItem';

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  darkMode: PropTypes.bool,
  textColor: PropTypes.func,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string,
  clicked: PropTypes.bool,
  text: PropTypes.string,
  index: PropTypes.number,
  handleMenuItemClick: PropTypes.func,
};

export default NotificationItem;

import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
import PropTypes from 'prop-types';
// import { LinearProgressWithLabel } from 'components';
import {
  Title,
  Message,
  Actions,
  StyledButton,
} from 'components/ConfirmModal/Styles';

const propTypes = {
  issue: PropTypes.object,
  attachment: PropTypes.object,
  modalClose: PropTypes.func,
};

const ProjectBoardIssueDetailsDownload = ({
  issue,
  attachment,
  modalClose,
}) => {
  // const [isDownloading, setIsDownloading] = useState(false);
  // const [DownloadProgress, setDownloadProgress] = useState(0);
  const [isWorking] = useState(false);
  const [GetObjectPresignedURL, setGetObjectPresignedURL] = useState('');

  useEffect(() => {
    const config = {
      params: {
        organizationId: localData.get('currentOrganizationId'),
        attachmentId: attachment.id,
      },
    };

    api
      .get(`/api/download/${issue.id}`, config)
      .then(async (data) => {
        setGetObjectPresignedURL(data.GetObjectPresignedURL);
      })
      .catch((error) => {
        alert(error);
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownloadButtonClick = () => {
    const organizationId = localData.get('currentOrganizationId');
    const ifSent = issue.srcOrgId === organizationId;
    api.post(`/api/logs`, {
      params: {
        organizationId: localData.get('currentOrganizationId'),
      },
      data: {
        type: 'download',
        contents: [issue.title],
        link: ifSent
          ? `/sent/issues/${issue.id}`
          : `/received/my-task/issues/${issue.id}`,
      },
    });

    modalClose();
  };

  const handleCancelButtonClick = () => {
    // https://github.com/atlassian/react-beautiful-dnd/issues/162
    // putIssueBackToNEWTASK(issueId, source, project);
    modalClose();
  };

  // TODO: problematic scenario: button is rendered, but useEffect() is not done. GetObjectPresignedURL is still '';
  // TODO: proposed plan: put GetObjectPresignedURL in the handleDownloadButtonClick() function; no need to use useEffect Hook;
  return (
    <Fragment>
      <Title>{attachment.title}</Title>
      <Message>{intl.get('kanban_modal_text_download_hint')}</Message>
      <Actions>
        <StyledButton variant={'primary'} isWorking={isWorking}>
          <a href={GetObjectPresignedURL} onClick={handleDownloadButtonClick}>
            {intl.get('kanban_modal_button_download')}
          </a>
        </StyledButton>
        <StyledButton hollow onClick={handleCancelButtonClick}>
          {intl.get('kanban_modal_button_download_cancel')}
        </StyledButton>
      </Actions>

      <br />

      {/* {isDownloading && <LinearProgressWithLabel value={DownloadProgress} />} */}
    </Fragment>
  );
};

ProjectBoardIssueDetailsDownload.propTypes = propTypes;

export default withRouter(ProjectBoardIssueDetailsDownload);

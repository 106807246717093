import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import { TaskTimer } from 'tasktimer';
import {
  Grid,
  Modal,
  Checkbox,
  FormControlLabel,
  Box,
} from '@material-ui/core';
import Chip from './Chip';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxOutlineBlackIconOutlined from '@material-ui/icons/CheckBoxOutlineBlankOutlined'

// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import FormField from 'pages/parts/FormField';
import FormFieldHelperText from 'pages/parts/FormFieldHelperText';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import DashboardNewDropZone from 'pages/parts/DropZone';
import { CustomCheckboxForm } from './customCheckboxForm';
import { PageLoader, PageError, TextEditor } from 'components';
import useStyles from './styles';
import { useMaterialUIController } from 'context';
import {
  useUploadMissionsContextController,
  addNewMission,
  setMissionStatusContext,
  contextUpdateUploadingSpeed,
} from 'context/NewIssueContext';
import {
  initNewIssue,
  startIssuePeriodicStatusCheck,
  uploadBatchAttachmentsToS3,
  checkForAttachmentsCompletionOnNewIssue,
  getDstOrgOptions,
  getGenderOptions,
  getGenderValue,
  getEmergencyOptions,
  generateConfirmWindowContents,
  getDstOrgObjectById,
  generateFileList,
  generateDirectoryList,
  getCVICSuperUserSourceOrgs,
  getCVICSuperUserDestinationOrgs,
} from './utils';

import {
  fileTypeIsValid,
  getStructuredFiles,
  calculateFolderSize,
  calcBatchTotalSize,
  findDuplicateAttachments,
} from 'shared/utils/fileHandling';

import { removeLeadingSlash } from 'shared/utils/misc';
import pxToRem from 'assets/theme/functions/pxToRem';
import { tagIntlValues, tagRelationships } from 'shared/constants/tagsValues';
import { formConstants } from './constants';
import { ConfirmModal } from 'shared/modals/confirmModal';

const AutocompleteGender = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 0.88em;
  }
`;

const propTypes = {
  userInfo: PropTypes.object,
};

const DashboardNew = ({ userInfo }) => {
  const [controllerUploadMissions, dispatchUploadMissions] =
    useUploadMissionsContextController();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const classes = useStyles();
  const currentUsername = localData.get('username');
  const currentOrganization = localData.get('currentOrganization');
  const currentOrganizationId = localData.get('currentOrganizationId');

  // states specifically for resetting some Autocomplete inputs;
  const [toggleResetEmergency, setToggleResetEmergency] = useState(false);
  const [toggleResetDstOrgName, setToggleResetDstOrgName] = useState(false);
  const [toggleResetDescription, setToggleResetDescription] = useState(false);

  // states related to pre-sending;
  const [exceedUploadMissionsLimit, setExceedUploadMissionsLimit] =
    useState(false);
  const [warnFormIncomplete, setWarnFormIncomplete] = useState(false);
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [showWarningBeforeSend, setShowWarningBeforeSend] = useState(false);
  const [sendContentsConfirmed, setSendContentsConfirmed] = useState(false);
  const [genderValue, setGenderValue] = useState('');
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [showWarnInvalidFileTypes, setShowWarnInvalidFileTypes] =
    useState(false);
  const [openCheckboxForm, setOpenCheckboxForm] = useState(false);
  const InitialTagForm = {
    CT1: false, // 冠動脈＋心エコー
    CT2: false, // 冠動脈
    CT3: false, // 大動脈
    CT4: false, // 胸部
    CT5: false, // 腹部
    CT6: false, // 肺
    CT7: false, // 造影
    CT8: false, // 非造影
    CT9: false, // 腎動脈
    CT10: false, // その他

    MRI1: false, // 心臓
    MRI2: false, // 非造影
    MRI3: false, // 造影
    MRI4: false, // 一任
    MRI5: false, // ※ 冠動脈MRAによる狭窄評価 要
    MRI6: false, // ※ 冠動脈MRAによる狭窄評価 不要
    MRI7: false, // ※ 冠動脈MRAによる狭窄評価 一任
    MRI8: false, // 脳 (MRAを含む)
    MRI9: false, // 大動脈
    MRI10: false, // 胸部
    MRI11: false, // 腹部
    MRI12: false, // 腎動脈
    MRI13: false, // 下肢動脈・静脈
    MRI14: false, // その他

    PhyExam1: false, // 心エコー
    PhyExam2: false, // 頸動脈エコー
    PhyExam3: false, // ABI
    PhyExam4: false, // ホルター心電図
  };
  const [checkboxForm, setCheckboxForm] = useState(InitialTagForm);

  const [formState, setFormState] = useState({
    patientName: '',
    patientGender: '',
    srcOrgName: currentOrganization,
    srcOrgId: currentOrganizationId,
    dstOrgName: '',
    dstOrgId: '',
    emergency: '',
    department: '',
    doctorInCharge: '',
    description: '',
    file: '',
    dataSet: '',
    doctorsInCharge: [],
    doctorIdsFromSrcOrg: [],
  });

  const [structuredAttachments, setStructuredAttachments] = useState([]);
  const [newStructuredAttachments, setNewStructuredAttachments] = useState([]);
  const [duplicatedAttachments, setDuplicatedAttachments] = useState([]);

  // const [CvicSuperUserOrgs, setCvicSuperUserOrgs] = useState({});
  // useEffect( () => {
  //   const fetchCvicSuperUserOrgs = async () => {
  //     const res = await api.get('/api/organizations/getRelatedOrgsForCvicSuper', {
  //       params: { organizationId: localData.get('currentOrganizationId')}
  //     });
  //     console.log(`res.allRelatedOrgs: ${JSON.stringify(res.allRelatedOrgs)}`);
  //     console.log(`res.allRelatedOrgs: ${typeof res.allRelatedOrgs}`);
  //     setCvicSuperUserOrgs({...res.allRelatedOrgs});
  //   }

  //   if (currentUsername === 'cvic_super') fetchCvicSuperUserOrgs();
  // }, []);

  // useEffect(() => {
  //   console.log('xxxx');
  //   setGenderValue({label: '女性', id: 'F'});
  // }, []);
  // useEffect(() => {
  //   console.log('new gender value');
  //   console.log(genderValue);
  // }, [genderValue]);
  // useEffect(() => {
  //   const newGenderValue = getGenderOptions().filter((value) => (value.id === formState.patientGender))[0];
  //   console.log('prepare a new gender value');
  //   console.log(newGenderValue);
  //   setGenderValue(newGenderValue);
  // }, [formState]);

  // states related to procress of sending new issue;

  const [sendNewIssueFailed, setSendNewIssueFailed] = useState(false);
  const [doctorsInCharge, setDoctorsInCharge] = useState([]);

  useEffect(() => {
    const id = controllerUploadMissions.newIssueIdentifierId;
    const status =
      id in controllerUploadMissions.missions
        ? controllerUploadMissions.missions[id].status
        : '';
    if (status === 'INIT') {
      resetForm();
      const missionId = controllerUploadMissions.newIssueIdentifierId;
      const mission = controllerUploadMissions.missions[missionId];
      sendNewIssueContinued(mission);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerUploadMissions.newIssueIdentifierId]);

  const [{ data, error }] = useApi.get('/api/organizations/new', {
    params: { organizationId: currentOrganizationId },
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { dstOrgs, CvicSuperUserOrgs, doctorsFromSrcOrg } = data;
  const doctorOptions = doctorsFromSrcOrg.map((doctor) => ({
    label:
      localData.get('userPreferredLangauge') === 'en-US'
        ? doctor.firstName + ' ' + doctor.lastName
        : doctor.lastName + ' ' + doctor.firstName,
    id: doctor.id,
  }));
  dstOrgs.sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });
  // if (CvicSuperUserOrgs) {
  //   CvicSuperUserOrgs.sort((a, b) => { return a.name < b.name ? -1 : 1 });
  // }
  const resetForm = () => {
    setFormState({
      patientName: '',
      patientGender: '',
      srcOrgName: currentOrganization,
      srcOrgId: currentOrganizationId,
      dstOrgName: '',
      dstOrgId: '',
      emergency: '',
      department: '',
      doctorInCharge: '',
      studyID: '',
      bodyPart: '',
      modality: '',
      description: '',
      file: '',
      dataSet: '',
      doctorsInCharge: [],
      doctorIdsFromSrcOrg: [],
    });
    setDoctorsInCharge([]);

    setGenderValue('');
    setToggleResetEmergency(!toggleResetEmergency);
    setToggleResetDstOrgName(!toggleResetDstOrgName);
    setToggleResetDescription(!toggleResetDescription);
    setCheckboxForm(InitialTagForm);

    setStructuredAttachments([]);
  };

  const handleSrcDstForCVICSuperUser = (key, org) => {
    const newFormState = { ...formState, [key]: org.id };
    const orgName = org.label;

    if (key === 'srcOrgId') {
      newFormState.srcOrgName = orgName;
      newFormState.dstOrgId = '';
      newFormState.dstOrgName = '';
    } else if (key === 'dstOrgId') {
      newFormState.dstOrgName = orgName;
    }
    setFormState({ ...newFormState });
  };

  // Handle changes from destOrgId
  const handleChangeOnForm = (key, value) => {
    const newFormState = {
      ...formState,
      [key]: value && value.value ? value.value : '',
    };
    // special case
    if (value && value.value) {
      if (key === formConstants.DEST_ORG_ID) {
        const dstOrg = getDstOrgObjectById(value.value, dstOrgs);
        newFormState.dstOrgName = dstOrg.name.toString();
      }
    }
    setFormState({ ...newFormState });
  };

  const handleChange = (key) => async (event) => {
    const newFormState = { ...formState, [key]: event.target.value };
    setFormState({ ...newFormState });
  };

  const handleChangeDoctors = (newDoctors) => {
    const doctorIds = newDoctors
      .filter((doctor) => doctor.id !== -1)
      .map((doctor) => doctor.id);
    const doctorNames = newDoctors.map((doctor) => doctor.label);
    setFormState((prevFormState) => ({
      ...prevFormState,
      doctorsInCharge: doctorNames,
      doctorIdsFromSrcOrg: doctorIds,
    }));
  };

  const handleDescriptionChange = (htmlValue) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      description: htmlValue,
    }));
  };

  //! This will handle the delete for the chips
  // Might want this for later
  // const handleDeleteTag = (valueToDelete) => () => {
  //  const newFormValues = {
  //   ...checkboxForm
  //  };
  //  newFormValues[valueToDelete] = false;
  //  setCheckboxForm(newFormValues);
  // }

  // Create the tags
  const createTags = (tagNameList) => {
    const tagList = [];
    tagNameList.forEach(function (item, index) {
      tagList.push(
        <Chip
          label={item}
          color={darkMode ? 'white' : 'black'}
          variant="outlined"
          style={{ marginRight: '4px' }}
        />
      );
    });
    return tagList;
  };

  // Show the tags correctly
  //! This will only work with the current situation
  const showSelectedModalItems = (type) => {
    const selectedItems = {};
    // Need to join specific tags
    for (const [key, value] of Object.entries(checkboxForm)) {
      // Trim the numbers from the key and lowercase them
      if (value && key.replace(/[0-9]/g, '') === type) {
        if (key in tagRelationships) {
          if (tagRelationships[key] === 'MRIGroup2') {
            selectedItems[tagRelationships[key]] = tagIntlValues['MRICor'];
          }
          selectedItems[tagRelationships[key]] = selectedItems[
            tagRelationships[key]
          ]
            ? selectedItems[tagRelationships[key]] + ' - ' + tagIntlValues[key]
            : tagIntlValues[key];
        } else {
          selectedItems[key] = tagIntlValues[key];
        }
      }
    }
    return Object.values(selectedItems);
  };

  // Shows the phyExam tags
  const showSelectedPhyExamModalItems = () => {
    const selectedItems = [];
    // Need to join specific tags
    for (const [key, value] of Object.entries(checkboxForm)) {
      // Trim the numbers from the key and lowercase them
      if (value && key.replace(/[0-9]/g, '') === 'PhyExam') {
        selectedItems.push(
          <Chip
            label={tagIntlValues[key]}
            color={darkMode ? 'white' : 'black'}
            variant="outlined"
            style={{ marginRight: '4px' }}
          />
        );
      }
    }
    return selectedItems;
  };

  // DO NOT DELETE!
  // const handleDicomZipFileInfoChange = (targetIndex, key) => (async (event) => {
  //   const newItems = attachments.map( (item, index) => {
  //     if (index == targetIndex) {
  //       return { ...item, [key]: event.target.value}
  //     }
  //     return item;
  //   });
  //   setAttachments(newItems);
  // });

  const handleFileDelete = (targetIndex) => async (event) => {
    setStructuredAttachments(
      structuredAttachments.filter((item, index) => index !== targetIndex)
    );
  };

  const handleDuplicateAttachments = async (action) => {
    switch (action) {
      case 'REPLACE': {
        const tmp = structuredAttachments.filter(
          (item) => !duplicatedAttachments.some((x) => item.name === x.name)
        );
        setStructuredAttachments([...tmp, ...newStructuredAttachments]);
        break;
      }
      case 'IGNORE': {
        const nonDups = newStructuredAttachments.filter(
          (item) => !duplicatedAttachments.some((x) => item.name === x.name)
        );
        setStructuredAttachments([...structuredAttachments, ...nonDups]);
        break;
      }
      case 'CANCEL':
        break;
      default:
        break;
    }
    setNewStructuredAttachments([]);
    setDuplicatedAttachments([]);
  };

  const handleFiles = async (files) => {
    const validAttachments = [];
    const invalidFiles = [];

    // filter out files with invalid formats;
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      if (!fileTypeIsValid(file)) {
        await invalidFiles.push(file);
      } else {
        const item = await handleSingleFile(file);
        await validAttachments.push(item);
      }
    }
    if (invalidFiles.length > 0) {
      setInvalidFiles([...invalidFiles]);
      setShowWarnInvalidFileTypes(true);
    }

    const newItems = getStructuredFiles(validAttachments);
    newItems.forEach((item) => {
      calculateFolderSize(item);
    });
    const dups = findDuplicateAttachments(structuredAttachments, newItems);

    if (dups.length > 0) {
      setDuplicatedAttachments(dups);
      setNewStructuredAttachments(newItems);
    } else {
      setStructuredAttachments([...structuredAttachments, ...newItems]);
    }
  };

  const handleClosingWarnInvalidFileWindow = () => {
    setInvalidFiles([]);
    setShowWarnInvalidFileTypes(false);
  };

  const handleSingleFile = async (f) => {
    const item = {
      type: 'file',
      file: f,
      name: f.name,
      fileFullPath: removeLeadingSlash(f.path),
      size: f.size,
    };
    return item;
  };

  const checkIfFormCompleted = () => {
    if (!formState.dstOrgId || !formState.emergency) {
      let incompleteFields = [
        !formState.dstOrgId ? intl.get('new_label_send_to') : null,
        !formState.emergency ? intl.get('new_label_emergency') : null,
      ];
      incompleteFields = incompleteFields.filter(function (e) {
        return e;
      });
      setIncompleteFields(incompleteFields);
      return false;
    } else {
      return true;
    }
  };

  const handleConfirmContentsCheckbox = () => {
    if (sendContentsConfirmed) {
      setSendContentsConfirmed(false);
    } else {
      setSendContentsConfirmed(true);
    }
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();
    if (checkIfFormCompleted()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setShowWarningBeforeSend(true);
      return;
    } else {
      setWarnFormIncomplete(true);
      return;
    }
  };

  const handleShowWarningBeforeSendModalClose = () => {
    setSendContentsConfirmed(false);
    setShowWarningBeforeSend(false);
  };

  const handleNewIssueFailModalClose = () => {
    setSendNewIssueFailed(false);
    // window.location.reload();
  };

  const handleExceedUploadMissionsLimitClose = () => {
    setExceedUploadMissionsLimit(false);
  };

  const getNumOngoingUploads = () => {
    const ongoings = Object.values(controllerUploadMissions.missions).filter(
      (x) => x.status === 'UPLOADING' || x.status === 'PROCESSING'
    );
    return ongoings.length;
  };

  const sendNewIssue = async () => {
    setShowWarningBeforeSend(false);
    setSendContentsConfirmed(false);

    // Step 0: check if there are already 3 ongoing uploads missions;
    if (getNumOngoingUploads() >= 3) {
      setExceedUploadMissionsLimit(true);
      return;
    }

    // Step 1: Create the Issue Entity in the backend, and get the issue unique ID;
    let issueInfo;
    try {
      issueInfo = await initNewIssue(
        formState,
        checkboxForm,
        structuredAttachments
      );
    } catch (error) {
      resetForm();
      setSendNewIssueFailed(true);
      return false;
    }

    const { issueId, issueIdentifierId, issueCreatedAt } = issueInfo;

    // Add the upload mission to Context;
    const batchTotalBytes = calcBatchTotalSize(structuredAttachments);

    addNewMission(dispatchUploadMissions, {
      issueId,
      issueIdentifierId,
      issueCreatedAt,
      formState,
      checkboxForm,
      structuredAttachments,
      batchTotalBytes,
    });
    // The rest of the steps are in function "sendNewIssueContinued"
    // It has to wait for addNewMission to finish updating the state stored in context;
    // Therefore It is placed inside useEffect hook
  };

  const sendNewIssueContinued = async (mission) => {
    // This function is a continuation from the "sendNewIssue" function;
    // It is placed in useEffect hook above and only executed after context state is successfully updated;

    const {
      issueId,
      issueIdentifierId,
      issueCreatedAt,
      formState,
      structuredAttachments,
      batchTotalBytes,
    } = mission;

    // Step 1: start a timer to track the upload speed of the upload mission that is about to start;
    const timer0 = new TaskTimer(1000); // milliseconds
    const task_uploadSpeed_checker = {
      id: 'heartbeat',
      tickInterval: 2, // number of ticks between 2 task runs;
      async callback(task) {
        // can also be an async function, returning a promise
        contextUpdateUploadingSpeed(dispatchUploadMissions, {
          missionId: issueIdentifierId,
        });
        if (!['INIT', 'UPLOADING'].includes(mission.status)) {
          timer0.stop();
        }
      },
    };
    timer0.add(task_uploadSpeed_checker).start();

    // Step 2: Upload the attachments to S3 bucket, and notify the backend to process it;
    try {
      setMissionStatusContext(dispatchUploadMissions, {
        missionId: issueIdentifierId,
        value: 'UPLOADING',
      });
      await uploadBatchAttachmentsToS3({
        missionId: issueIdentifierId,
        issueId,
        issueIdentifierId,
        issueCreatedAt,
        structuredAttachments,
        batchTotalBytes,
        dstOrgId: formState.dstOrgId,
        controllerUploadMissions,
        dispatchUploadMissions,
      });
    } catch (error) {
      // if cancelled, error = {message: 'canceled', name: 'CanceledError', code: 'ERR_CANCELED'}
      if (error.code === 'ERR_CANCELED') {
        console.log(`Upload is aborted: ${error}`);
        setMissionStatusContext(dispatchUploadMissions, {
          missionId: issueIdentifierId,
          value: 'UPLOAD_ABORTED',
        });
      } else {
        console.log(`Failed to upload batch of attachments: ${error}`);
        setMissionStatusContext(dispatchUploadMissions, {
          missionId: issueIdentifierId,
          value: 'UPLOAD_FAILED',
        });
      }
      return false;
    }

    setMissionStatusContext(dispatchUploadMissions, {
      missionId: issueIdentifierId,
      value: 'PROCESSING',
    });

    // Step 3: Notify the backend to start a periodic check on this issue;
    await startIssuePeriodicStatusCheck(issueId);

    // Step 4: while waiting for backend to process, poll every a few seconds to check for new status;
    const timer = new TaskTimer(1000); // milliseconds
    const task1 = {
      id: 'heartbeat',
      tickInterval: 5, // number of ticks between 2 taskTuns; e.g. run every 5 sec;
      totalRuns: 1440, // times to run; max 2 hour before timeout;
      async callback(task) {
        // can also be an async function, returning a promise
        const issueStatusRes = await checkForAttachmentsCompletionOnNewIssue(
          issueId
        );
        if (issueStatusRes === 'Complete') {
          setMissionStatusContext(dispatchUploadMissions, {
            missionId: issueIdentifierId,
            value: 'PROCESS_COMPLETE',
          });
          timer.stop();
        }
        if (issueStatusRes === 'Failed') {
          setMissionStatusContext(dispatchUploadMissions, {
            missionId: issueIdentifierId,
            value: 'PROCESS_FAILED',
          });
          timer.stop();
        }
      },
    };
    timer.add(task1).start();
  };

  return (
    <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <MDBox pl={1} mb={5} display="flex">
          <MDTypography variant="h3">
            {intl.get('new_text_new_task')}
          </MDTypography>
        </MDBox>

        <Grid spacing={3} container>
          <Grid xs={6} item>
            <Grid container spacing={3}>
              {currentUsername === 'cvic_super' && (
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    id="srcOrgName"
                    value={formState.srcOrgName}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.id)
                        handleSrcDstForCVICSuperUser(
                          formConstants.SOURCE_ORG_ID,
                          newValue
                        );
                    }}
                    options={getCVICSuperUserSourceOrgs(CvicSuperUserOrgs)}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        label={'From: '}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <FormFieldHelperText style={{ color: 'red' }}>
                    Source Organization
                  </FormFieldHelperText>
                </Grid>
              )}
              {currentUsername === 'cvic_super' && (
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    id="dstOrgName"
                    value={formState.dstOrgName}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.id)
                        handleSrcDstForCVICSuperUser(
                          formConstants.DEST_ORG_ID,
                          newValue
                        );
                    }}
                    options={getCVICSuperUserDestinationOrgs(
                      CvicSuperUserOrgs,
                      formState.srcOrgId
                    )}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        label={'To: '}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <FormFieldHelperText style={{ color: 'red' }}>
                    Destination Organization
                  </FormFieldHelperText>
                </Grid>
              )}
              {!(currentUsername === 'cvic_super') && (
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    id="dstOrgName"
                    onChange={(event, newValue) => {
                      handleChangeOnForm(formConstants.DEST_ORG_ID, newValue);
                    }}
                    options={getDstOrgOptions(dstOrgs)}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        label={intl.get('new_label_send_to')}
                        InputLabelProps={{ shrink: true }}
                        // variant="outlined"
                      />
                    )}
                    key={toggleResetDstOrgName}
                  />
                  <FormFieldHelperText style={{ color: 'red' }}>
                    {intl.get('new_helper_send_to')}
                  </FormFieldHelperText>
                </Grid>
              )}

              <Grid item xs={6} sm={6}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    handleChangeOnForm(formConstants.EMERGENCY, newValue);
                  }}
                  options={getEmergencyOptions()}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label={intl.get('new_label_emergency')}
                      InputLabelProps={{ shrink: true }}
                      // variant="outlined"
                    />
                  )}
                  key={toggleResetEmergency}
                />
                <FormFieldHelperText>
                  {intl.get('new_helper_emergency')}
                </FormFieldHelperText>
              </Grid>

              <Grid item xs={6} sm={6}>
                <FormField
                  variant="outlined"
                  label={intl.get('new_label_patient_name')}
                  onChange={handleChange(formConstants.PATIENT_NAME)}
                  value={formState.patientName}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <AutocompleteGender
                  id="patientGender"
                  value={genderValue}
                  onChange={(event, newValue) => {
                    handleChangeOnForm(formConstants.PATIENT_GENDER, newValue);
                    if (newValue && newValue.value) {
                      setGenderValue(getGenderValue(newValue.value));
                    } else {
                      setGenderValue('');
                    }
                  }}
                  options={getGenderOptions()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={intl.get('new_label_patient_gender')}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                <FormFieldHelperText>
                  {intl.get('new_helper_select_gender')}
                </FormFieldHelperText>
              </Grid>

              <Grid item xs={6} sm={6}>
                <FormField
                  variant="outlined"
                  label={intl.get('new_label_department')}
                  onChange={handleChange(formConstants.DEPARTMENT)}
                  value={formState.department}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Autocomplete
                  multiple
                  id="doctor-in-charge"
                  options={doctorOptions}
                  freeSolo
                  value={doctorsInCharge}
                  onChange={(event, newValue) => {
                    if (newValue.length > doctorsInCharge.length) {
                      const lastNewValue = newValue[newValue.length - 1];
                      if (typeof lastNewvalue !== 'string') {
                        let i = 0;
                        for (i = 0; i < newValue.length - 1; i++) {
                          if (
                            typeof newValue[i] !== 'string' &&
                            newValue[i].id === lastNewValue.id
                          ) {
                            break;
                          }
                        }
                        if (i !== newValue.length - 1) {
                          newValue.splice(newValue.length - 1);
                          newValue.splice(i, 1);
                        }
                      }
                    }
                    const newDoctors = newValue.map((doctor) => {
                      if (typeof doctor === 'string') {
                        return {
                          label: doctor,
                          id: -1,
                        };
                      }
                      return doctor;
                    });
                    handleChangeDoctors(newDoctors);
                    setDoctorsInCharge(newDoctors);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        color={darkMode ? 'white' : 'grey'}
                        deleteIconColor={darkMode ? 'white' : 'grey'}
                        label={option.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label={intl.get('new_label_doctor_in_charge')}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6} item>
            <Grid item xs={12} sm={12}>
              <MDTypography
                style={{
                  fontWeight: 'light',
                  fontSize: '12px',
                  color: 'light',
                  opacity: 0.7,
                  marginBottom: '10px',
                  marginTop: 0,
                }}>
                {intl.get('new_label_additional_info')}
              </MDTypography>
              <TextEditor
                className={classes.description}
                placeholder={intl.get('new_label_case_description')}
                defaultValue={formState.description}
                onChange={handleDescriptionChange}
                key={toggleResetDescription}
              />
            </Grid>
          </Grid>

          <Grid xs={12} item>
            <Grid container spacing={3}>
              <Grid item xs="auto" marginTop="30px">
                <MDButton
                  variant="gradient"
                  color="info"
                  width="50%"
                  onClick={() => setOpenCheckboxForm(!openCheckboxForm)}
                  style={{ color: 'white', fontSize: pxToRem(12) }}>
                  {intl.get('new_button_modal_tag')}
                </MDButton>
              </Grid>
              <Grid item xs={10}>
                <MDTypography style={{ paddingBottom: '20px' }}>
                  <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                    {'\u2022'} CT :
                  </span>{' '}
                  {createTags(showSelectedModalItems('CT'))}
                </MDTypography>
                <MDTypography style={{ paddingBottom: '20px' }}>
                  <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                    {'\u2022'} MRI :
                  </span>{' '}
                  {createTags(showSelectedModalItems('MRI'))}
                </MDTypography>
                <MDTypography style={{ paddingBottom: '20px' }}>
                  <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                    {'\u2022'} 生理検査 :
                  </span>{' '}
                  {showSelectedPhyExamModalItems()}
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <br />

        {structuredAttachments.length > 0 && (
          // <div style={{ overFlowY: "scroll", maxHeight: "50vh"}}>
          <Grid spacing={5} container>
            <Grid item xs={12}>
              <MDTypography style={{ marginTop: '25px', marginBottom: '15px' }}>
                {intl.get('new_text_attachments')}
              </MDTypography>
            </Grid>

            <Grid item xs={6}>
              <Grid item xs={12}>
                <MDBox
                  style={{
                    marginTop: '5px',
                    marginBottom: '45px',
                  }}>
                  <MDTypography>{intl.get('new_text_files')}</MDTypography>
                </MDBox>
              </Grid>
              {generateFileList(structuredAttachments, handleFileDelete)}
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <MDBox
                  style={{
                    marginTop: '5px',
                    marginBottom: '45px',
                  }}>
                  <MDTypography>
                    {intl.get('new_text_directories')}
                  </MDTypography>
                </MDBox>
              </Grid>
              {generateDirectoryList(
                structuredAttachments,
                handleFileDelete,
                darkMode
              )}
            </Grid>
          </Grid>
          // </div>
        )}

        <Grid spacing={3} container>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <DashboardNewDropZone handleFiles={handleFiles} />
          </Grid>
        </Grid>

        <Grid spacing={3} container alignItems="flex-end">
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              style={{ marginTop: '15px', marginBottom: '10px' }}>
              <MDButton
                variant="gradient"
                color="info"
                // fullWidth
                // width="80%"
                onClick={handleSubmitClick}
                style={{ color: 'white', fontSize: pxToRem(18) }}>
                {intl.get('new_button_submit')}
              </MDButton>
            </Box>
          </Grid>
        </Grid>
      </MDBox>

      {/* Modals */}
      <ConfirmModal
        isOpened={sendNewIssueFailed}
        title={intl.get('new_modal_new_issue_failed_message_1')}
        onConfirm={handleNewIssueFailModalClose}
        confirmButtonText={intl.get('new_modal_new_issue_failed_message_4')}>
        {
          <div>
            <MDTypography mb={4}>
              {intl.get('new_modal_new_issue_failed_message_2')}
            </MDTypography>
            <MDTypography mb={1}>
              {intl.get('new_modal_new_issue_failed_message_3')}
            </MDTypography>
          </div>
        }
      </ConfirmModal>

      <ConfirmModal
        isOpened={exceedUploadMissionsLimit}
        title={intl.get('new_modal_exceed_upload_limit_message_1')}
        bodyText={`${intl.get('new_modal_exceed_upload_limit_message_2')}
  ${intl.get('new_modal_exceed_upload_limit_message_3')}`}
        onConfirm={handleExceedUploadMissionsLimitClose}
        confirmButtonText={intl.get(
          'new_modal_exceed_upload_limit_button_close'
        )}
      />

      <ConfirmModal
        isOpened={warnFormIncomplete}
        title={intl.get('new_modal_text_warning_title')}
        body={intl.get('new_modal_text_warning_text_empty')}
        onConfirm={() => setWarnFormIncomplete(false)}
        confirmButtonText={intl.get('new_modal_tag_confirm')}>
        <MDBox>
          <br />
          {incompleteFields &&
            incompleteFields.map((field, index) => (
              <p style={{ color: 'red' }} key={index}>
                {field}
              </p>
            ))}
          <br />
          <MDTypography mb={1}>
            {intl.get('new_modal_text_warning_text_fill')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      {/* Modal of confirmation sending info to the server */}
      <ConfirmModal
        isOpened={showWarningBeforeSend}
        title={intl.get('new_modal_text_confirm_title')}
        bodyText={`*${intl.get('new_modal_text_confirm_text_correct')}`}
        onConfirm={sendNewIssue}
        onCancel={handleShowWarningBeforeSendModalClose}
        confirmButtonText={intl.get('new_modal_button_submit')}
        cancelButtonText={intl.get('new_modal_button_cancel')}>
        <MDBox sx={{ overFlowY: 'auto' }}>
          {showWarningBeforeSend &&
            generateConfirmWindowContents(
              formState,
              structuredAttachments,
              checkboxForm,
              darkMode
            )}

          <Grid
            spacing={3}
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            style={{ marginTop: '20px' }}>
            <Grid xs={12} item>
              <FormControlLabel
                style={{ color: darkMode ? 'white' : 'black' }}
                control={
                  <Checkbox
                    icon={
                      <CheckBoxOutlineBlankIcon style={{ fill: '#66ccff' }} />
                    }
                    onChange={handleConfirmContentsCheckbox}
                  />
                }
                label={intl.get('new_modal_text_confirm_text_sure')}
              />
            </Grid>
          </Grid>
        </MDBox>
      </ConfirmModal>

      {/*//! Different irregular modal - Open the more info modal, where select  */}
      <Modal
        open={openCheckboxForm}
        onClose={() => setOpenCheckboxForm(false)}
        className={classes.modalModal}>
        <CustomCheckboxForm
          checkboxForm={checkboxForm}
          setCheckboxForm={setCheckboxForm}
          openCheckboxForm={openCheckboxForm}
          setOpenCheckboxForm={setOpenCheckboxForm}
        />
      </Modal>

      <ConfirmModal
        isOpened={showWarnInvalidFileTypes}
        title={intl.get('new_modal_invalid_files_title')}
        bodyText={intl.get('new_modal_invalid_files_message_1')}
        onConfirm={handleClosingWarnInvalidFileWindow}
        confirmButtonColor="info"
        confirmButtonText={intl.get(
          'new_modal_exceed_upload_limit_button_close'
        )}>
        <MDBox>
          {invalidFiles.map((file, index) => (
            <MDTypography style={{ color: 'red' }} key={index}>
              {file.path}
            </MDTypography>
          ))}
          <br />
          <MDTypography mb={1}>
            {intl.get('new_modal_invalid_files_message_2')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      {/* Changed into 2 buttons since the last button is useless*/}
      <ConfirmModal
        isOpened={duplicatedAttachments.length > 0}
        title={intl.get('new_modal_duplicate_files_message_1')}
        onConfirm={() => handleDuplicateAttachments('REPLACE')}
        onCancel={() => handleDuplicateAttachments('IGNORE')}
        confirmButtonColor="info"
        confirmButtonText={intl.get('new_modal_duplicate_files_replace')}
        cancelButtonColor="error"
        cancelButtonText={intl.get('new_modal_duplicate_files_ignore')}>
        <MDBox>
          {duplicatedAttachments.map((file, index) => (
            <MDTypography style={{ color: 'red' }} key={index}>
              {file.fileFullPath}
            </MDTypography>
          ))}
          <MDTypography>
            {intl.get('new_modal_duplicate_files_message_2')}
          </MDTypography>
          <br />
        </MDBox>
      </ConfirmModal>
    </Card>
  );
};

DashboardNew.propTypes = propTypes;

export default withRouter(DashboardNew);

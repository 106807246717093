import { css } from 'styled-components';
import Color from 'color';

import {
  IssueType,
  IssueStatus,
  IssuePriority,
  IssueStatusForClient,
} from 'shared/constants/issues';

export const color = {
  primary: '#0052cc', // Blue
  success: '#0B875B', // green
  danger: '#E13C3C', // red
  warning: '#F89C1C', // orange
  secondary: '#F4F5F7', // light grey

  textDarkest: '#172b4d',
  textDark: '#42526E',
  textMedium: '#5E6C84',
  textLight: '#8993a4',
  textLink: '#0052cc',
  textPrimary: '#ffffff',

  backgroundDarkPrimary: '#0747A6',
  backgroundMedium: '#dfe1e6',
  backgroundLight: '#ebecf0',
  backgroundLightest: '#F4F5F7',
  backgroundLightPrimary: '#D2E5FE',
  backgroundLightSuccess: '#E4FCEF',

  borderLightest: '#dfe1e6',
  borderLight: '#C1C7D0',
  borderInputFocus: '#4c9aff',
};

export const issueTypeColors = {
  [IssueType.TASK]: '#4FADE6', // blue
  // [IssueType.STORY]: '#65BA43', // green
};

export const issuePriorityColors = {
  [IssuePriority.HIGHEST]: '#CD1317', // red
  [IssuePriority.HIGH]: '#E9494A', // orange
  [IssuePriority.MEDIUM]: '#E97F33', // orange
  [IssuePriority.LOW]: '#2D8738', // green
  // [IssuePriority.LOWEST]: '#57A55A', // green
};

export const issueStatusColors = {
  [IssueStatus.NEWTASK]: color.textDark,
  [IssueStatus.CHECKREADY]: color.textPrimary,
  [IssueStatus.DOCTORASSIGNED]: color.textPrimary,
  [IssueStatus.REPORTREADY]: color.textPrimary,
  [IssueStatus.SENDBACK]: color.textPrimary,
  [IssueStatus.DONE]: color.textDark,
  [IssueStatusForClient.NEWTASK]: color.textDark,
  [IssueStatusForClient.CHECKREADY]: color.textPrimary,
  [IssueStatusForClient.DOCTORASSIGNED]: color.textPrimary,
  [IssueStatusForClient.REPORTREADY]: color.textPrimary,
  [IssueStatusForClient.SENDBACK]: color.textPrimary,
  [IssueStatusForClient.DONE]: color.textDark,
};

export const issueStatusBackgroundColors = {
  [IssueStatus.NEWTASK]: color.warning,
  [IssueStatus.CHECKREADY]: color.primary,
  [IssueStatus.DOCTORASSIGNED]: color.primary,
  [IssueStatus.REPORTREADY]: color.primary,
  [IssueStatus.SENDBACK]: color.success,
  [IssueStatus.DONE]: color.backgroundMedium,
  [IssueStatusForClient.NEWTASK]: color.warning,
  [IssueStatusForClient.CHECKREADY]: color.primary,
  [IssueStatusForClient.DOCTORASSIGNED]: color.primary,
  [IssueStatusForClient.REPORTREADY]: color.primary,
  [IssueStatusForClient.SENDBACK]: color.success,
  [IssueStatusForClient.DONE]: color.backgroundMedium,
};

export const sizes = {
  appNavBarLeftWidth: 64,
  secondarySideBarWidth: 230,
  minViewportWidth: 1000,
};

export const zIndexValues = {
  modal: 1000,
  dropdown: 101,
  navLeft: 100,
};

export const font = {
  regular: 'font-family: "CircularStdBook"; font-weight: normal;',
  medium: 'font-family: "CircularStdMedium"; font-weight: normal;',
  bold: 'font-family: "CircularStdBold"; font-weight: normal;',
  black: 'font-family: "CircularStdBlack"; font-weight: normal;',
  size: (size) => `font-size: ${size}px;`,
};

export const mixin = {
  darken: (colorValue, amount) => Color(colorValue).darken(amount).string(),
  lighten: (colorValue, amount) => Color(colorValue).lighten(amount).string(),
  rgba: (colorValue, opacity) => Color(colorValue).alpha(opacity).string(),
  boxShadowMedium: css`
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  `,
  boxShadowDropdown: css`
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.31) 0px 0px 1px;
  `,
  truncateText: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  clickable: css`
    cursor: pointer;
    user-select: none;
  `,
  hardwareAccelerate: css`
    transform: translateZ(0);
  `,
  cover: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  placeholderColor: (colorValue) => css`
    ::-webkit-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    ::-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-ms-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
  `,
  scrollableY: css`
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  `,
  customScrollbar: ({
    width = 8,
    background = color.backgroundMedium,
  } = {}) => css`
    &::-webkit-scrollbar {
      width: ${width}px;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background: ${background};
    }
  `,
  backgroundImage: (imageURL) => css`
    background-image: url('${imageURL}');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${color.backgroundLight};
  `,
  link: (colorValue = color.textLink) => css`
    cursor: pointer;
    color: ${colorValue};
    ${font.medium}
    &:hover, &:visited, &:active {
      color: ${colorValue};
    }
    &:hover {
      text-decoration: underline;
    }
  `,
  tag: (
    background = color.backgroundMedium,
    colorValue = color.textDarkest
  ) => css`
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    color: ${colorValue};
    background: ${background};
    ${font.bold}
    ${font.size(12)}
    i {
      margin-left: 4px;
    }
  `,
  tagKanbanFilter: (
    background = color.backgroundMedium,
    colorValue = color.textDarkest
  ) => css`
    max-height: 48px;
    max-width: 600px;
    display: inline-flex;
    align-items: center;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    color: ${colorValue};
    background: ${background};
    ${font.bold}
    ${font.size(16)}
    i {
      margin-left: 6px;
    }
  `,
  tagIssueListStatus: (
    background = color.backgroundMedium,
    colorValue = color.textDarkest
  ) => css`
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    user-select: none;
    color: ${colorValue};
    background: ${background};
    ${font.bold}
    ${font.size(12)}
    i {
      margin-left: 4px;
    }
  `,
};

import React, { useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import CornerstoneViewerport from 'react-cornerstone-viewport';
import { withRouter } from 'react-router';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './styles';

const propTypes = {
  dicomFilePaths: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

const DashboardKanbanIssueDetailsViewport = ({ dicomFilePaths, close }) => {
  const classes = useStyles();

  // const tabStyle = { color: darkMode ? 'white' : 'grey'};

  const [selectedIndex, setSelectedIndex] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleClickRow = (event, newSelectedIndex) => {
    setSelectedIndex(newSelectedIndex);
  };

  const tools = [
    {
      name: 'Wwwc',
      mode: 'active',
      modeOptions: { mouseButtonMask: 1 },
    },
    {
      name: 'Zoom',
      mode: 'active',
      modeOptions: { mouseButtonMask: 2 },
    },
    {
      name: 'Pan',
      mode: 'active',
      modeOptions: { mouseButtonMask: 4 },
    },
    {
      name: 'StackScrollMouseWheel',
      mode: 'active',
    },
    {
      name: 'PanMultiTouch',
      mode: 'active',
    },
    {
      name: 'ZoomTouchPinch',
      mode: 'active',
    },
    {
      name: 'StackScrollMultiTouch',
      mode: 'active',
    },
  ];

  const series = [];
  const keys = [];
  const tableRowData = [];
  for (let key in dicomFilePaths) {
    const value = dicomFilePaths[key];
    series.push(value.seriesNumber);
    keys.push(key);
    tableRowData.push({
      seriesNumber: value.seriesNumber ? value.seriesNumber : '',
      protocol: value.protocolName ? value.protocolName : '',
      modality: value.modality ? value.modality : '',
      bodypart: value.bodypart ? value.bodypart : '',
    });
  }

  return (
    <Grid
      sx={{
        background: 'black',
        borderBottom: 1,
        borderColor: 'divider',
        height: '85vh',
      }}>
      <Grid container className={classes.actionGrid} xs={12} justify="flex-end">
        <div style={{ background: 'black' }}>
          {/* <Button icon="close" iconSize={24} variant="empty" color="black" onClick={close} /> */}
          <IconButton onClick={close}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </div>
      </Grid>
      <Grid className={classes.viewportGrid} xs={12}>
        <CornerstoneViewerport
          // imageIds={dicomFilePaths[keys[value]].filePaths}
          imageIds={dicomFilePaths[keys[selectedIndex]].filePaths}
          tools={tools}
        />
      </Grid>
      <Grid className={classes.studyListGrid}>
        <Paper className={classes.studyListPaper}>
          <TableContainer className={classes.studyListTableContainer}>
            <Table
              className={classes.studyListTable}
              stickyHeader
              size="small"
              aria-label="study list">
              <TableHead className={classes.studyListTableHead}>
                <TableRow className={classes.studyListTableHeadRow}>
                  <TableCell
                    className={classes.studyListTableHeadCell}
                    align="center"
                    style={{ width: '25%' }}>
                    {intl.get(
                      'issue_details_viewport_table_header_series_number'
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.studyListTableHeadCell}
                    align="center"
                    style={{ width: '25%' }}>
                    {intl.get('issue_details_viewport_table_header_protocol')}
                  </TableCell>
                  <TableCell
                    className={classes.studyListTableHeadCell}
                    align="center"
                    style={{ width: '25%' }}>
                    {intl.get('issue_details_viewport_table_header_modality')}
                  </TableCell>
                  <TableCell
                    className={classes.studyListTableHeadCell}
                    align="center"
                    style={{ width: '25%' }}>
                    {intl.get('issue_details_viewport_table_header_bodypart')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.studyListTableBody}>
                {tableRowData.map((rowData, index) => {
                  const isRowSelected = index === selectedIndex ? true : false;
                  const classNameUnselected =
                    index % 2 === 0
                      ? classes.studyListTableBodyCellLight
                      : classes.studyListTableBodyCellDark;
                  return (
                    <TableRow
                      className={classes.studyListTableBodyRow}
                      key={index}
                      hover
                      onClick={(event) => handleClickRow(event, index)}
                      selected={isRowSelected}>
                      <TableCell
                        className={
                          isRowSelected
                            ? classes.studyListTableBodyCellSelected
                            : classNameUnselected
                        }
                        align="center"
                        style={{ width: '25%' }}>
                        {rowData.seriesNumber}
                      </TableCell>
                      <TableCell
                        className={
                          isRowSelected
                            ? classes.studyListTableBodyCellSelected
                            : classNameUnselected
                        }
                        align="center"
                        style={{ width: '25%' }}>
                        {rowData.protocol}
                      </TableCell>
                      <TableCell
                        className={
                          isRowSelected
                            ? classes.studyListTableBodyCellSelected
                            : classNameUnselected
                        }
                        align="center"
                        style={{ width: '25%' }}>
                        {rowData.modality}
                      </TableCell>
                      <TableCell
                        className={
                          isRowSelected
                            ? classes.studyListTableBodyCellSelected
                            : classNameUnselected
                        }
                        align="center"
                        style={{ width: '25%' }}>
                        {rowData.bodypart}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

DashboardKanbanIssueDetailsViewport.propTypes = propTypes;

export default withRouter(DashboardKanbanIssueDetailsViewport);

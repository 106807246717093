import { createTheme } from '@material-ui/core/styles';

export const getTablesTheme = (darkMode) =>
  createTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      // grey: {
      //   800: "#000000", // overrides failed
      //   900: "#121212" // overrides success
      // },
      background: {
        paper: darkMode ? '#1f283e' : 'white',
      },
    },

    // DO NOT DELETE code below; IT WORKS as well, and might be useful in future;
    // Reference:
    //  https://github.com/gregnb/mui-datatables/issues/1214
    //  https://github.com/gregnb/mui-datatables/blob/master/examples/customize-styling/index.js
    //  https://github.com/gregnb/mui-datatables/issues/1842

    // overrides: {
    //   MUIDataTable: {
    //     root: {
    //       backgroundColor: '#000000 !important',
    //     },
    //     paper: {
    //       background: '#ff0000 !important',
    //     },
    //   },
    //   MuiTableCell: {
    //     head: {
    //         backgroundColor: "#000000 !important"
    //     }
    //   },
    //   MuiButton: {
    //     text: {
    //       color: 'white! important'
    //     }
    //   },
    // }
  });

export const treeItemTheme = createTheme({
  components: {
    // Name of the component
    MuiTreeView: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: 'black',
          backgroundColor: '#ffffff',
        },
      },
    },

    MuiTreeItem: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: 'black',
          backgroundColor: '#ffffff',
        },
      },
    },
  },
});

export const tagIntlValues = {
  CT1: '冠動脈＋心エコー',
  CT2: '冠動脈',
  CT3: '大動脈',
  CT4: '胸部',
  CT5: '腹部',
  CT6: '肺',
  CT7: '造影',
  CT8: '非造影',
  CT9: '腎動脈',
  CT10: 'その他',
  MRI1: '心臓',
  MRI2: '非造影',
  MRI3: '造影',
  MRI4: '一任',
  MRI5: '要',
  MRI6: '不要',
  MRI7: '一任',
  MRI8: '脳 (MRAを含む)',
  MRI9: '大動脈',
  MRI10: '胸部',
  MRI11: '腹部',
  MRI12: '腎動脈',
  MRI13: '下肢動脈・静脈',
  MRI14: 'その他',
  PhyExam1: '心エコー',
  PhyExam2: '頸動脈エコー',
  PhyExam3: 'ABI',
  PhyExam4: 'ホルター心電図',
  MRICor: '冠動脈MRAによる狭窄評価',
};

// These dictionary will contain the relationships for the tags to work
export const tagRelationships = {
  CT3: 'CTGroup1',
  CT4: 'CTGroup1',
  CT5: 'CTGroup1',
  CT6: 'CTGroup2',
  CT7: 'CTGroup2',
  CT8: 'CTGroup2',
  MRI1: 'MRIGroup1',
  MRI2: 'MRIGroup1',
  MRI3: 'MRIGroup1',
  MRI4: 'MRIGroup1',
  MRI5: 'MRIGroup2',
  MRI6: 'MRIGroup2',
  MRI7: 'MRIGroup2',
  MRI9: 'MRIGroup3',
  MRI10: 'MRIGroup3',
  MRI11: 'MRIGroup3',
};

import intl from 'react-intl-universal';

export const UserType = {
  DOCTOR: 'doctor',
  STAFF: 'staff',
  MANAGER: 'manager',
};

export const getUserType = (userType) => {
  if (userType === UserType.DOCTOR) {
    return intl.get('shared_value_user_type_doctor');
  } else if (userType === UserType.STAFF) {
    return intl.get('shared_value_user_type_staff');
  } else if (userType === UserType.MANAGER) {
    return intl.get('shared_value_user_type_manager');
  }
};

import { Card, Modal } from '@mui/material';
import MDButton from 'components/MDButton';
import classNames from 'classnames';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import pxToRem from 'assets/theme/functions/pxToRem';
import PropTypes from 'prop-types';

const propTypes = {
  isOpened: PropTypes.boolean,
  onClose: PropTypes.func,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
      close: PropTypes.boolean,
    })
  ),
  title: PropTypes.string,
  bodyText: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.styles,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

// MultiButton Modal Component that will show multiple buttons in a modal
export const MultiButtonModal = ({
  isOpened,
  onClose,
  actionButtons,
  title,
  bodyText,
  className,
  style,
  children,
}) => {
  return (
    <Modal
      disableEscapeKeyDown
      open={isOpened}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '50vw',
      }}>
      <Card sx={style} className={classNames('multibutton-modal', className)}>
        <MDBox py={3} px={3} mt={3} mx={3}>
          {title && (
            <MDTypography id="modal-title" variant="h3" component="h2">
              {title}
            </MDTypography>
          )}
          <MDTypography id="modal-description" sx={{ mt: 2 }} component="pre">
            {bodyText}
          </MDTypography>
          {children && (
            <div className={classNames('modal-body')}>{children}</div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginTop: '25px',
              marginBottom: '10px',
            }}>
            {actionButtons.map(({ onClick, color, label, close }, index) => (
              <MDButton
                variant="gradient"
                color={color}
                style={{
                  color: 'white',
                  fontSize: pxToRem(18),
                }}
                onClick={() => (close ? onClose() : onClick?.())}
                key={index}>
                {label}
              </MDButton>
            ))}
          </div>
        </MDBox>
      </Card>
    </Modal>
  );
};

MultiButtonModal.propTypes = propTypes;

import React from 'react';
import intl from 'react-intl-universal';
import {
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import { useMaterialUIController } from 'context';
import { tagIntlValues } from '../../../shared/constants/tagsValues';

const multipleChoiceFromTwoTags = (tagName, tagValue, checkboxForm, tags) => {
  const { baseTag, option1, option2 } = tags;
  var updates = {};
  if (tagName === option1) {
    if (tagValue) {
      // selected option1;
      updates[baseTag] = true; // automatically select baseTag;
    } else {
      // de-selected option1;
      // do nothing for now
    }
  } else if (tagName === option2) {
    if (tagValue) {
      // selected option2;
      updates[baseTag] = true; // automatically select baseTag;
    } else {
      // de-selected option2;
      // do nothing for now
    }
  } else if (baseTag && tagName === baseTag && tagValue === false) {
    updates[option1] = false;
    updates[option2] = false;
  }

  return updates;
};

const singleChoiceFrom2Tags = (tagName, tagValue, checkboxForm, tags) => {
  const { baseTag, option1, option2 } = tags;
  var updates = {};
  if (tagName === option1) {
    if (tagValue) {
      // selected option1;
      updates[option2] = false; // automatically deselect option2;
      if (baseTag) updates[baseTag] = true; // automatically select baseTag;
    } else {
      // de-selected option1;
      // if (!checkboxForm[option2] && baseTag && checkboxForm[baseTag]){
      //   updates[baseTag] = false; // if option2 is false and baseTag is true; set baseTag to false;
      // }
    }
  } else if (tagName === option2) {
    if (tagValue) {
      // selected option2;
      updates[option1] = false; // automatically deselect option1;
      if (baseTag) updates[baseTag] = true; // automatically select baseTag;
    } else {
      // de-selected option2;
      // if (!checkboxForm[option1] && baseTag && checkboxForm[baseTag]){
      //   updates[baseTag] = false; // if option1 is not selected and baseTag is selected; de-select baseTag ;
      // }
    }
  } else if (baseTag && tagName === baseTag && tagValue === false) {
    updates[option1] = false;
    updates[option2] = false;
  }

  return updates;
};

const singleChoiceFrom3Tags = (tagName, tagValue, checkboxForm, tags) => {
  const { baseTag, option1, option2, option3 } = tags;
  var updates = {};
  if (tagName === option1) {
    if (tagValue) {
      // selected option1;
      updates[option2] = false; // automatically deselect option2;
      updates[option3] = false;
      if (baseTag) updates[baseTag] = true; // automatically select baseTag;
    } else {
      // de-selected option1;
      // if (!checkboxForm[option2] && !checkboxForm[option3] && baseTag && checkboxForm[baseTag]){
      //   updates[baseTag] = false; // if option2 and option3 is not selected and baseTag is selected; de-select baseTag;
      // }
    }
  } else if (tagName === option2) {
    if (tagValue) {
      // selected option2;
      updates[option1] = false; // automatically deselect option1;
      updates[option3] = false;
      if (baseTag) updates[baseTag] = true; // automatically select baseTag;
    } else {
      // de-selected option2;
      // if (!checkboxForm[option1] && !checkboxForm[option3] && baseTag && checkboxForm[baseTag]){
      //   updates[baseTag] = false; // if the other 2 options are not selected and baseTag is selected; de-select baseTag ;
      // }
    }
  } else if (tagName === option3) {
    if (tagValue) {
      // selected option3;
      updates[option2] = false; // automatically deselect option2;
      updates[option1] = false;
      if (baseTag) updates[baseTag] = true; // automatically select baseTag;
    } else {
      // de-selected option3;
      // if (!checkboxForm[option1] && !checkboxForm[option2] && baseTag && checkboxForm[baseTag]){
      //   updates[baseTag] = false; // if the other 2 options are not selected and baseTag is selected; de-select baseTag ;
      // }
    }
  } else if (baseTag && tagName === baseTag && tagValue === false) {
    updates[option1] = false;
    updates[option2] = false;
    updates[option3] = false;
  }

  return updates;
};

const handleTagsRelations = (tagName, tagValue, checkboxForm) => {
  var updates = {};
  if (tagName === 'CT3' || tagName === 'CT4' || tagName === 'CT5') {
    updates = multipleChoiceFromTwoTags(tagName, tagValue, checkboxForm, {
      baseTag: 'CT3',
      option1: 'CT4',
      option2: 'CT5',
    });
  } else if (tagName === 'CT6' || tagName === 'CT7' || tagName === 'CT8') {
    updates = singleChoiceFrom2Tags(tagName, tagValue, checkboxForm, {
      baseTag: 'CT6',
      option1: 'CT7',
      option2: 'CT8',
    });
  } else if (tagName === 'MRI9' || tagName === 'MRI10' || tagName === 'MRI11') {
    updates = multipleChoiceFromTwoTags(tagName, tagValue, checkboxForm, {
      baseTag: 'MRI9',
      option1: 'MRI10',
      option2: 'MRI11',
    });
  } else if (
    tagName === 'MRI1' ||
    tagName === 'MRI2' ||
    tagName === 'MRI3' ||
    tagName === 'MRI4'
  ) {
    updates = singleChoiceFrom3Tags(tagName, tagValue, checkboxForm, {
      baseTag: 'MRI1',
      option1: 'MRI2',
      option2: 'MRI3',
      option3: 'MRI4',
    });
  } else if (tagName === 'MRI5' || tagName === 'MRI6' || tagName === 'MRI7') {
    updates = singleChoiceFrom3Tags(tagName, tagValue, checkboxForm, {
      baseTag: undefined,
      option1: 'MRI5',
      option2: 'MRI6',
      option3: 'MRI7',
    });
  }
  updates[tagName] = tagValue;
  return updates;
};

const propTypes = {
  checkboxForm: PropTypes.object,
  setCheckboxForm: PropTypes.func.isRequired,
  openCheckboxForm: PropTypes.func.isRequired,
  setOpenCheckboxForm: PropTypes.func.isRequired,
};

export const CustomCheckboxForm = ({
  checkboxForm,
  setCheckboxForm,
  openCheckboxForm,
  setOpenCheckboxForm,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  //! Pass the different choices
  // TODO Need to pass specfic format
  // const tagStructure = {"CT": ["CT1", "CT2", "CT3", "CT4", "CT5", "CT6", "CT7", "CT8", "CT9", "CT10"]};

  const labelText = (t) => <MDTypography>{tagIntlValues[t]}</MDTypography>;

  const handleBoxClick = (event) => {
    const updates = handleTagsRelations(
      event.target.name,
      event.target.checked,
      checkboxForm
    );
    setCheckboxForm({ ...checkboxForm, ...updates });
  };

  return (
    <Card sx={{ margin: 4, marginTop: 3, width: 1200 }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <Grid spacing={3} container>
          {/* <Grid xs={6} item> */}

          {/* //! Need to make this loop and automatically format */}
          {/* CT Choices */}
          <Grid container spacing={3}>
            <Grid xs={1} item>
              <MDTypography varient="h1"> CT : </MDTypography>
            </Grid>

            <Grid xs={11} item>
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.CT1}
                        onChange={handleBoxClick}
                        name="CT1"
                      />
                    }
                    label={labelText('CT1')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.CT2}
                        onChange={handleBoxClick}
                        name="CT2"
                      />
                    }
                    label={labelText('CT2')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.CT3}
                        onChange={handleBoxClick}
                        name="CT3"
                      />
                    }
                    label={labelText('CT3')}
                  />
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '5px',
                    }}
                    control={<MDTypography> ( </MDTypography>}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.CT4}
                        onChange={handleBoxClick}
                        name="CT4"
                      />
                    }
                    label={labelText('CT4')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.CT5}
                        onChange={handleBoxClick}
                        name="CT5"
                      />
                    }
                    label={labelText('CT5')}
                  />
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '25px',
                    }}
                    control={<MDTypography>)</MDTypography>}
                  />
                </FormGroup>

                <FormGroup row>
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.CT6}
                        onChange={handleBoxClick}
                        name="CT6"
                      />
                    }
                    label={labelText('CT6')}
                  />
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '5px',
                    }}
                    control={<MDTypography> ( </MDTypography>}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.CT7}
                        onChange={handleBoxClick}
                        name="CT7"
                      />
                    }
                    label={labelText('CT7')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.CT8}
                        onChange={handleBoxClick}
                        name="CT8"
                      />
                    }
                    label={labelText('CT8')}
                  />
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '25px',
                    }}
                    control={<MDTypography> ) </MDTypography>}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.CT9}
                        onChange={handleBoxClick}
                        name="CT9"
                      />
                    }
                    label={labelText('CT9')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.CT10}
                        onChange={handleBoxClick}
                        name="CT10"
                      />
                    }
                    label={labelText('CT10')}
                  />
                </FormGroup>
                <MDTypography variant="body2">
                  ※ 単純CTの場合は、検査目的欄へ記入し、予約時にご依頼下さい。
                </MDTypography>
              </FormControl>
            </Grid>

            {/* MRI Choices */}
            <Grid xs={1} item>
              <MDTypography>MRI :</MDTypography>
            </Grid>
            <Grid xs={11} item>
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI1}
                        onChange={handleBoxClick}
                        name="MRI1"
                      />
                    }
                    label={labelText('MRI1')}
                  />
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '5px',
                    }}
                    control={<MDTypography> ( </MDTypography>}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI2}
                        onChange={handleBoxClick}
                        name="MRI2"
                      />
                    }
                    label={labelText('MRI2')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI3}
                        onChange={handleBoxClick}
                        name="MRI3"
                      />
                    }
                    label={labelText('MRI3')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI4}
                        onChange={handleBoxClick}
                        name="MRI4"
                      />
                    }
                    label={labelText('MRI4')}
                  />
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '25px',
                    }}
                    control={<MDTypography> ) </MDTypography>}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <MDTypography>※ 冠動脈MRAによる狭窄評価 </MDTypography>
                    }
                  />
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '5px',
                    }}
                    control={<MDTypography> ( </MDTypography>}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI5}
                        onChange={handleBoxClick}
                        name="MRI5"
                      />
                    }
                    label={labelText('MRI5')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI6}
                        onChange={handleBoxClick}
                        name="MRI6"
                      />
                    }
                    label={labelText('MRI6')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI7}
                        onChange={handleBoxClick}
                        name="MRI7"
                      />
                    }
                    label={labelText('MRI7')}
                  />
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '25px',
                    }}
                    control={<MDTypography> ) </MDTypography>}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI8}
                        onChange={handleBoxClick}
                        name="MRI8"
                      />
                    }
                    label={labelText('MRI8')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI9}
                        onChange={handleBoxClick}
                        name="MRI9"
                      />
                    }
                    label={labelText('MRI9')}
                  />
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '5px',
                    }}
                    control={<MDTypography> ( </MDTypography>}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI10}
                        onChange={handleBoxClick}
                        name="MRI10"
                      />
                    }
                    label={labelText('MRI10')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI11}
                        onChange={handleBoxClick}
                        name="MRI11"
                      />
                    }
                    label={labelText('MRI11')}
                  />
                  <FormControlLabel
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '25px',
                    }}
                    control={<MDTypography> ) </MDTypography>}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI12}
                        onChange={handleBoxClick}
                        name="MRI12"
                      />
                    }
                    label={labelText('MRI12')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI13}
                        onChange={handleBoxClick}
                        name="MRI13"
                      />
                    }
                    label={labelText('MRI13')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.MRI14}
                        onChange={handleBoxClick}
                        name="MRI14"
                      />
                    }
                    label={labelText('MRI14')}
                  />
                </FormGroup>
                <MDTypography variant="body2">
                  ※
                  心臓以外は原則非造影検査です。造影が必要な場合はご相談下さい。
                </MDTypography>
              </FormControl>
            </Grid>

            {/* 生理検査 Choices */}
            <Grid item xs={1}>
              <MDTypography variant="body2">生理検査:</MDTypography>
            </Grid>
            <Grid item xs={11}>
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.PhyExam1}
                        onChange={handleBoxClick}
                        name="PhyExam1"
                      />
                    }
                    label={labelText('PhyExam1')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.PhyExam2}
                        onChange={handleBoxClick}
                        name="PhyExam2"
                      />
                    }
                    label={labelText('PhyExam2')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.PhyExam3}
                        onChange={handleBoxClick}
                        name="PhyExam3"
                      />
                    }
                    label={labelText('PhyExam3')}
                  />
                  <FormControlLabel
                    style={{ color: darkMode ? 'white' : 'black' }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fill: '#66ccff' }}
                          />
                        }
                        checked={checkboxForm.PhyExam4}
                        onChange={handleBoxClick}
                        name="PhyExam4"
                      />
                    }
                    label={labelText('PhyExam4')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-end">
          <Grid xs={12} item>
            <Box display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="info"
                style={{
                  color: 'white',
                  width: '10%',
                }}
                onClick={() => setOpenCheckboxForm(!openCheckboxForm)}>
                {intl.get('new_modal_tag_confirm')}
              </MDButton>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

CustomCheckboxForm.propTypes = propTypes;

import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@material-ui/core/styles';

import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { updateArrayItemById } from 'shared/utils/javascript';
import { getSentTableColumns } from './SentTableColumns';
import { PageLoader, PageError, Modal } from 'components';

import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';

import PropTypes from 'prop-types';
import IssueDetails from '../Kanban/IssueDetails';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';

import { IssueStatusForClient } from 'shared/constants/issues';
import CustomSearchRender from 'components/CustomSearchRender';

const propTypes = {
  match: PropTypes.obj,
  history: PropTypes.obj,
};

const DashboardSentHistory = ({ match, history }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [{ data, error, setLocalData }] = useApi.get('/api/issues/sent', {
    params: { organizationId: localData.get('currentOrganizationId') },
  });
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { sentIssues, users } = data;

  const updateLocalProjectIssues = (issueId, updatedFields) => {
    setLocalData((currentData) => ({
      sentIssues: updateArrayItemById(
        currentData.sentIssues,
        issueId,
        updatedFields
      ),
      users: users,
    }));
  };

  const handleOpenIssueDetails = (issueId) => {
    history.push(`${match.path}/issues/${issueId}`);
  };

  const tableData = sentIssues.map((issue) => {
    return [
      issue.title,
      issue.patientName,
      IssueStatusForClient[issue.status],
      issue.state,
      issue.createdAt,
      issue.deadline,
      // { priority: issue.priority,  deadline: issue.deadline },
      issue.dstOrgName,
      // issue.modality,
      // issue.bodypart,
      issue.id,
    ];
  });

  const columns = getSentTableColumns(handleOpenIssueDetails);

  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'None',
    // filterType: 'textField',
    responsive: 'stacked',
    // rowsPerPage: 20,
    rowsPerPageOptions: [10, 20, 50],
    // page: 1,
    // onRowClick: (rowData, rowState) => {
    //   // let _id = rowData[8];
    //   // history.push({
    //   //   pathname: `/viewer/${_id}`,
    //   // });
    //   const issueId = parseInt(rowData[8]);
    //   setSelectedIssueId(issueId);
    //   setModalIssueDetailsOpen(true);
    // },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={
            localData.get('userType') === 'doctor'
              ? intl.get('case_list_text_in_charge_table_title')
              : intl.get('case_list_text_sent_table_title')
          }
        />
      );
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
        // columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MUIDataTable
              title={
                localData.get('userType') === 'doctor'
                  ? intl.get('case_list_text_in_charge_table_title')
                  : intl.get('case_list_text_sent_table_title')
              }
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </MDBox>
      </Card>

      {/* {modalIssueDetailsOpen && <Modal 
        isOpen 
        testId="modal:issue-details"
        width={1040}
        withCloseIcon={false}
        onClose={()=>setModalIssueDetailsOpen(false)}
        renderContent={modal => (
          <IssueDetails 
            issueId={selectedIssueId}
            trigger={'byClick'}
            projectUsers={users}
            updateLocalProjectIssues={updateLocalProjectIssues}
            modalClose={modal.close}
          />
        )}
      />} */}

      <Route
        path={`${match.path}/issues/:issueId`}
        render={(routeProps) => (
          // Irregular
          <Modal
            isOpen
            testId="modal:issue-details"
            width={1540}
            withCloseIcon={false}
            onClose={() => history.push(match.url)}
            renderContent={(modal) => (
              <IssueDetails
                issueId={routeProps.match.params.issueId}
                trigger={'byClick'}
                projectUsers={users}
                updateLocalProjectIssues={updateLocalProjectIssues}
                modalClose={modal.close}
              />
            )}
          />
        )}
      />
    </Fragment>
  );
};

DashboardSentHistory.propTypes = propTypes;

export default withRouter(DashboardSentHistory);

import Icon from '@mui/material/Icon';
import intl from 'react-intl-universal';

import New from './New';
import ReceivedHistory from './ReceivedHistory';
import SentHistory from './SentHistory';
import BillingForReceivedCases from './Billing/BillingForReceivedCases';
import BillingForSentCases from './Billing/BillingForSentCases';
import Kanban from './Kanban';
import OrganizationSettings from './OrganizationSettings';
import OrganizationLogs from './OrganizationLogs';
import Manual from './Manual';
import ServiceTerms from './ServiceTerms';

export const analyzeRouteForBreadcrumbs = (route) => {
  let translatedRoute = [];

  if (route[0] === 'home')
    translatedRoute.push(intl.get('dashboard_button_home'));

  if (route[0] === 'personal-settings')
    translatedRoute.push(intl.get('dashboard_button_personal_settings'));

  if (route[0] === 'new')
    translatedRoute.push(intl.get('dashboard_button_new'));

  if (route[0] === 'received') {
    translatedRoute.push(intl.get('dashboard_button_received'));
    if (route[1] === 'history')
      translatedRoute.push(intl.get('dashboard_button_received_history'));
    if (route[1] === 'my-task')
      translatedRoute.push(intl.get('dashboard_button_task_management'));
  }

  if (route[0] === 'sent')
    translatedRoute.push(intl.get('dashboard_button_sent'));

  if (route[0] === 'in-charge')
    translatedRoute.push(intl.get('dashboard_button_in_charge'));

  if (route[0] === 'billing') {
    translatedRoute.push(intl.get('dashboard_button_billing'));
    if (route[1] === 'received')
      translatedRoute.push(intl.get('dashboard_button_billing_received'));
    if (route[1] === 'sent')
      translatedRoute.push(intl.get('dashboard_button_billing_sent'));
  }

  if (route[0] === 'settings')
    translatedRoute.push(intl.get('dashboard_button_organization_settings'));

  if (route[0] === 'logs')
    translatedRoute.push(intl.get('dashboard_button_organization_logs'));

  if (route[0] === 'manual')
    translatedRoute.push(intl.get('dashboard_button_manual'));

  if (route[0] === 'service-terms')
    translatedRoute.push(intl.get('dashboard_button_service_terms'));

  return translatedRoute;
};

export const getStaticMenuContents = (userInfo) => {
  const userType = userInfo.userType;
  const hasAffiliation = userInfo.organizations ? true : false;

  if (!hasAffiliation) {
    return [
      {
        type: 'collapse',
        name: intl.get('dashboard_button_service_terms'),
        key: 'service-terms',
        icon: <Icon fontSize="medium">handshake</Icon>,
        route: '/service-terms',
        component: <ServiceTerms />,
        noCollapse: true,
      },
    ];
  }

  if (userType === 'doctor') {
    const staticMenuContentsForDoctors = [
      {
        type: 'collapse',
        name: intl.get('dashboard_button_received'),
        key: 'received',
        icon: <Icon fontSize="medium">mail</Icon>,
        collapse: [
          {
            name: intl.get('dashboard_button_received_history'),
            key: 'received-history',
            route: '/received/history',
            component: <ReceivedHistory />,
          },
        ],
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_in_charge'),
        key: 'inCharge',
        icon: <Icon fontSize="medium">send</Icon>,
        route: '/in-charge',
        component: <SentHistory />,
        noCollapse: true,
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_manual'),
        key: 'manual',
        icon: <Icon fontSize="medium">lightbulb</Icon>,
        route: '/manual',
        component: <Manual />,
        noCollapse: true,
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_service_terms'),
        key: 'service-terms',
        icon: <Icon fontSize="medium">handshake</Icon>,
        route: '/service-terms',
        component: <ServiceTerms />,
        noCollapse: true,
      },
    ];

    return staticMenuContentsForDoctors;
  } else if (userType === 'staff') {
    const staticMenuContentsForStaffs = [
      {
        type: 'collapse',
        name: intl.get('dashboard_button_new'),
        key: 'new',
        route: '/new',
        icon: <Icon fontSize="medium">create</Icon>,
        component: <New userInfo={userInfo} />,
        noCollapse: true,
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_received'),
        key: 'received',
        icon: <Icon fontSize="medium">mail</Icon>,
        collapse: [
          {
            name: intl.get('dashboard_button_received_history'),
            key: 'history',
            route: '/received/history',
            component: <ReceivedHistory />,
          },
          {
            name: intl.get('dashboard_button_task_management'),
            key: 'my-task',
            route: '/received/my-task',
            component: <Kanban />,
          },
        ],
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_sent'),
        key: 'sent',
        icon: <Icon fontSize="medium">send</Icon>,
        route: '/sent',
        component: <SentHistory />,
        noCollapse: true,
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_billing'),
        key: 'billing',
        icon: <Icon fontSize="medium">currency_yen</Icon>,
        collapse: [
          {
            name: intl.get('dashboard_button_billing_received'),
            key: 'received-billing',
            route: '/billing/received-billing',
            component: <BillingForReceivedCases />,
          },
          {
            name: intl.get('dashboard_button_billing_sent'),
            key: 'sent-billing',
            route: '/billing/sent-billing',
            component: <BillingForSentCases />,
          },
        ],
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_manual'),
        key: 'manual',
        icon: <Icon fontSize="medium">lightbulb</Icon>,
        route: '/manual',
        component: <Manual />,
        noCollapse: true,
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_service_terms'),
        key: 'service-terms',
        icon: <Icon fontSize="medium">handshake</Icon>,
        route: '/service-terms',
        component: <ServiceTerms />,
        noCollapse: true,
      },
    ];

    return staticMenuContentsForStaffs;
  } else if (userType === 'manager') {
    const staticMenuContentsForManagers = [
      {
        type: 'collapse',
        name: intl.get('dashboard_button_new'),
        key: 'new',
        route: '/new',
        icon: <Icon fontSize="medium">create</Icon>,
        component: <New />,
        noCollapse: true,
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_received'),
        key: 'received',
        icon: <Icon fontSize="medium">mail</Icon>,
        collapse: [
          {
            name: intl.get('dashboard_button_received_history'),
            key: 'history',
            route: '/received/history',
            component: <ReceivedHistory />,
          },
          {
            name: intl.get('dashboard_button_task_management'),
            key: 'my-task',
            route: '/received/my-task',
            component: <Kanban />,
          },
        ],
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_sent'),
        key: 'sent',
        icon: <Icon fontSize="medium">send</Icon>,
        route: '/sent',
        component: <SentHistory />,
        noCollapse: true,
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_billing'),
        key: 'billing',
        icon: <Icon fontSize="medium">currency_yen</Icon>,
        collapse: [
          {
            name: intl.get('dashboard_button_billing_received'),
            key: 'received-billing',
            route: '/billing/received-billing',
            component: <BillingForReceivedCases />,
          },
          {
            name: intl.get('dashboard_button_billing_sent'),
            key: 'sent-billing',
            route: '/billing/sent-billing',
            component: <BillingForSentCases />,
          },
        ],
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_organization_settings'),
        key: 'settings',
        icon: <Icon fontSize="medium">settings</Icon>,
        route: '/settings',
        component: <OrganizationSettings />,
        // component: <></>,
        noCollapse: true,
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_organization_logs'),
        key: 'logs',
        icon: <Icon fontSize="medium">assignment</Icon>,
        route: '/logs',
        component: <OrganizationLogs />,
        // component: <></>,
        noCollapse: true,
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_manual'),
        key: 'manual',
        icon: <Icon fontSize="medium">lightbulb</Icon>,
        route: '/manual',
        component: <Manual />,
        noCollapse: true,
      },
      {
        type: 'collapse',
        name: intl.get('dashboard_button_service_terms'),
        key: 'service-terms',
        icon: <Icon fontSize="medium">handshake</Icon>,
        route: '/service-terms',
        component: <ServiceTerms />,
        noCollapse: true,
      },
    ];

    return staticMenuContentsForManagers;
  }
  return null;
};

import api from 'shared/utils/api';
import localData from 'shared/utils/localData';

export const updateIssue = (issueId, updatedFields) => {
  api.put(`/api/issues/${issueId}`, {
    params: {
      organizationId: localData.get('currentOrganizationId'),
    },
    data: updatedFields,
  });
};

export const triggerLogForInvoicePDF = (srcOrgName) => {
  api.post(`/api/logs`, {
    params: {
      organizationId: localData.get('currentOrganizationId'),
    },
    data: {
      type: 'pdf',
      contents: [srcOrgName],
      link: '',
    },
  });
};

import React, { Fragment, useState, useRef } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Typography, Modal, Grid } from '@material-ui/core';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
import useApi from 'shared/hooks/api';
import { PageError, Button } from 'components';
import { IssueStatus, IssueState } from 'shared/constants/issues';
import { StyledConfirmModal } from 'components/ConfirmModal/Styles';

import Loader from './Loader';
import Info from './Info';
import Description from './Description';
import Comments from './Comments';
import Status from './Status';
import AssigneesReporter from './AssigneesReporter';
import Priority from './Priority';
import EstimateTracking from './EstimateTracking';
import Dates from './Dates';
import ConfirmPopUpWindow from './ConfirmPopUpWindow';
import Download from './Download';
import Viewport from './Viewport';
import Preview from './Preview';
import { ModalForUploadingMoreAttachments } from './UploadMoreAttachmentsModal';
import {
  TopActions,
  TopActionsRight,
  Content,
  Left,
  Right,
  useStyles,
} from './Styles';
import {
  isObject,
  generateAttachmentList,
  generateAttchmentList_OLD,
} from './utils';
import { useMaterialUIController } from 'context';

const propTypes = {
  issueId: PropTypes.string.isRequired,
  trigger: PropTypes.string.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  projectUsers: PropTypes.array.isRequired,
  fetchProject: PropTypes.func.isRequired,
  updateLocalProjectIssues: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired,
};

const ProjectBoardIssueDetails = ({
  issueId,
  trigger,
  projectUsers,
  updateLocalProjectIssues,
  modalClose,
  match,
  history,
}) => {
  const classes = useStyles();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [viewportModalOpen, setViewModalOpen] = useState(false);
  const [viewerDicomFilePaths, setViewerDicomFilePaths] = useState([]);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalUpdateData, setConfirmModalUpdateData] = useState();
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    source: '',
    destination: '',
  });
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [attachmentObjToBeDownloaded, setAttachmentObjToBeDownloaded] =
    useState({});
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [attachmentObjToBePreviewed, setAttachmentObjToBePreviewed] = useState(
    {}
  );

  const [uploadMoreAttachmentsModalOpen, setUploadMoreAttachmentsModalOpen] =
    useState(false);

  // for comment functionalities
  const [refreshQuote, doRefreshQuote] = useState(0);
  const [quoteAttachmentName, setQuoteAttachmentName] = useState('');
  const commentEditorRef = useRef(null);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);

  if (!issueId) {
    issueId = match.params.issueId;
  }

  const userType = localData.get('userType');
  const currentOrganizationId = localData.get('currentOrganizationId');

  const [{ data, error, setLocalData }, fetchIssue] = useApi.get(
    `/api/issues/${issueId}`,
    {
      params: { organizationId: currentOrganizationId },
    }
  );

  if (error && error.status === 404) history.push('/home');
  if (!data) return <Loader />;
  if (error) return <PageError />;

  const { issue, user_quotas } = data;

  const srcOrgId = issue.srcOrgId.toString();
  // const dstOrgId = issue.dstOrgId.toString();

  const isClient = srcOrgId === currentOrganizationId;

  const updateLocalIssueDetails = (fields) => {
    // setLocalData == 使用某种定制化 getUpdatedData() 的 mergeState
    setLocalData(
      (
        currentData // currentData == data on line_ref_(*cyka) of query.js;  data是什么，currentData就是什么;
      ) => ({
        issue: { ...currentData.issue, ...fields },
        user_quotas: currentData.user_quotas,
      })
    );
  };

  const updateIssue = (updatedFields) => {
    if (trigger === 'byDrop') {
      if (updatedFields.users) {
        updatedFields = {
          status: IssueStatus.DOCTORASSIGNED,
          ...updatedFields,
        };
      }
    }

    api.optimisticUpdate(`/api/issues/${issueId}`, {
      updatedFields,
      currentFields: issue,
      setLocalData: (fields) => {
        updateLocalIssueDetails(fields);
        updateLocalProjectIssues(issue.id, fields);
      },
    });
  };

  const handleLinkButtonClick = (dicomFilePaths) => {
    setViewerDicomFilePaths(dicomFilePaths);
    setViewModalOpen(true);
  };

  const handleCommentButtonClick = (attachmentObj) => {
    setQuoteAttachmentName(attachmentObj.title);
    doRefreshQuote((prev) => prev + 1);
    setIsCommentFormOpen(true);
  };

  const handleDownloadButtonClick = (attachmentObj) => {
    setAttachmentObjToBeDownloaded(attachmentObj);
    setDownloadModalOpen(true);
  };

  const handlePreviewButtonClick = (attachmentObj) => {
    setAttachmentObjToBePreviewed(attachmentObj);
    setPreviewModalOpen(true);
  };

  const handleBlockQuoteTextClick = (attachmentName) => {
    const res = issue.attachments.filter(
      (item) => item.title === attachmentName
    );
    if (res && res.length > 0) {
      const item = res[0];
      const dicoms = res[0].dicomFilesInfo;
      if (isObject(dicoms) && Object.keys(dicoms).length > 0) {
        setViewerDicomFilePaths(dicoms);
        setViewModalOpen(true);
      } else {
        setAttachmentObjToBePreviewed(item);
        setPreviewModalOpen(true);
      }
    }
  };

  const handleBugButtonClick = (formerState) => {
    if (formerState === IssueState.NORMAL) {
      const state = IssueState.WARNING;
      updateIssue({ state });
    } else if (formerState === IssueState.WARNING) {
      const state = IssueState.NORMAL;
      updateIssue({ state });
    }
  };

  const handleUploadMoreAttachmentsModalClose = () => {
    setUploadMoreAttachmentsModalOpen(false);
  };

  const handleAddAttachmentButtonClick = () => {
    setUploadMoreAttachmentsModalOpen(true);
  };

  return (
    <Fragment>
      <TopActions>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{
            fontWeight: 'bold',
            marginTop: '5px',
            marginLeft: '14px',
            textAlign: 'right',
            alignSelf: 'stretch',
          }}>
          {issue.title}
        </Typography>

        <TopActionsRight>
          {/* <CopyLinkButton variant="empty" /> */}
          <FormGroup sx={{ mr: 5 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                style={{
                  color: issue.state === IssueState.NORMAL ? 'black' : 'grey',
                }}>
                {intl.get('shared_value_state_normal')}
              </Typography>

              <Switch
                checked={issue.state === IssueState.WARNING}
                onChange={() => handleBugButtonClick(issue.state)}
              />
              <Typography
                style={{
                  color: issue.state === IssueState.WARNING ? 'black' : 'grey',
                }}>
                {intl.get('shared_value_state_warning')}
              </Typography>
            </Stack>
          </FormGroup>

          {/* <Delete issue={issue} fetchProject={fetchProject} modalClose={modalClose} /> */}
          <Button
            icon="close"
            iconSize={24}
            variant="empty"
            onClick={modalClose}
          />
        </TopActionsRight>
      </TopActions>
      <Content>
        <Left>
          {/* <Title issue={issue} updateIssue={updateIssue} /> */}
          <Info issue={issue} updateIssue={updateIssue} isClient={isClient} />
          <Description
            issue={issue}
            updateIssue={updateIssue}
            isClient={isClient}
          />

          <Typography
            style={{
              marginTop: '15px',
              marginBottom: '15px',
            }}>
            {intl.get('issue_details_sender_attachment_files')}
          </Typography>

          {issue.id > 30
            ? generateAttachmentList({
                srcOrDst: 'src',
                orgId: issue.srcOrgId,
                attachments: issue.attachments,
                dicomFilePaths: issue.dicomFilePaths,
                darkMode,
                handleCommentButtonClick,
                handleDownloadButtonClick,
                handleLinkButtonClick,
                handlePreviewButtonClick,
              })
            : generateAttchmentList_OLD(
                issue.attachments,
                issue.dicomFilePaths,
                darkMode,
                handleDownloadButtonClick,
                handleLinkButtonClick
              )}

          <Typography
            style={{
              marginTop: '15px',
              marginBottom: '15px',
            }}>
            {intl.get('issue_details_receiver_attachment_files')}
          </Typography>

          {issue.id > 30
            ? generateAttachmentList({
                srcOrDst: 'dst',
                orgId: issue.dstOrgId,
                attachments: issue.attachments,
                dicomFilePaths: issue.dicomFilePaths,
                darkMode,
                handleCommentButtonClick,
                handleDownloadButtonClick,
                handleLinkButtonClick,
                handlePreviewButtonClick,
              })
            : generateAttchmentList_OLD(
                issue.attachments,
                issue.dicomFilePaths,
                darkMode,
                handleDownloadButtonClick,
                handleLinkButtonClick
              )}

          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="primary"
                icon="plus"
                iconSize={20}
                style={{ 'justify-content': 'start' }}
                onClick={handleAddAttachmentButtonClick}>
                {intl.get('issue_details_add_more_attachment_files')}
              </Button>
            </Grid>
          </Grid>

          <Comments
            issue={issue}
            fetchIssue={fetchIssue}
            isClient={isClient}
            updateLocalProjectIssues={updateLocalIssueDetails}
            handleDownloadButtonClick={handleDownloadButtonClick}
            handlePreviewButtonClick={handlePreviewButtonClick}
            quoteAttachmentName={quoteAttachmentName}
            refreshQuote={refreshQuote}
            commentEditorRef={commentEditorRef}
            isCommentFormOpen={isCommentFormOpen}
            setIsCommentFormOpen={setIsCommentFormOpen}
            handleBlockQuoteTextClick={handleBlockQuoteTextClick}
            handleAddAttachmentButtonClick={handleAddAttachmentButtonClick}
          />

          {/* {(['staff', 'manager'].includes(userType) && currentOrganizationId === srcOrgId) && 
            <Comments issue={issue} fetchIssue={fetchIssue} isClient={true} 
               updateLocalProjectIssues={updateLocalProjectIssues}
            /> 
          }
          {(['staff', 'manager'].includes(userType) && currentOrganizationId === dstOrgId) && 
            <Comments issue={issue} fetchIssue={fetchIssue} isClient={false} 
               updateLocalProjectIssues={updateLocalProjectIssues}
            /> 
          }    
          {(['doctor'].includes(userType) && currentOrganizationId === srcOrgId) && 
            <Comments issue={issue} fetchIssue={fetchIssue} isClient={true} 
               updateLocalProjectIssues={updateLocalProjectIssues}
            /> 
          } 
          {(['doctor'].includes(userType) && currentOrganizationId === dstOrgId) && 
            <Comments issue={issue} fetchIssue={fetchIssue} isClient={false} 
               updateLocalProjectIssues={updateLocalProjectIssues}
            /> 
          }  */}
        </Left>
        <Right>
          {['staff', 'manager'].includes(userType) && (
            <Status
              isClient={isClient}
              issue={issue}
              updateLocalIssueDetails={updateLocalIssueDetails}
              setConfirmModalInfo={setConfirmModalInfo}
              setConfirmModalUpdateData={setConfirmModalUpdateData}
              setConfirmModalOpen={setConfirmModalOpen}
            />
          )}
          {/* {(['staff', 'manager'].includes(userType) && currentOrganizationId === dstOrgId) &&
            <Status isClient={false} issue={issue} updateLocalIssueDetails={updateLocalIssueDetails} setConfirmModalInfo={setConfirmModalInfo} setConfirmModalUpdateData={setConfirmModalUpdateData} setConfirmModalOpen={setConfirmModalOpen} />
          } */}
          {['staff', 'manager'].includes(userType) &&
            !isClient &&
            issue.status !== IssueStatus.NEWTASK && (
              <AssigneesReporter
                issue={issue}
                updateIssue={updateIssue}
                user_quotas={user_quotas}
                projectUsers={projectUsers}
                updateLocalIssueDetails={updateLocalIssueDetails}
                setConfirmModalInfo={setConfirmModalInfo}
                setConfirmModalUpdateData={setConfirmModalUpdateData}
                setConfirmModalOpen={setConfirmModalOpen}
              />
            )}
          <Priority issue={issue} updateIssue={updateIssue} />
          <EstimateTracking issue={issue} updateIssue={updateIssue} />
          <Dates issue={issue} />
          {/* <Reports issue={issue} /> */}
        </Right>
      </Content>

      {/* Irregular */}
      <Modal
        open={viewportModalOpen}
        onClose={() => setViewModalOpen(false)}
        className={classes.viewportModal}>
        <div className={classes.viewportModalPaper}>
          <Viewport
            dicomFilePaths={viewerDicomFilePaths}
            close={() => setViewModalOpen(false)}
          />
        </div>
      </Modal>

      {confirmModalOpen && (
        <StyledConfirmModal
          isOpen
          testid="modal:confirm"
          width={740}
          withCloseIcon={false}
          onClose={() => {
            setConfirmModalOpen(false);
          }}
          renderContent={(modal) => (
            <ConfirmPopUpWindow
              modalClose={modal.close}
              updateIssue={updateIssue}
              confirmModalUpdateData={confirmModalUpdateData}
              confirmModalInfo={confirmModalInfo}
            />
          )}
        />
      )}

      {downloadModalOpen && (
        <StyledConfirmModal
          isOpen
          testId="modal:zipfile-download"
          width={740}
          withCloseIcon={false}
          onClose={() => {
            setDownloadModalOpen(false);
          }}
          renderContent={(modal) => (
            <Download
              issue={issue}
              attachment={attachmentObjToBeDownloaded}
              modalClose={modal.close}
            />
          )}
        />
      )}

      {/* Irregular */}
      <Modal
        open={previewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        className={classes.previewModal}>
        <div className={classes.previewModalPaper}>
          <Preview
            issue={issue}
            attachment={attachmentObjToBePreviewed}
            modalClose={() => setPreviewModalOpen(false)}
          />
        </div>
      </Modal>

      <ModalForUploadingMoreAttachments
        issue={issue}
        fetchIssue={fetchIssue}
        isOpen={uploadMoreAttachmentsModalOpen}
        handleModalClose={handleUploadMoreAttachmentsModalClose}
        darkMode={darkMode}
      />
    </Fragment>
  );
};

ProjectBoardIssueDetails.propTypes = propTypes;

export default withRouter(ProjectBoardIssueDetails);

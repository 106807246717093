import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoicePaymentMethod: PropTypes.object,
};

const InvoicePaymentMethod = ({ invoicePaymentMethod }) => {
  const bank = invoicePaymentMethod.bank;
  const branch = invoicePaymentMethod.branch;
  const accountType = invoicePaymentMethod.accountType;
  const accountNo = invoicePaymentMethod.accountNo;
  const accountName = invoicePaymentMethod.accountName;

  return (
    <Fragment>
      <View style={styles.invoicePaymentMethod}>
        <Text style={styles.invoicePaymentMethodText}>
          {intl.get('billing_invoice_text_transfer_destination')}
        </Text>
        <Text style={styles.invoicePaymentMethodText}>
          {bank + '  ' + branch}
        </Text>
        <Text style={styles.invoicePaymentMethodText}>
          {accountType + '  ' + accountNo}
        </Text>
        <Text style={styles.invoicePaymentMethodText}>{accountName}</Text>
      </View>
    </Fragment>
  );
};
InvoicePaymentMethod.propTypes = propTypes;

export default InvoicePaymentMethod;

import localData from './localData';
// const locale = 'jp'; // TODO: get the locale from API that reads the settings
// const lang = 'en'; // TODO: get the language preference from API that reads the settings

const toLocalTime = (srcTimeRepresentation) => {
  const srcTime = new Date(srcTimeRepresentation);
  const tgtTime = srcTime.toString();
  return tgtTime;
};

const formatDeadlineJa = (deadlineString) => {
  // TODO: finish the function to format deadline in Japanese
  return '';
};

const formatDeadlineZh = (deadlineString) => {
  // TODO: finish the function to format deadline in Chinese
  return '';
};

const formatDeadlineEn = (deadlineString) => {
  const deadline = new Date(deadlineString);

  if (deadline.getHours() < 9) {
    deadline.setDate(deadline.getDate() - 1);
  }
  const deadlineYear = deadline.getFullYear();
  const deadlineMonth = deadline.getMonth() + 1;
  const deadlineDate = deadline.getDate();
  const deadlineHour = deadline.getHours();
  var deadlineHourString = ' by';
  if (deadlineHour < 9) {
    deadlineHourString += ' 18:00'; // 18:00
  } else if (deadlineHour < 12) {
    deadlineHourString += ' 09:00'; // 09:00
  } else if (deadlineHour < 15) {
    deadlineHourString += ' 12:00'; // 12:00
  } else if (deadlineHour < 18) {
    deadlineHourString += ' 15:00'; // 15:00
  } else {
    deadlineHourString += ' 18:00'; // 18:00
  }
  const formatedDeadline =
    deadlineYear.toString() +
    '-' +
    deadlineMonth.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    '-' +
    deadlineDate.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    deadlineHourString;
  return formatedDeadline;
};

const formatDeadline = (timeRep) => {
  // timeRep is short for time representation
  // const timeStringLocal = toLocalTime(timeRep);
  const timeStringLocal = timeRep;

  const lang = localData.get('userPreferredLanguage');

  var formatedDeadline = '';
  if (lang === 'zh') {
    formatedDeadline = formatDeadlineZh(timeStringLocal);
  } else if (lang === 'ja') {
    formatedDeadline = formatDeadlineJa(timeStringLocal);
  } else {
    // the default is to treat the language as English
    formatedDeadline = formatDeadlineEn(timeStringLocal);
  }

  return formatedDeadline;
};

const formatDeadlineForEmergency = (timeRep) => {
  // timeRep is short for time representation
  const timeStringLocal = toLocalTime(timeRep);
  const deadline = new Date(timeStringLocal);
  const deadlineYear = deadline.getFullYear();
  const deadlineMonth = deadline.getMonth() + 1;
  const deadlineDate = deadline.getDate();
  const deadlineHour = deadline.getHours();
  const deadlineMinutes = deadline.getMinutes();

  const formatedDeadline =
    deadlineYear.toString() +
    '-' +
    deadlineMonth.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    '-' +
    deadlineDate.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    ' by ' +
    deadlineHour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    ':' +
    deadlineMinutes.toLocaleString(undefined, { minimumIntegerDigits: 2 });

  return formatedDeadline;
};

const calculateDeadlineType = (timeRep) => {
  const deadline = new Date(timeRep);
  const now = new Date();

  const timeDiff = deadline - now;

  if (timeDiff < 0) {
    return 'passed';
  } else if (timeDiff < 1800000) {
    return '30mins';
  } else if (timeDiff < 3600000) {
    return '1hour';
  } else if (timeDiff < 10800000) {
    return '3hours';
  } else if (timeDiff < 86400000) {
    return '24hours';
  } else {
    return '7days';
  }
};

const formatDeadlineWithType = (timeRep) => {
  const type = calculateDeadlineType(timeRep);

  const formatedDeadline =
    type === '7days'
      ? formatDeadline(timeRep)
      : formatDeadlineForEmergency(timeRep);

  return { type, formatedDeadline };
};

const formatTimeFromNowZh = (timeRep) => {
  // TODO: finish the function to format time in Chinese
  return '';
};

const formatTimeFromNowJa = (timeRep) => {
  // TODO: finish the function to format time in Japanese
  return '';
};

const formatTimeFromNowEn = (timeRep) => {
  return '';
};

const formatTimeFromNow = (timeRep) => {
  // timeRep is short for time representation
  const timeStringLocal = toLocalTime(timeRep);

  const lang = localData.get('userPreferredLanguage');

  var formatedTimeFromNow = '';
  if (lang === 'zh') {
    formatedTimeFromNow = formatTimeFromNowZh(timeStringLocal);
  } else if (lang === 'ja') {
    formatedTimeFromNow = formatTimeFromNowJa(timeStringLocal);
  } else {
    // the default language is English
    formatedTimeFromNow = formatTimeFromNowEn(timeStringLocal);
  }
  return formatedTimeFromNow;
};

const formatTimeZh = (timeRep) => {
  // TODO: finish this function to format time in Chinese
  return '';
};

const formatTimeJa = (timeRep) => {
  // TODO: finish this function to format time in Japanese
  const time = new Date(timeRep);
  const timeYear = time.getFullYear();
  const timeMonth = time.getMonth() + 1;
  const timeDate = time.getDate();
  const timeHours = time.getHours();
  const timeMinutes = time.getMinutes();
  const formatedTime =
    timeYear.toString() +
    '-' +
    timeMonth.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    '-' +
    timeDate.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    ' ' +
    timeHours.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    ':' +
    timeMinutes.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  return formatedTime;
};

const formatTimeEn = (timeRep) => {
  // TODO: finish this function to format time in English
  const time = new Date(timeRep);

  const timeYear = time.getFullYear();
  const timeMonth = time.getMonth() + 1;
  const timeDate = time.getDate();
  const timeDay = time.getDay();
  var timeDayString = '';
  switch (timeDay) {
    case 0:
      timeDayString = 'Sun';
      break;
    case 1:
      timeDayString = 'Mon';
      break;
    case 2:
      timeDayString = 'Tue';
      break;
    case 3:
      timeDayString = 'Wed';
      break;
    case 4:
      timeDayString = 'Thu';
      break;
    case 5:
      timeDayString = 'Fri';
      break;
    case 6:
      timeDayString = 'Sat';
      break;
    default:
      console.log('Wrong Day!');
      break;
  }

  const formatedTime =
    timeYear.toString() +
    '-' +
    timeMonth.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    '-' +
    timeDate.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    ' ' +
    timeDayString;
  return formatedTime;
};

const formatTime = (timeRep) => {
  // timeRep is short for time representation
  const timeStringLocal = toLocalTime(timeRep);

  const lang = localData.get('userPreferredLanguage');

  var formatedTime = '';
  if (lang === 'zh-CN') {
    formatedTime = formatTimeZh(timeStringLocal);
  } else if (lang === 'ja-JP') {
    formatedTime = formatTimeJa(timeStringLocal);
  } else {
    // the default language is English
    formatedTime = formatTimeEn(timeStringLocal);
  }

  return formatedTime;
};

export {
  formatDeadline,
  formatDeadlineForEmergency,
  formatDeadlineWithType,
  formatTimeFromNow,
  formatTime,
};

import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const propTypes = {
  onSearch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  tableTitle: PropTypes.string,
};

const defaultSearchStyles = (theme) => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
  },
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
});

class CustomSearchRender extends React.Component {
  handleTextChange = (event) => {
    this.props.onSearch(event.target.value);
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  };

  render() {
    const { classes, options, onHide, searchText, tableTitle } = this.props;

    return (
      <Grid
        container
        columnSpacing={3}
        xs={12}
        sx={{ marginTop: 3 }}
        className={classes.main}
        ref={(el) => (this.rootRef = el)}>
        {tableTitle}
        <Grid container item xs={8}>
          <Grid item xs={10}>
            <TextField
              // placeholder={'Custom TableSearch without search icon'}
              autoFocus={'true'}
              className={classes.searchText}
              InputProps={{
                'aria-label': options.textLabels.toolbar.search,
              }}
              value={searchText || ''}
              onChange={this.handleTextChange}
              fullWidth={true}
              inputRef={(el) => (this.searchField = el)}
            />
          </Grid>
          <Grid item>
            <IconButton className={classes.clearIcon} onClick={onHide}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CustomSearchRender.propTypes = propTypes;

export default withStyles(defaultSearchStyles, { name: 'CustomSearchRender' })(
  CustomSearchRender
);

export const IssueTitleMasking = (fullTitle, type) => {
  const wordArray = fullTitle.split('-');
  let res;
  switch (type) {
    case 'showSrc':
      res = wordArray[0] + '-' + wordArray[2];
      break;
    case 'showDst':
      res = wordArray[1] + '-' + wordArray[2];
      break;
    default:
      // change to log
      console.log('wrong type specified for title;');
      return false;
  }
  return res;
};

export const sortCompareDate = (order) => {
  return (obj1, obj2) => {
    const deadline1 = obj1.deadline;
    const deadline2 = obj2.deadline;
    if (deadline1 > deadline2) {
      return order === 'desc' ? 1 : -1;
    } else {
      return order === 'desc' ? -1 : 1;
    }
  };
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatSeconds = (numSeconds) => {
  var hrs = ~~(numSeconds / 3600);
  var mins = ~~((numSeconds % 3600) / 60);
  var secs = ~~numSeconds % 60;

  // Output in format "HH:MM:SS"
  var ret = '';

  if (hrs > 0) {
    // ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    ret += `${hrs}:${mins < 10 ? '0' : ''}`;
  }

  // ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += `${mins}:${secs < 10 ? '0' : ''}`;
  // ret += "" + secs;
  ret += `${secs}`;
  return ret;
};

export const simpleStringify = (object) => {
  var simpleObject = {};
  for (var prop in object) {
    if (!Object.prototype.hasOwnProperty.call(object, prop)) {
      continue;
    }
    if (typeof object[prop] == 'object') {
      continue;
    }
    if (typeof object[prop] == 'function') {
      continue;
    }
    simpleObject[prop] = object[prop];
  }
  return JSON.stringify(simpleObject); // returns cleaned up JSON
};

export const validatePassword = (password) => {
  // Must contain: lower case / upper case / digit / at least 8 characters
  let check = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  return password.match(check);
};

export class Queue {
  constructor() {
    this.items = {};
    this.headIndex = 0;
    this.tailIndex = 0;
  }
  enqueue(item) {
    this.items[this.tailIndex] = item;
    this.tailIndex++;
  }
  dequeue() {
    const item = this.items[this.headIndex];
    delete this.items[this.headIndex];
    this.headIndex++;
    return item;
  }
  peekHead() {
    return this.items[this.headIndex];
  }
  peekTail() {
    return this.items[this.tailIndex - 1];
  }
  get length() {
    return this.tailIndex - this.headIndex;
  }
}

/**
 *  Simply removes any leading slash(s) of a string;
 */
export const removeLeadingSlash = (s) => {
  return s.replace(/^\/+/g, '');
};

/**
 * Calculate remaining time in HH-mm-ss format;
 *
 * @param {number} totalBytes total number of bytes to be uploaded;
 * @param {number} uploadedBytes number of bytes that has already been uploaded;
 * @param {number} bytesPerSecond speed as bytes per second
 * @return {string}
 */
export const calculateRemainingTime = (
  totalBytes,
  uploadedBytes,
  bytesPerSecond
) => {
  let remainingTime = 'N/A';
  if (bytesPerSecond > 0) {
    const remainingSeconds = (totalBytes - uploadedBytes) / bytesPerSecond;
    remainingTime = formatSeconds(remainingSeconds);
  }
  return remainingTime;
};

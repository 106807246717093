import { Fragment } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Chip from './Chip';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';

import localData from 'shared/utils/localData';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
// import FormField from 'pages/parts/FormField';
import api from 'shared/utils/api';
import { axiosForS3PresignedUrl } from 'shared/utils/axiosInstance';
// import { InvalidFileExtensions, InvalidFileTypeNames } from 'shared/constants/invalidFileTypes';
import { Tags } from 'shared/constants/tags';
import { formatBytes } from 'shared/utils/misc';
import { FILE_CHUNK_SIZE, uploadParts } from './S3MultipartUpload';
import { generate8DigitsIdentifier } from 'shared/utils/randomIdGenerator';
import {
  setUploadingFileNameContext,
  setUploadingProgressContext,
  setUploadedBytesContext,
  setUploadControllerContext,
} from 'context/NewIssueContext';
import parse from 'html-react-parser';

import {
  getStructuredFiles,
  flattenStructuredAttachmentsObject,
} from 'shared/utils/fileHandling';

// Icons
// import FileImage from 'assets/images/icons/file.png';
//<a href="[https://www.flaticon.es/iconos-gratis/documento](https://www.flaticon.es/iconos-gratis/documento)" title="documento iconos">Documento iconos creados por Tomas Knop - Flaticon</a>
// import FolderImage from 'assets/images/icons/folder.png';
//<a href="[https://www.flaticon.es/iconos-gratis/carpeta](https://www.flaticon.es/iconos-gratis/carpeta)" title="carpeta iconos">Carpeta iconos creados por Freepik - Flaticon</a>

const consoleLogApiError = (error) => {
  if (error.response) {
    // The server responded with a status code out of the range of 2xx;
    console.log(`error.response: ${JSON.stringify(error.response)}`);
  } else if (error.request) {
    // The request was made but no response was received;
    // `error.request` is an XMLHttpRequest instance in the browser;
    console.log(
      `request made, no response received; error.request: ${JSON.stringify(
        error.request
      )}`
    );
    throw new Error('Network Failure');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Unknown Error', error.message);
  }
};

export const getGenderOptions = () => {
  return [
    { label: intl.get('new_option_patient_gender_female'), value: 'F' },
    { label: intl.get('new_option_patient_gender_male'), value: 'M' },
    { label: intl.get('new_option_patient_gender_others'), value: 'X' },
  ];
};

export const getGenderValue = (genderValue) => {
  if (genderValue === 'F') {
    return intl.get('new_option_patient_gender_female');
  } else if (genderValue === 'M') {
    return intl.get('new_option_patient_gender_male');
  } else if (genderValue === 'X') {
    return intl.get('new_option_patient_gender_others');
  }
};

export const getEmergencyOptions = () => {
  return [
    { label: intl.get('new_option_emergency_no_emergency'), value: 2 },
    // {label: intl.get('new_option_emergency_in_1_hour'), value: 5},
    // {label: intl.get('new_option_emergency_in_3_hours'), value: 4},
    // {label: intl.get('new_option_emergency_in_24_hours'), value: 3}
  ];
};

export const getDstOrgOptions = (dstOrgs) => {
  return dstOrgs.map((dstOrg) => {
    if (localData.get('userPreferredLanguage') === 'en-US') {
      return {
        label: `${dstOrg.name} [${dstOrg.used}/${dstOrg.quota} used]`,
        value: dstOrg.id,
      };
    } else {
      //  if (localData.get('userPreferredLanguage') === 'ja-JP')
      return {
        label: `${dstOrg.name} [残り ${dstOrg.quota - dstOrg.used} 件送信可能(${
          dstOrg.quota
        } 件まで)]`,
        value: dstOrg.id,
      };
    }
  });
};

export const getCVICSuperUserSourceOrgs = (CvicSuperUserOrgs) => {
  return Object.values(CvicSuperUserOrgs).map((org) => ({
    label: `${org.srcOrgName}`,
    id: org.srcOrgId,
  }));
  // return CvicSuperUserOrgs.map((org) => ({label: `${org.name}`, id: org.id}));
};

export const getCVICSuperUserDestinationOrgs = (
  CvicSuperUserOrgs,
  srcOrgId
) => {
  const dstOrgs = Object.values(CvicSuperUserOrgs[srcOrgId].destinations).map(
    (org) => ({ label: `${org.name}`, id: org.id })
  );
  return dstOrgs;
  // const cvicOrgs = CvicSuperUserOrgs.map((org) => ({label: `${org.name}`, id: org.id}));
  // const validDst = cvicOrgs.filter(item => item.id !== parseInt(srcOrgId));
  // return validDst
};

// const getOrgIdToNameMap = (arr) => {
//   return arr.reduce((obj, item) => {
//       obj[item[id]] = item.name
//       return obj
//   }, {})
// }

export const getDstOrgObjectById = (orgId, dstOrgs) => {
  const res = dstOrgs.filter((org) => org.id.toString() === orgId.toString());
  if (res.length > 0) {
    return res[0];
  }
};

const makeTagStringWithSubTags = (checkboxForm, tagKey, subTagKeys) => {
  var res = Tags[tagKey];
  const { option1, option2, option3 } = subTagKeys;
  const subTagNames = [];
  if (option1 && checkboxForm[option1]) subTagNames.push(Tags[option1]);
  if (option2 && checkboxForm[option2]) subTagNames.push(Tags[option2]);
  if (option3 && checkboxForm[option3]) subTagNames.push(Tags[option3]);
  if (subTagNames.length > 0) {
    res += `(${subTagNames.join(', ')})`;
  }
  return res;
};

export const makeTagString = (tagKey, checkboxForm) => {
  var res = '';
  if (tagKey === 'CT3') {
    res = makeTagStringWithSubTags(checkboxForm, tagKey, {
      option1: 'CT4',
      option2: 'CT5',
    });
  } else if (tagKey === 'CT4' || tagKey === 'CT5') {
    return;
  } else if (tagKey === 'CT6') {
    res = makeTagStringWithSubTags(checkboxForm, tagKey, {
      option1: 'CT7',
      option2: 'CT8',
    });
  } else if (tagKey === 'CT7' || tagKey === 'CT8') {
    return;
  } else if (tagKey === 'MRI9') {
    res = makeTagStringWithSubTags(checkboxForm, tagKey, {
      option1: 'MRI10',
      option2: 'MRI11',
    });
  } else if (tagKey === 'MRI10' || tagKey === 'MRI11') {
    return;
  } else if (tagKey === 'MRI1') {
    res = makeTagStringWithSubTags(checkboxForm, tagKey, {
      option1: 'MRI2',
      option2: 'MRI3',
      option3: 'MRI4',
    });
  } else if (tagKey === 'MRI2' || tagKey === 'MRI3' || tagKey === 'MRI4') {
    return;
  } else {
    res = Tags[tagKey];
  }
  return res;
};

export const listTags = (checkboxForm, darkMode) => {
  const tags = {
    CT: [],
    MRI: [],
    生理検査: [],
  };

  for (const [key, value] of Object.entries(checkboxForm)) {
    if (value) {
      const res = makeTagString(key, checkboxForm);
      if (res) {
        if (key.startsWith('CT'))
          tags.CT.push(
            <Chip
              label={res}
              color={darkMode ? 'white' : 'black'}
              variant="outlined"
              style={{ marginLeft: '4px' }}
            />
          );
        if (key.startsWith('MRI'))
          tags.MRI.push(
            <Chip
              label={res}
              color={darkMode ? 'white' : 'black'}
              variant="outlined"
              style={{ marginLeft: '4px' }}
            />
          );
        if (key.startsWith('PhyExam'))
          tags['生理検査'].push(
            <Chip
              label={res}
              color={darkMode ? 'white' : 'black'}
              variant="outlined"
              style={{ marginLeft: '4px' }}
            />
          );
      }
    }
  }
  const createTagType = (tags) => {
    const tagType = [];
    for (const [key, value] of Object.entries(tags)) {
      tagType.push(
        <Grid xs={12} item>
          <MDTypography variant="body2">
            <span style={{ fontStyle: 'italic' }}>
              {'\u2022'} {key}:{' '}
            </span>
            {value}
          </MDTypography>
        </Grid>
      );
    }
    return tagType;
  };

  return (
    <Fragment>
      <Grid xs={12} item>
        <MDTypography style={{ fontWeight: 'bold' }}>
          {intl.get('new_modal_confirm_text_tags')}
        </MDTypography>
      </Grid>
      {createTagType(tags)}
    </Fragment>
  );
};

export const generateConfirmWindowContents = (
  formState,
  structuredAttachments,
  checkboxForm,
  darkMode
) => {
  let fullGenderName;
  switch (formState.patientGender) {
    case 'F':
      fullGenderName = intl.get('new_option_patient_gender_female');
      break;
    case 'M':
      fullGenderName = intl.get('new_option_patient_gender_male');
      break;
    case 'X':
      fullGenderName = intl.get('new_option_patient_gender_others');
      break;
    default:
      break;
  }

  let emergrncyName;
  switch (formState.emergency) {
    case 2:
      emergrncyName = intl.get('new_option_emergency_no_emergency');
      break;
    case 5:
      emergrncyName = intl.get('new_option_emergency_in_1_hour');
      break;
    case 4:
      emergrncyName = intl.get('new_option_emergency_in_3_hours');
      break;
    case 3:
      emergrncyName = intl.get('new_option_emergency_in_24_hours');
      break;
    default:
      break;
  }

  // //! Edit Image size here
  //   const getFilesList = () => structuredAttachments.map((item) => {
  //     return(
  //       // <Paper variant="outlined" elevation={1} style={{margin: "10px", padding: "5px"}}>
  //       // <MDBox py={3} px={3} mt={3} mx={3} sx={{overFlowY: "auto"}}>
  //         <Grid container direction="row" item spacing={2} justify="center" alignItems="center">
  //           <Grid item xs={2} spacing={1} justify="center" alignItems="center">
  //             {/* <img src={item.type === "directory" ? FolderImage : FileImage} alt="file" width="100%"/> */}
  //             <Icon fontSize="large">folder</Icon>,
  //           </Grid>
  //           <Grid item xs={10} spacing={1} justify="center" alignItems="center">
  //             <MDTypography variant="body2" style={{marginTop: "25px"}}>
  //               {/* {intl.get('new_label_file_name')}: {item.name} */}
  //               {intl.get('new_label_file_name')}: {"check123"}
  //             </MDTypography>
  //             <MDTypography variant="body2">{intl.get('new_label_file_size')}: {formatBytes(item.size)} </MDTypography>
  //           </Grid>
  //         </Grid>
  //       // </MDBox>
  //     );
  //   })

  //! Edit Image size here

  const getFilesList = () =>
    structuredAttachments.map((item, index) => {
      return (
        <Grid container item spacing={2} key={index}>
          <Grid item xs={12} spacing={1}>
            <Stack direction="row" spacing={4}>
              <Icon fontSize="large" color="secondary">
                {item.type === 'directory' ? 'folder' : 'attachment'}
              </Icon>
              <Stack direction="column" spacing={2}>
                <MDTypography variant="body2" style={{ marginTop: '25px' }}>
                  {intl.get('new_label_file_name')}: {item.name}
                </MDTypography>
                <MDTypography variant="body2">
                  {intl.get('new_label_file_size')}: {formatBytes(item.size)}{' '}
                </MDTypography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      );
    });

  const formData = {
    new_label_patient_name: formState.patientName,
    new_label_patient_gender: fullGenderName,
    new_label_send_to: formState.dstOrgName,
    new_label_emergency: emergrncyName,
    new_label_department: formState.department,
    new_label_doctor_in_charge: formState.doctorsInCharge.join(', '),
  };

  const createFormData = (formInfo) => {
    const formValues = [];
    for (const [key, value] of Object.entries(formInfo)) {
      formValues.push(
        <Grid xs={6} item>
          <MDTypography>
            <span style={{ fontWeight: 'bold' }}>{intl.get(key)}: </span>
            <span>{value}</span>
          </MDTypography>
        </Grid>
      );
    }
    formValues.push(
      <Grid xs={12} item>
        <MDTypography>
          <span style={{ fontWeight: 'bold' }}>
            {intl.get('new_label_case_description')}:
          </span>
        </MDTypography>
      </Grid>
    );
    formValues.push(
      <Grid xs={12} item>
        <MDTypography variant="body2" mt={-2}>
          {parse(formState.description)}
        </MDTypography>
      </Grid>
    );
    return formValues;
  };

  return (
    <Grid
      spacing={3}
      container
      style={{ marginTop: '15px', overflowY: 'auto', maxHeight: '40vh' }}>
      {createFormData(formData)}
      {listTags(checkboxForm, darkMode)}
      {getFilesList()}
    </Grid>
  );
};

export const generateFileList = (structuredAttachments, handleFileDelete) => {
  const res = structuredAttachments.map((item, index) => {
    if (item.type === 'directory') return <></>;
    return (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={5}>
            <MDTypography variant="body2">
              {item.name} - {formatBytes(item.size)}
            </MDTypography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <MDButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleFileDelete(index)}>
              {intl.get('issue_details_button_delete')}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    );
  });

  return res;
};

export const generateDirectoryList = (
  structuredAttachments,
  handleFileDelete,
  darkMode
) => {
  const res = structuredAttachments.map((item, index) => {
    if (item.type === 'file') return <></>;
    return (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={10}>
            {item.type === 'file' ? (
              <MDTypography variant="body2">
                {item.name} - {formatBytes(item.size)}
              </MDTypography>
            ) : (
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                style={{
                  color: darkMode ? 'white' : 'black',
                  backgroundColor: darkMode ? '#202940' : 'white',
                }}
                sx={{
                  maxheight: 400,
                  flexGrow: 1,
                  maxWidth: 500,
                  overflowY: 'auto',
                }}>
                {generateTreeView(item)}
              </TreeView>
            )}
          </Grid>
          <Grid item xs={2}>
            <MDButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleFileDelete(index)}>
              {intl.get('issue_details_button_delete')}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    );
  });

  return res;
};

const generateTreeView = (structureObject) => {
  const label = `${structureObject.name}  -  ${formatBytes(
    structureObject.size
  )}`;
  return (
    <TreeItem
      nodeId={`${structureObject.name}-${generate8DigitsIdentifier()}`}
      label={label}>
      {structureObject.type === 'directory' &&
        structureObject.children.map((item) => generateTreeView(item))}
    </TreeItem>
  );
};

export const initNewIssue = async (
  formState,
  checkboxForm,
  structuredAttachments
) => {
  // POST to backend server to create a new Issue Entity;
  const currentOrganizationId = localData.get('currentOrganizationId');
  const formData = {};
  formData.description = formState.description;
  formData.emergency = formState.emergency;
  formData.patientGender = formState.patientGender;
  formData.patientName = formState.patientName;
  formData.department = formState.department;
  formData.doctorInCharge = formState.doctorInCharge;
  formData.doctorsInCharge = formState.doctorsInCharge;
  formData.doctorIdsFromSrcOrg = formState.doctorIdsFromSrcOrg;
  formData.srcOrgName = formState.srcOrgName;
  formData.srcOrgId = formState.srcOrgId;
  formData.dstOrgName = formState.dstOrgName;
  formData.dstOrgId = formState.dstOrgId;
  formData.numAttachments = structuredAttachments.length;
  const checkboxFormTags = [];
  for (const [key, value] of Object.entries(checkboxForm)) {
    if (value) {
      checkboxFormTags.push(key);
    }
  }
  formData.checkboxFormTags = checkboxFormTags;

  const apiVariables = {
    params: { organizationId: currentOrganizationId },
    data: formData,
  };

  let issueInfo;
  try {
    issueInfo = await api.post('/api/issues/new', apiVariables);
  } catch (error) {
    console.log(`POST to /api/issues/new Failed`);
    consoleLogApiError(error);
    throw error;
  }

  return issueInfo;
};

export const startIssuePeriodicStatusCheck = async (issueId) => {
  let status;
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get('currentOrganizationId'),
        issueId: issueId,
      },
    };
    const res = await api.get(
      '/api/issues/periodic-status-check',
      apiVariables
    );
    status = res.status;
  } catch (error) {
    console.log(
      `Request to /api/attachment/periodic-status-check failed; Check network condition;`
    );
    consoleLogApiError(error);
  }

  return status;
};

/**
 *  Get dateTime string in given format (Japan)
 */
const getTimeStringInFormat = (issueCreatedAt) => {
  const format = 'YYYY-MM-DD-HH-mm';
  const dateTimeString = moment(issueCreatedAt).tz('Asia/Tokyo').format(format);
  return dateTimeString;
};

const notifyServerAboutAttachment = async ({
  issueId,
  issueIdentifierId,
  issueDateTimeString,
  updatedAttachmentItem,
}) => {
  try {
    const apiVariables = {
      params: { organizationId: localData.get('currentOrganizationId') },
      data: {
        issueId: issueId,
        issueIdentifierId: issueIdentifierId,
        issueDateTimeString: issueDateTimeString,
        attachmentObj: updatedAttachmentItem,
      },
    };

    // console.log(`POSTMAN: localData.get('currentOrganizationId'): ${JSON.stringify(localData.get('currentOrganizationId'))}`);
    // console.log(`POSTMAN: issueId: ${JSON.stringify(issueId)}`);
    // console.log(`POSTMAN: issueIdentifierId: ${JSON.stringify(issueIdentifierId)}`);
    // console.log(`POSTMAN: issueDateTimeString: ${JSON.stringify(issueDateTimeString)}`);
    // console.log(`POSTMAN: updatedAttachmentItem: ${JSON.stringify(updatedAttachmentItem)}`);

    const res = await api.post(
      '/api/attachment/notify-upload-completion',
      apiVariables
    );
    return res;
  } catch (error) {
    console.log(`POST to /api/attachment/notify-upload-completion Failed`);
    consoleLogApiError(error);
    throw error;
  }
};

export const checkForAttachmentsCompletionOnNewIssue = async (issueId) => {
  let status;
  try {
    const apiVariables = {
      params: {
        organizationId: localData.get('currentOrganizationId'),
        issueId: issueId,
      },
    };
    const res = await api.get(
      '/api/attachment/check-for-attachments-completion-on-new-issue',
      apiVariables
    );
    status = res.status;
  } catch (error) {
    console.log(
      `Request to /api/attachment/check-for-attachments-completion-on-new-issue failed; Check network condition;`
    );
    consoleLogApiError(error);
  }

  return status;
};

export const uploadBatchAttachmentsToS3 = async ({
  missionId,
  issueId,
  issueIdentifierId,
  issueCreatedAt,
  structuredAttachments,
  batchTotalBytes,
  dstOrgId,
  controllerUploadMissions,
  dispatchUploadMissions,
}) => {
  const issueDateTimeString = getTimeStringInFormat(issueCreatedAt);

  const attachments = [];
  // For each attachment: upload and notify;
  for (var i = 0; i < structuredAttachments.length; i++) {
    const AttachmentItem = structuredAttachments[i];

    // 1. Upload attachment into S3 bucket; no need to be non-blocking / async;
    const updatedAttachmentItem = await uploadSingleAttachmentToS3({
      missionId,
      issueIdentifierId,
      issueCreatedAt,
      issueDateTimeString,
      AttachmentItem,
      batchTotalBytes,
      dstOrgId,
      controllerUploadMissions,
      dispatchUploadMissions,
    });

    // 2. Notify backend that it is in s3, ready for processing;
    const res = await notifyServerAboutAttachment({
      issueId,
      issueIdentifierId,
      issueDateTimeString,
      updatedAttachmentItem,
    });
    attachments.push(res);
  }

  return attachments;
};

export const uploadSingleAttachmentToS3 = async ({
  missionId,
  issueIdentifierId,
  issueCreatedAt,
  issueDateTimeString,
  AttachmentItem,
  batchTotalBytes,
  dstOrgId,

  controllerUploadMissions,
  dispatchUploadMissions,
}) => {
  // flatten the attachment object into an array of objects, each representing a single file;
  let arrayOfFileObjects = flattenStructuredAttachmentsObject(AttachmentItem);

  for (var i = 0; i < arrayOfFileObjects.length; i++) {
    const item = arrayOfFileObjects[i];
    setUploadingFileNameContext(dispatchUploadMissions, {
      missionId,
      value: item.fileFullPath,
    });
    const axiosUploadController = new AbortController();
    setUploadControllerContext(dispatchUploadMissions, {
      missionId,
      value: axiosUploadController,
    });
    const objectInfo = await uploadSingleFileToS3({
      missionId,
      issueIdentifierId,
      issueCreatedAt,
      issueDateTimeString,
      batchTotalBytes,
      dstOrgId,
      attachmentFileObj: item,
      controllerUploadMissions,
      dispatchUploadMissions,
      axiosUploadController,
    });

    arrayOfFileObjects[i] = { ...item, ...objectInfo }; // add new info to each object
  }

  const arrayOfUpdatedStructuredAttachment =
    getStructuredFiles(arrayOfFileObjects);
  // TODO:  error-handling here: accessing an array
  const updatedStructuredAttachment = arrayOfUpdatedStructuredAttachment[0];
  return updatedStructuredAttachment;
};

/**
 *  Upload the file to S3 bucket through pre-signed URL
 */
export const uploadSingleFileToS3 = async ({
  missionId,
  issueIdentifierId,
  issueCreatedAt,
  issueDateTimeString,
  batchTotalBytes,
  dstOrgId,
  attachmentFileObj, // an object of one single file
  controllerUploadMissions,
  dispatchUploadMissions,
  axiosUploadController,
}) => {
  const { file, fileFullPath } = attachmentFileObj;
  let bucketName;
  let objectKey;
  try {
    // use S3 Multipart Upload if file is larger than threshold;
    const multipart =
      attachmentFileObj.size > FILE_CHUNK_SIZE
        ? Math.ceil(attachmentFileObj.size / FILE_CHUNK_SIZE)
        : false;

    const apiVariables = {
      params: {
        multipart: multipart,
        organizationId: localData.get('currentOrganizationId'),
        srcOrgId: localData.get('currentOrganizationId'),
        dstOrgId: dstOrgId,
        filename: fileFullPath,
        issueIdentifierId,
        issueCreatedAt,
        issueDateTimeString,
      },
    };
    const res = await api.get(
      '/api/issues/url-for-uploading-file',
      apiVariables
    );
    const { presignedS3Url, uploadId } = res;
    bucketName = res.bucketName;
    objectKey = res.objectKey;

    if (multipart) {
      // Multipart Upload
      const parts = await uploadParts({
        file,
        presignedS3Url,
        batchTotalBytes,
        missionId,
        controllerUploadMissions,
        dispatchUploadMissions,
        axiosUploadController,
      });

      const apiVariables = {
        params: { organizationId: localData.get('currentOrganizationId') },
        data: {
          bucketname: bucketName,
          objectname: objectKey,
          uploadId: uploadId,
          parts: parts,
        },
      };

      await api.post('/api/issues/complete-multipart-upload', apiVariables);
    } else {
      const uploadedBytesBase =
        controllerUploadMissions.missions[missionId].uploadedBytes;
      // var prevUploadedBytes = controllerUploadMissions.missions[missionId].uploadedBytes;
      // var prevUploadedBytesTimestamp = Date.now();
      await axiosForS3PresignedUrl({
        method: 'PUT',
        url: presignedS3Url,
        data: file,
        // maxContentLength: Infinity,
        // maxBodyLength: Infinity,
        signal: axiosUploadController.signal,
        onUploadProgress: (data) => {
          const gap = data.total - data.loaded;
          if (gap > 0 && Math.random() > 0.9) return;

          // const timestamp = Date.now();
          const newUploadedBytes = uploadedBytesBase + data.loaded;
          // const bytesPerSecond = 1000 * (newUploadedBytes - prevUploadedBytes) / (timestamp - prevUploadedBytesTimestamp);
          // prevUploadedBytes = newUploadedBytes;
          // prevUploadedBytesTimestamp = timestamp;
          const uploadingProgress = Math.round(
            100 * (newUploadedBytes / batchTotalBytes)
          );

          setUploadedBytesContext(dispatchUploadMissions, {
            missionId,
            value: newUploadedBytes,
          });
          // setUploadingSpeedContext(dispatchUploadMissions, {missionId, value:bytesPerSecond});
          setUploadingProgressContext(dispatchUploadMissions, {
            missionId,
            value: uploadingProgress,
          });
        },
      });
    }
  } catch (error) {
    // if cancelled, error = {message: 'canceled', name: 'CanceledError', code: 'ERR_CANCELED'}
    console.log('ERROR when uploading file to S3 bucket: ', error);
    consoleLogApiError(error);
    throw error;
  }

  return { Bucket: bucketName, Key: objectKey };
};

// DO NOT DELETE
// const handleSingleFileOriginal = async (f) => {
//   let item;
//   // unzip and extract dicom tag information
//   const dataSet = await extractAndParseDicom(f);

//   if (dataSet != null ){  // this is a zip file containing dicom file(s)
//     // update original formState
//     const newFormState = { ...formState };
//     newFormState.zipFileName = f.name;
//     newFormState.file = f;
//     const attributes = {
//       patientName: 'x00100010',
//       patientGender: 'x00100040',
//       patientID: 'x00100020',
//       patientBirthdate: 'x00100030',
//       accessionNumber: 'x00080050',
//       // studyID: 'x00200010',
//       // StudyUID: 'x0020000d',
//       studyID: 'x0020000d',
//       studyDate: "x00080020",
//       studyTime: "x00080030",
//       // studyDescription: 'x00081030',
//       bodyPart: 'x00180015',
//       modality: 'x00080060',
//     };

//     Object.entries(attributes).map(([key, attr]) => {
//       var element = dataSet.elements[attr];
//       var text = "";
//       if (element !== undefined) {
//         var str = dataSet.string(attr);
//         if (str !== undefined) {
//           text = str;
//         }
//       }
//       if (text !== "") newFormState[key] = text;
//     })

//     setFormState({...newFormState});
//     setGenderValue(getGenderValue(newFormState.patientGender));

//     item = {
//       type: 'zippedDicoms',
//       modality: newFormState.modality,
//       bodyPart: newFormState.bodyPart,
//       file: f,
//       filename: f.name,
//       size: f.size,
//     };
//   } else {
//     item = {
//       type: 'other',
//       file: f,
//       filename: f.name,
//       size: f.size,
//     };
//   }

//   return item
// };

import { axiosForS3PresignedUrl } from 'shared/utils/axiosInstance';
import {
  // setUploadingSpeedContext,
  setUploadingProgressContext,
  setUploadedBytesContext,
} from 'context/NewIssueContext';

export const MB = 1024 * 1024;
export const GB = 1024 * MB;
export const FILE_CHUNK_SIZE = 100 * MB;

// Could be useful:
// const myUploadProgress = (myFileId) => (progress) => {
//   let percentage = Math.floor((progress.loaded * 100) / progress.total)
//   console.log(myFileId)
//   console.log(percentage)
// }

export const uploadParts = async ({
  file,
  presignedS3Url,
  batchTotalBytes,
  missionId,
  controllerUploadMissions,
  dispatchUploadMissions,
  axiosUploadController,
}) => {
  const keys = Object.keys(presignedS3Url);
  const resParts = [];
  for (const indexStr of keys) {
    const uploadedBytesBase =
      controllerUploadMissions.missions[missionId].uploadedBytes;
    // let prevUploadedBytes = controllerUploadMissions.missions[issueIdentifierId].uploadedBytes;
    // let prevUploadedBytesTimestamp = Date.now();
    const index = parseInt(indexStr);
    const start = index * FILE_CHUNK_SIZE;
    const end = (index + 1) * FILE_CHUNK_SIZE;
    const blob =
      index < keys.length ? file.slice(start, end) : file.slice(start);
    // const resPart = await axiosForS3PresignedUrl.put(
    //   presignedS3Url[index],
    //   blob,
    //   {
    //     onUploadProgress: data => {
    //       const gap = data.total - data.loaded;
    //       if (gap > 0 && Math.random() > 0.25) return;

    //       const timestamp = Date.now();
    //       const newUploadedBytes = uploadedBytesBase + data.loaded;
    //       const bytesPerSecond = 1000 * (newUploadedBytes - prevUploadedBytes) / (timestamp - prevUploadedBytesTimestamp);
    //       prevUploadedBytes = newUploadedBytes;
    //       prevUploadedBytesTimestamp = timestamp;
    //       const uploadingProgress = Math.round(100 * ( newUploadedBytes / batchTotalBytes));

    //       setUploadedBytesContext(dispatchUploadMissions, {issueIdentifierId, value: newUploadedBytes});
    //       setUploadingSpeedContext(dispatchUploadMissions, {issueIdentifierId, value: bytesPerSecond});
    //       setUploadingProgressContext(dispatchUploadMissions, {issueIdentifierId, value:uploadingProgress});
    //     }
    //   }
    // );

    const resPart = await axiosForS3PresignedUrl({
      method: 'PUT',
      url: presignedS3Url[index],
      data: blob,
      signal: axiosUploadController.signal,
      onUploadProgress: (data) => {
        const gap = data.total - data.loaded;
        if (gap > 0 && Math.random() > 0.25) return;

        // const timestamp = Date.now();
        const newUploadedBytes = uploadedBytesBase + data.loaded;
        // const bytesPerSecond = 1000 * (newUploadedBytes - prevUploadedBytes) / (timestamp - prevUploadedBytesTimestamp);
        // prevUploadedBytes = newUploadedBytes;
        // prevUploadedBytesTimestamp = timestamp;
        const uploadingProgress = Math.round(
          100 * (newUploadedBytes / batchTotalBytes)
        );

        setUploadedBytesContext(dispatchUploadMissions, {
          missionId,
          value: newUploadedBytes,
        });
        // setUploadingSpeedContext(dispatchUploadMissions, {issueIdentifierId, value: bytesPerSecond});
        setUploadingProgressContext(dispatchUploadMissions, {
          missionId,
          value: uploadingProgress,
        });
      },
    });

    resParts.push(resPart);
  }

  return resParts.map((part, index) => ({
    ETag: part.headers.etag,
    PartNumber: index + 1,
  }));
};

import axios from 'axios';
import { getStoredAuthToken } from './authToken';
import { objectToQueryString } from './url';
import localData from 'shared/utils/localData';

const defaults = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: (variables) => ({
    'Content-Type': 'application/json',
    authorization: getStoredAuthToken() ? `${getStoredAuthToken()}` : undefined,
    ...variables.headers,
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message:
      'Something went wrong. Please check your internet connection or contact our supprot.',
    status: 503,
    data: {},
  },
};

const api = (method, url, variables) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${defaults.baseURL}${url}`,
      method,
      headers: defaults.headers(variables),
      // params: method === 'get' ? variables.params : undefined,
      // data: method !== 'get' ? variables.data : undefined,
      params: variables.params,
      data: variables.data,
      paramsSerializer: objectToQueryString,
      onDownloadProgress: variables.onDownloadProgress,
    }).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        if (error.response) {
          if (error.response.data.error.code === 'INVALID_TOKEN') {
            // removeStoredAuthToken();
          } else {
            reject(error.response.data.error);
          }
          // reject(defaults.error);
          reject(error);
        } else {
          // reject(defaults.error);
          reject(error);
        }
      }
    );
  });
};

const optimisticUpdate = async (
  url,
  { updatedFields, curretnFields, setLocalData }
) => {
  try {
    setLocalData(updatedFields);
    await api('put', url, {
      params: {
        organizationId: localData.get('currentOrganizationId'),
      },
      data: updatedFields,
    });
  } catch (error) {
    console.log(`optimisticUpdate error: ${error}`);
    setLocalData(curretnFields);
  }
};

const apiUtils = {
  get: (...args) => api('get', ...args),
  post: (...args) => api('post', ...args),
  put: (...args) => api('put', ...args),
  patch: (...args) => api('patch', ...args),
  delete: (...args) => api('delete', ...args),
  optimisticUpdate,
};

export default apiUtils;

// export const getStoredAuthToken = () => localStorage.getItem('authToken');

// export const storeAuthToken = token => localStorage.setItem('authToken', token);

// export const removeStoredAuthToken = () => localStorage.removeItem('authToken');

// export const getStoredAuthToken = () => {
//   const username = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`);
//   const idToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.idToken`;
//   const accessToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.accessToken`;
//   return localStorage.getItem(idToken);
// }

// export const getStoredAccessToken = () => {
//   const username = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`);
//   const accessToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.accessToken`;
//   return localStorage.getItem(accessToken);
// }

// DO NOT DELETE ABOVE CODE
import { browserStorage } from 'shared/utils/storage';

export const getStoredAuthToken = () => {
  // const username = sessionStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`);
  const username = browserStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`
  );
  const idToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.idToken`;
  // return sessionStorage.getItem(idToken);
  return browserStorage.getItem(idToken);
};

export const getStoredAccessToken = () => {
  // const username = sessionStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`);
  const username = browserStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`
  );
  const accessToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.accessToken`;
  // return sessionStorage.getItem(accessToken);
  return browserStorage.getItem(accessToken);
};

export const setStoredAuthToken = (idTokenValue) => {
  // const username = sessionStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`);
  const username = browserStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`
  );
  const idTokenKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.idToken`;
  // return sessionStorage.setItem(idTokenKey, idTokenValue);
  return browserStorage.setItem(idTokenKey, idTokenValue);
};

export const setStoredAccessToken = (accessTokenValue) => {
  // const username = sessionStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`);
  const username = browserStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.LastAuthUser`
  );
  const accessTokenKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}.${username}.accessToken`;
  // return sessionStorage.setItem(accessTokenKey, accessTokenValue);
  return browserStorage.setItem(accessTokenKey, accessTokenValue);
};

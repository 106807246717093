/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Auth } from 'aws-amplify';
// react-router components
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Modal from '@mui/material/Modal';

import Sidenav from 'pages/Dashboard/Sidenav';
import DashboardLayout from 'pages/parts/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'pages/parts/Navbar';
import ServiceTermsPopUpWindow from 'pages/parts/ServiceTermsPopUpWindow';
import UploadMissionsMenu from 'pages/parts/UploadMissions';
import theme from 'assets/theme';
import themeDark from 'assets/theme-dark';
import { getStaticMenuContents } from './routes';
import PersonalSettings from 'pages/Dashboard/PersonalSettings';
import Home from 'pages/Dashboard/Home';

import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { injectLocale, queryLocalCode } from 'shared/utils/intl';
import { PageLoader, PageError } from 'components';

import { useMaterialUIController } from 'context';
import { UploadMissionContextControllerProvider } from 'context/NewIssueContext';

import PropTypes from 'prop-types';
import useStyles from './styles';
import { ConfirmModal } from 'shared/modals/confirmModal';

// Images
import brandWhite from 'assets/images/logos/iwg/iwg-white-logo-cropped.png';
import brandDark from 'assets/images/logos/iwg/iwg-dark-logo-cropped.png';

const propTypes = {
  currentUser: PropTypes.object,
};

export default function App({ currentUser }) {
  const [controller] = useMaterialUIController();
  const { sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  const classes = useStyles();

  const [showConfirmBeforeLogout, setShowConfirmBeforeLogout] = useState(false);
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const [{ data, error }] = useApi.get('/api/user');
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const userInfo = {
    ...data,
  };

  const organizationNames = [];
  if (userInfo.organizations) {
    for (let index = 0; index < userInfo.organizations.length; index++) {
      organizationNames.push(userInfo.organizations[index].name);
    }
  }

  // store the user info data locally for other usage
  if (
    !localData.get('username') ||
    localData.get('username') !== userInfo.username ||
    !userInfo.organizations ||
    (userInfo.organizations &&
      !organizationNames.includes(localData.get('currentOrganization')))
  ) {
    localData.set('username', userInfo.username);
    localData.set('userId', userInfo.id);
    localData.set('userFirstName', userInfo.firstName);
    localData.set('userLastName', userInfo.lastName);
    localData.set('userAvatarUrl', userInfo.avatarUrl);
    localData.set(
      'currentOrganization',
      userInfo.organizations ? userInfo.organizations[0].name : null
    );
    localData.set(
      'currentOrganizationId',
      userInfo.organizations ? userInfo.organizations[0].id : -1
    );
    localData.set('userType', userInfo.userType);
    localData.set('userPreferredLanguage', userInfo.preferredLanguage);
    localData.set('userPreferredTimezone', userInfo.preferredTimezone);
    localData.set('userEmail', userInfo.email);
  }

  if (localData.get('userPreferredLanguage') !== userInfo.preferredLanguage) {
    localData.set('userPreferredLanguage', userInfo.preferredLanguage);
  }

  const routes = getStaticMenuContents(userInfo);

  injectLocale(queryLocalCode(localData.get('userPreferredLanguage')));

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        // return <Route path={route.route} element={route.component} key={route.key} />;
        // NOTICE: keep the commented code below
        // if (route.key === 'my-task') {
        //   return (
        //     <Route path={route.route} key={route.key} >
        //       {route.component}
        //     </Route>
        //   )
        // }
        return (
          <Route path={route.route} key={route.key}>
            {route.component}
          </Route>
        );
      }
      return null;
    });

  const handleClickLogout = () => {
    setShowConfirmBeforeLogout(true);
  };

  const signOut = async () => {
    if (
      process.env.REACT_APP_AUTH_SERVER === 'aws-cognito' &&
      process.env.REACT_APP_SIGN_IN_PAGE === 'custom'
    ) {
      try {
        await Auth.signOut();
        window.location.assign(
          process.env.REACT_APP_LOGOUT_REDIRECT_URL ||
            'https://demo.docloud.iwg-inc.co.jp/'
        );
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <div
        style={{
          overflowY: 'visible',
          minWidth: '1680px',
          minHeight: '1050px',
          height: '100vh',
        }}>
        <Sidenav
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName="I.W.G Docloud"
          routes={routes}
          userInfo={userInfo}
          handleClickLogout={handleClickLogout}
        />

        <UploadMissionContextControllerProvider>
          <DashboardLayout>
            <DashboardNavbar />
            {data.agreedServiceTerms && (
              <Switch>
                {getRoutes(routes)}
                <Route exact path="/personal-settings">
                  <PersonalSettings
                    userInfo={userInfo}
                    currentUser={currentUser}
                  />
                </Route>
                <Route exact path="/home">
                  <Home userInfo={userInfo} />
                </Route>
                <Redirect from="*" to={'/home'} />
              </Switch>
            )}

            <UploadMissionsMenu />

            {/* Irregular so will be left as it is  */}
            {/* TODO: these following two modals will cause runtime warnings;  */}
            <Modal
              open={!data.agreedServiceTerms}
              className={classes.agreeServiceTermsModal}>
              <ServiceTermsPopUpWindow />
            </Modal>

            <ConfirmModal
              isOpened={showConfirmBeforeLogout}
              title={intl.get('dashboard_logout_modal_text_confirm_title')}
              bodyText={intl.get('dashboard_logout_modal_text_confirm_hint')}
              onConfirm={signOut}
              onCancel={() => setShowConfirmBeforeLogout(false)}
              confirmButtonText={intl.get(
                'dashboard_logout_modal_button_logout'
              )}
              cancelButtonText={intl.get(
                'dashboard_logout_modal_button_cancel'
              )}></ConfirmModal>
          </DashboardLayout>
        </UploadMissionContextControllerProvider>
      </div>
    </ThemeProvider>
  );
}

App.propTypes = propTypes;

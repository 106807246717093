import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';

// react-router components
import { Switch, Route, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Material Dashboard 2 PRO React components

// Self designed sub pages, compoenents and others
import Sidenav from './Sidenav';
import Users from './Users';
import Organizations from './Organizations';
import Employments from './Employments';
import Contracts from './Contracts';

import DashboardLayout from 'pages/parts/LayoutContainers/DashboardLayout';

// self designed functions
import {
  setStoredAuthToken,
  setStoredAccessToken,
} from 'shared/utils/authToken';

// context
import { useMaterialUIController } from 'context';

// themes
import theme from 'assets/theme';
import themeDark from 'assets/theme-dark';

// Images
import brandWhite from 'assets/images/logos/iwg/iwg-white-logo-cropped.png';
import brandDark from 'assets/images/logos/iwg/iwg-dark-logo-cropped.png';

const IWGDashboard = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const PERIOD_MS = 60000 * 25; // 25 mins
  useEffect(() => {
    const interval = setInterval(async () => {
      const currentSession = await Auth.currentSession();
      setStoredAuthToken(currentSession.idToken.jwgToken);
      setStoredAccessToken(currentSession.accessToken.jwtToken);
    }, PERIOD_MS);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <div style={{ overFlowY: 'scroll', minWidth: '1500px' }}>
        <Sidenav
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName="I.W.G DoCloud"
        />
        <DashboardLayout>
          {/* <DashboardNavbar fixedNavbar={false} /> */}
          <Switch>
            <Route path="/admin/users">
              <Users />
            </Route>
            <Route path="/admin/orgs">
              <Organizations />
            </Route>
            {/* <Route path="/admin/issues">
              <Issues />
            </Route> */}
            <Route path="/admin/employments">
              <Employments />
            </Route>
            <Route path="/admin/contracts">
              <Contracts />
            </Route>
            {/* <Route path="/admin/prices">
              <Prices />
            </Route> */}
          </Switch>
        </DashboardLayout>
      </div>
    </ThemeProvider>
  );
};

export default IWGDashboard;

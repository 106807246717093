import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core';
import {
  issueStatusColors,
  issueStatusBackgroundColors,
  mixin,
} from 'shared/utils/styles';

const useStyles = makeStyles((theme) => ({}));

export const Status = styled.div`
  text-transform: uppercase;
  transition: all 0.1s;
  ${(props) =>
    mixin.tagIssueListStatus(
      issueStatusBackgroundColors[props.color],
      issueStatusColors[props.color]
    )}
  ${(props) =>
    props.isValue &&
    css`
      padding: 0 12px;
      height: 32px;
      &:hover {
        transform: scale(1.05);
      }
    `}
`;

export default useStyles;

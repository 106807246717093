import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Message, Actions, StyledButton } from 'components/ConfirmModal/Styles';

const propTypes = {
  project: PropTypes.object,
  issueId: PropTypes.string,
  modalClose: PropTypes.func.isRequired,
  updateLocalProjectIssues: PropTypes.func.isRequired,
  alertModalUpdateData: PropTypes.func.isRequired,
  alertModalInfo: PropTypes.string,
};

const AlertWindow = ({
  project,
  modalClose,
  updateLocalProjectIssues,
  alertModalUpdateData,
  alertModalInfo,
}) => {
  const [isWorking] = useState(false);

  // const handleYesButtonClick = () => {
  //   const { issueId, source, destination, listPosition } = alertModalUpdateData;
  //   updateIssue(issueId, destination, project, listPosition);
  //   modalClose();
  // };

  const generateAlertMessage = (alertModalInfo) => {
    if (alertModalInfo === 'invalidDestination') {
      // return 'invalid drag and drop';
      return intl.get('kanban_alert_popwindow_text_invalid_destination');
    } else if (alertModalInfo === 'moveForwardWithWarning') {
      return intl.get('kanban_alert_popwindow_text_move_with_warning');
    }
  };

  const alertMessage = generateAlertMessage(alertModalInfo);

  return (
    <Fragment>
      <Message>{alertMessage}</Message>
      <Actions>
        <StyledButton
          variant={'primary'}
          isWorking={isWorking}
          onClick={modalClose}>
          {intl.get('kanban_alert_popwindow_button_yes')}
        </StyledButton>
        {/* <StyledButton hollow onClick={modalClose}>
          {intl.get('kanban_alert_popwindow_button_no')}
        </StyledButton> */}
      </Actions>
    </Fragment>
  );
};

AlertWindow.propTypes = propTypes;

export default AlertWindow;

import { makeStyles } from '@material-ui/core';
import { StyleSheet, Font } from '@react-pdf/renderer';

// // register the font frist
Font.register({
  family: 'NotoSansJP',
  fonts: [
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Black.otf',
      fontWeight: 'black',
    },
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Bold.otf',
      fontWeight: 'bold',
    },
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Light.otf',
      fontWeight: 'light',
    },
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Medium.otf',
      fontWeight: 'medium',
    },
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Regular.otf',
      fontWeight: 'normal',
    },
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Thin.otf',
      fontWeight: 'thin',
    },
  ],
});

Font.register({
  family: 'NotoSansRegular',
  src: '/static/font/NotoSans-Regular.ttf',
});

Font.register({
  family: 'NasuRegular',
  src: '/static/font/Nasu-Regular.ttf',
});

Font.register({
  family: 'Nasu',
  fonts: [
    {
      src: '/static/font/Nasu/Nasu-Regular-20200227.ttf',
      fontWeight: 'normal',
    },
    {
      src: '/static/font/Nasu/Nasu-Bold-20200227.ttf',
      fontWeight: 'bold',
    },
  ],
});

export const useStyles = makeStyles((theme) => ({
  pdfViewer: {
    // maxWidth: '100%',
    // maxHeight: '90%',
    // width: '1000px',
    // height: '1000',
  },
}));

export const styles = StyleSheet.create({
  headerIWG: {
    fontSize: 12,
    // marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  firstPage: {
    // fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  otherPage: {
    // fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  invoiceTitleContainer: {
    // flexDirection: 'row',
    marginTop: 10,
    // textAlign: 'center',
  },
  invoiceTitleText: {
    color: '#61dafb',
    fontFamily: 'Nasu',
    fontWeight: 'bold',
    letterSpacing: 4,
    fontSize: 22,
    textAlign: 'center',
    // textTransform: 'uppercase',
  },
  invoiceNoContainer: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'flex-end',
  },
  invoiceNoLabel: {
    font: 12,
    fontFamily: 'Nasu',
    fontWeight: 'normal',
  },
  invoiceNoText: {
    font: 30,
    fontFamily: 'Nasu',
    fontWeight: 'normal',
  },
  invoiceDateContainer: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'flex-end',
  },
  invoiceDateLabel: {
    font: 12,
    fontFamily: 'Nasu',
    fontWeight: 'normal',
  },
  invoiceDateText: {
    font: 14,
    fontFamily: 'Nasu',
    // fontWeight: 'bold',
  },
  invoiceFromAndTo: {
    // flex: 0,
    // height: 200,
    flexDirection: 'row',
    // flexGrow: 1,
    marginTop: 15,
  },
  invoiceFromAndToLeft: {
    width: '60%',
    // flexGrow: 0,
    // flexShrink: 1,
    // flexBasis: 200,
    // height: 200,
    fontSize: 18,
    padding: 5,
    fontFamily: 'Nasu',
  },
  invoiceFromAndToRight: {
    width: '40%',
    padding: 5,
    // flexShrink: 1,
    // flexBasis: 200,
    // flexGrow: 2,
    // height: 200,
    fontSize: 11,
    fontFamily: 'Nasu',
  },
  invoiceTotalAmountAndDue: {
    // flex: 0,
    flexDirection: 'row',
    // flexGrow: 1,
    marginTop: 10,
  },
  invoiceTotalAmountAndDueLeft: {
    width: '60%',
    padding: 5,
    fontFamily: 'Nasu',
  },
  invoiceTotalAmountAndDueRight: {
    width: '40%',
    padding: 5,
    fontFamily: 'Nasu',
  },
  invoiceAmountListTable: {
    // flexDirection: 'row',
    flexWrap: 'warp',
    marginTop: 5,
    borderWidth: 1,
    boderColor: '#bff0fd',
    // fontFamily: 'Nasu',
  },
  invoiceAmountListHeader: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 20,
    fontWeight: 'bold',
    flexGrow: 1,
    fontFamily: 'Nasu',
  },
  invoiceAmountListNo: {
    width: '15%',
  },
  invoiceAmountListItem: {
    width: '40%',
  },
  invoiceAmountListItemUnitPrice: {
    width: '15%',
  },
  invoiceAmountListItemCount: {
    width: '15%',
  },
  invoiceAmountListItemTotalPrice: {
    width: '15%',
  },
  invoiceAmountListRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
    fontWeight: 'bold',
    fontFamily: 'Nasu',
    borderBottomWidth: 1,
    borderBottomColor: '#bff0fd',
  },
  invoiceAmountListTableFooter: {
    flexWrap: 'warp',
    marginTop: 5,
    borderWidth: 1,
  },
  invoiceAmountListTableFooterRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    fontWeight: 'bold',
    fontFamily: 'Nasu',
    // borderBottomWidth: 1,
    // borderBottomColor: '#bff0fd',
  },
  invoiceAmountListTableFooterLabel: {
    width: '70%',
    textAlign: 'right',
    paddingRight: 8,
  },
  invoiceAmountListTableFooterText: {
    width: '30%',
    textAlign: 'right',
    paddingRight: 8,
  },
  invoicePaymentMethod: {
    flexWrap: 'warp',
    marginTop: 5,
    borderWidth: 1,
  },
  invoicePaymentMethodText: {
    fontFamily: 'Nasu',
    paddingLeft: 20,
  },
  invoiceRemarks: {
    heigth: 20,
    flexDirection: 'row',
    marginTop: 5,
    borderWidth: 1,
    borderBottomColor: '#bff0fd',
    alignItems: 'center',
  },
  invoiceRemarksLeft: {
    width: '15%',
    fontSize: 15,
    fontFamily: 'Nasu',
    borderRightColor: '#bff0fd',
    padding: 5,
  },
  invoiceRemarksRight: {
    width: '85%',
    fontSize: 10,
    fontFamily: 'Nasu',
    padding: 5,
  },
  invoiceDetailsTitle: {
    fontFamily: 'Nasu',
    fontSize: 20,
  },
  invoiceDetailsTable: {
    flexWrap: 'warp',
    marginTop: 5,
    borderWidth: 1,
    boderColor: '#bff0fd',
  },
  invoiceDetailsTableHeader: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 20,
    fontWeight: 'bold',
    flexGrow: 1,
    fontFamily: 'Nasu',
  },
  invoiceDetailsTableIndex: {
    width: '10%',
  },
  invoiceDetailsTableTitle: {
    width: '25%',
  },
  invoiceDetailsTableModality: {
    width: '15%',
  },
  invoiceDetailsTableBodypart: {
    width: '15%',
  },
  invoiceDetailsTableEmergency: {
    width: '20%',
  },
  invoiceDetailsTableCurrentPrice: {
    width: '15%',
  },
  invoiceDetailsTableRow: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 20,
    fontWeight: 'bold',
    flexGrow: 1,
    fontFamily: 'Nasu',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

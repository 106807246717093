import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import useApi from 'shared/hooks/api';
import api from 'shared/utils/api';

import { PageLoader, PageError, Modal } from 'components';

// components and parts
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';

import Details from '../Details';
import Delete from '../Delete';
import New from './New';

import CustomSearchRender from 'components/CustomSearchRender/admin.js';

const propTypes = {};

const IWGDashboardContracts = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [contractDetailsModalOpen, setContractDetailsModalOpen] =
    useState(false);
  const [contractIdSelected, setContractIdSelected] = useState(-1);
  const [contractDataSelected, setContractDataSelected] = useState({});
  const [contractNewModalOpen, setContractNewModalOpen] = useState(false);
  const [contractDeleteModalOpen, setContractDeleteModalOpen] = useState(false);

  const [{ data, error }, fetchContracts] = useApi.get(
    '/api/admin/contracts',
    {}
  );
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const getChangableContractData = (contract) => {
    return {
      monthlyInvoiceDate: contract.monthlyInvoiceDate,
      quota: contract.quota,
      used: contract.used,
    };
  };

  const handleOpenContractDetails = (contract) => {
    setContractIdSelected(contract.id);
    setContractDataSelected((prevState) => ({
      ...getChangableContractData(contract),
    }));
    setContractDetailsModalOpen(true);
  };

  const handleUpdateContract = async (contractId, updatedContractJSON) => {
    const updatedContractData = JSON.parse(updatedContractJSON);
    await api.put('/api/admin/contracts', {
      data: {
        ids: [contractId],
        updatedData: [updatedContractData],
      },
    });
    await fetchContracts();
  };

  const handleOpenDeleteContract = (contract) => {
    setContractIdSelected(contract.id);
    setContractDataSelected((prevState) => ({
      ...getChangableContractData(contract),
    }));
    setContractDeleteModalOpen(true);
  };

  const handleDeleteContract = async (contractId) => {
    await api.delete('/api/admin/contracts', {
      data: {
        ids: [contractId],
      },
    });
    await fetchContracts();
  };

  data.sort((contract1, contract2) => {
    if (contract1.id > contract2.id) return 1;
    if (contract1.id < contract2.id) return -1;
    return 0;
  });

  const tableData = data.map((contract) => {
    return [
      contract.id,
      contract.srcOrgName,
      contract.dstOrgName,
      contract.monthlyInvoiceDate,
      contract.quota,
      contract.used,
      contract,
      contract,
    ];
  });

  const columns = [
    {
      name: 'contractId',
      label: 'Id',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'srcOrgName',
      label: 'From',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'dstOrgName',
      label: 'To',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'monthlyInvoiceDate',
      label: 'Monthly Invoice Date',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'quota',
      label: 'Quota',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'used',
      label: 'Used',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'details',
      label: 'Details',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const contract = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenContractDetails(contract);
                }}>
                Details
              </MDButton>
            </div>
          );
        },
      },
    },
    {
      name: 'delete',
      label: 'Delete',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const contract = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenDeleteContract(contract);
                  // handleDeleteUser(userId);
                }}>
                Delete
              </MDButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: true,
    download: true,
    filterType: 'textField',
    responsive: 'stacked',
    rowsPerPageOptions: [20, 50, 100],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={
            <Grid container item xs={3} columnSpacing={3}>
              <Grid item>
                <h2>Contracts</h2>
              </Grid>
              <Grid item>
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setContractNewModalOpen(true);
                  }}>
                  New
                </MDButton>
              </Grid>
            </Grid>
          }
        />
      );
    },
  };

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MUIDataTable
              title={
                <Grid container columnSpacing={3} sx={{ marginTop: 3 }}>
                  <Grid item>
                    <h2>Contracts</h2>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setContractNewModalOpen(true);
                      }}>
                      New
                    </MDButton>
                  </Grid>
                </Grid>
              }
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </MDBox>
      </Card>
      {contractNewModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-contract-new"
          width={1040}
          withcloseIcon={true}
          onClose={() => setContractNewModalOpen(false)}
          renderContent={(modal) => (
            <New close={modal.close} fetchContracts={fetchContracts} />
          )}
        />
      )}
      {contractDetailsModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-contract-details"
          width={1040}
          withCloseIcon={true}
          onClose={() => setContractDetailsModalOpen(false)}
          renderContent={(modal) => (
            <Details
              dataType="Contract"
              dataId={contractIdSelected}
              data={contractDataSelected}
              updateData={handleUpdateContract}
            />
          )}
        />
      )}
      {contractDeleteModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-contract-delete"
          width={1040}
          withCloseIcon={true}
          onClose={() => setContractDeleteModalOpen(false)}
          renderContent={(modal) => (
            <Delete
              dataType="Contract"
              dataId={contractIdSelected}
              data={contractDataSelected}
              deleteData={handleDeleteContract}
              close={setContractDeleteModalOpen}
            />
          )}
        />
      )}
    </Fragment>
  );
};

IWGDashboardContracts.propTypes = propTypes;

export default withRouter(IWGDashboardContracts);

import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { Paper, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Lists from './Lists';
import Header from './Header';
import Filters from './Filters';
import IssueDetails from './IssueDetails';
import IssueCreate from './IssueCreate';
// import IssueDownload from './IssueDownload';
import AlertWindow from './AlertPopupWindow';
import ConfirmPopUpWindow from './ConfirmPopUpWindow';
import { StyledConfirmModal } from 'components/ConfirmModal/Styles';

import useApi from 'shared/hooks/api';
import useMergeState from 'shared/hooks/mergeState';
import { PageLoader, PageError, Modal } from 'components';
import localData from 'shared/utils/localData';
import { useStyles } from './styles';
import { updateArrayItemById } from 'shared/utils/javascript';

// components and parts
import MDBox from 'components/MDBox';

const propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

const defaultFilters = {
  searchTerm: '',
  userIds: [],
  srcOrgNames: [],
  myOnly: false,
  recent: false,
};

const DashboardKanban = ({
  match,
  history,
  // setPageTitle,
}) => {
  const classes = useStyles();
  const userType = localData.get('userType');

  const [issueCreateModalOpen, setIssueCreateModalOpen] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertModalUpdateData, setAlertModalUpdateData] = useState();
  const [alertModalInfo, setAlertModalInfo] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalUpdateData, setConfirmModalUpdateData] = useState();
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    source: '',
    destination: '',
  });

  const [filters, mergeFilters] = useMergeState(defaultFilters);

  // setPageTitle('received_my_task');
  const dateThres = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  dateThres.setHours(0, 0, 0, 0);

  const [{ data, error, setLocalData }, fetchProject] = useApi.get(
    '/api/organizations/project',
    {
      params: {
        organizationId: localData.get('currentOrganizationId'),
        dateThres: dateThres,
      },
    }
  );

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { project } = data;

  const updateLocalProjectIssues = (issueId, updateFields) => {
    setLocalData((currentData) => ({
      project: {
        ...currentData.project,
        issues: updateArrayItemById(
          currentData.project.issues,
          issueId,
          updateFields
        ),
      },
    }));
  };

  const updateLocalProjectWithMoreDoneIssues = (updatedIssues) => {
    setLocalData((currentData) => ({
      project: {
        ...currentData.project,
        issues: updatedIssues,
      },
    }));
  };

  return ['staff', 'manager'].includes(userType) ? (
    <Fragment>
      <MDBox py={3} px={3} mt={2} mx={3}>
        <Header organizationName={project.name} />

        <Grid container>
          <Grid item xs={12}>
            <Filters
              sourceOrganizations={project.quotaSrcOrgs}
              projectUsers={project.users}
              defaultFilters={defaultFilters}
              filters={filters}
              mergeFilters={mergeFilters}
            />
          </Grid>

          <Grid item xs={2} container justify="flex-end">
            {/* <Button icon="plus" iconSize={27} variant="empty" 
              style={{"marginTop": "24px"}}
              onClick={() => {setIssueCreateModalOpen(!issueCreateModalOpen)}}>
              {intl.get('kanban_button_create_local_task')}
            </Button>         */}
          </Grid>
        </Grid>

        <Lists
          project={project}
          fetchProject={fetchProject}
          filters={filters}
          updateLocalProjectIssues={updateLocalProjectIssues}
          setAlertModalOpen={setAlertModalOpen}
          setAlertModalUpdateData={setAlertModalUpdateData}
          setAlertModalInfo={setAlertModalInfo}
          setConfirmModalInfo={setConfirmModalInfo}
          setConfirmModalOpen={setConfirmModalOpen}
          setConfirmModalUpdateData={setConfirmModalUpdateData}
          updateLocalProjectWithMoreDoneIssues={
            updateLocalProjectWithMoreDoneIssues
          }
        />

        <Route
          path={`${match.path}/issues/:issueId`}
          render={(routeProps) => (
            <Modal
              isOpen
              testId="modal:issue-details"
              width={1540}
              withCloseIcon={false}
              onClose={() => history.push(match.url)}
              renderContent={(modal) => (
                <IssueDetails
                  issueId={routeProps.match.params.issueId}
                  trigger={
                    routeProps.location.state
                      ? routeProps.location.state.trigger
                      : 'byClick'
                  }
                  projectUsers={project.users}
                  fetchProject={fetchProject}
                  updateLocalProjectIssues={updateLocalProjectIssues}
                  modalClose={modal.close}
                />
              )}
            />
          )}
        />

        {alertModalOpen && (
          <StyledConfirmModal
            isOpen
            testid="modal:alert"
            width={740}
            withCloseIcon={false}
            onClose={() => {
              setAlertModalOpen(false);
            }}
            renderContent={(modal) => (
              <AlertWindow
                project={project}
                modalClose={modal.close}
                updateLocalProjectIssues={updateLocalProjectIssues}
                alertModalUpdateData={alertModalUpdateData}
                alertModalInfo={alertModalInfo}
              />
            )}
          />
        )}

        {issueCreateModalOpen && (
          // Irregular
          <Modal
            isOpen
            testId="modal:issue-create"
            width={800}
            withCloseIcon={false}
            onClose={() => {
              setIssueCreateModalOpen(!issueCreateModalOpen);
            }}
            renderContent={(modal) => (
              <IssueCreate
                project={project}
                fetchProject={fetchProject}
                onCreate={() => history.push(match.url)}
                modalClose={modal.close}
              />
            )}
          />
        )}

        {confirmModalOpen && (
          <StyledConfirmModal
            isOpen
            testid="modal:confirm"
            width={740}
            withCloseIcon={false}
            onClose={() => {
              setConfirmModalOpen(false);
            }}
            renderContent={(modal) => (
              <ConfirmPopUpWindow
                project={project}
                modalClose={modal.close}
                updateLocalProjectIssues={updateLocalProjectIssues}
                confirmModalUpdateData={confirmModalUpdateData}
                confirmModalInfo={confirmModalInfo}
              />
            )}
          />
        )}
      </MDBox>
    </Fragment>
  ) : (
    <Fragment>
      <Paper className={classes.paper} elevation={10}>
        <Typography variant="h4" gutterBottom>
          You are not allowed to check Kanban!
        </Typography>
        <br />
        <br />
      </Paper>
    </Fragment>
  );
};

DashboardKanban.propTypes = propTypes;

export default withRouter(DashboardKanban);

import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { getTextContentsFromHtmlString } from 'shared/utils/browser';
import { TextEditor, TextEditedContent, Button } from 'components';

import { Title, EmptyLabel, Actions } from './Styles';

const propTypes = {
  issue: PropTypes.object.isRequired,
  updateIssue: PropTypes.func.isRequired,
  isClient: PropTypes.bool,
};

const ProjectBoardIssueDetailsDescription = ({
  issue,
  updateIssue,
  isClient,
}) => {
  const [description, setDescription] = useState(issue.description);
  const [isEditing, setEditing] = useState(false);

  const handleUpdate = () => {
    setEditing(false);
    updateIssue({ description });
  };

  const isDescriptionEmpty =
    getTextContentsFromHtmlString(description).trim().length === 0;

  return (
    <Fragment>
      <Title>{intl.get('issue_details_text_description')}</Title>
      {isClient && isEditing ? (
        <Fragment>
          <TextEditor
            placeholder={intl.get(
              'issue_details_placeholder_describe_the_issue'
            )}
            defaultValue={description}
            onChange={setDescription}
          />
          <Actions>
            <Button variant="primary" onClick={handleUpdate}>
              {intl.get('issue_details_button_save')}
            </Button>
            <Button
              variant="empty"
              onClick={() => {
                setEditing(false);
                setDescription(issue.description);
              }}>
              {intl.get('issue_details_button_cancel')}
            </Button>
          </Actions>
        </Fragment>
      ) : (
        <Fragment>
          {isDescriptionEmpty ? (
            <EmptyLabel onClick={() => setEditing(true)}>
              {intl.get('issue_details_placeholder_add_a_description')}
            </EmptyLabel>
          ) : (
            <TextEditedContent
              content={description}
              onClick={isClient ? () => setEditing(true) : () => {}}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

ProjectBoardIssueDetailsDescription.propTypes = propTypes;

export default ProjectBoardIssueDetailsDescription;

import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core';

const propTypes = {
  srcOrgName: PropTypes.string,
  setTableType: PropTypes.func,
};

const DashboardBillingForReceivedCasesDetailedTableCustomTitle = ({
  srcOrgName,
  setTableType,
}) => {
  return (
    <div>
      {srcOrgName}
      <Button
        variant="contained"
        color="primary"
        style={{ marginLeft: '25px' }}
        onClick={() => {
          setTableType('summary');
        }}>
        {intl.get('billing_for_received_cases_button_go_to_summary_table')}
      </Button>
    </div>
  );
};

DashboardBillingForReceivedCasesDetailedTableCustomTitle.propTypes = propTypes;

export default withRouter(
  DashboardBillingForReceivedCasesDetailedTableCustomTitle
);

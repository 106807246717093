import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceTo: PropTypes.object,
  invoiceFrom: PropTypes.object,
};

const InvoiceFromAndTo = ({ invoiceTo, invoiceFrom }) => {
  const invoiceToOrgName = invoiceTo.orgName;

  const invoiceFromOrgName = invoiceFrom.orgName;
  const invoiceFromZipCode = invoiceFrom.zipCode;
  const invoiceFromAddressLine1 = invoiceFrom.addressLine1;
  const invoiceFromAddressLine2 = invoiceFrom.addressLine2;
  const invoiceFromTel = invoiceFrom.tel;
  const invoiceFromFax = invoiceFrom.fax;
  const invoiceFromWhoInChargeEmail = invoiceFrom.whoInChargeEmail;
  const invoiceFromWhoInChargeName = invoiceFrom.whoInChargeName;

  return (
    <Fragment>
      <View style={styles.invoiceFromAndTo}>
        <View style={styles.invoiceFromAndToLeft}>
          <Text>
            {invoiceToOrgName + intl.get('billing_invoice_text_dear')}
          </Text>
        </View>
        <View style={styles.invoiceFromAndToRight}>
          <Text>{invoiceFromOrgName}</Text>
          <Text>{'〒' + invoiceFromZipCode}</Text>
          <Text>{invoiceFromAddressLine1}</Text>
          <Text>{invoiceFromAddressLine2}</Text>
          <Text>{'TEL：' + invoiceFromTel}</Text>
          <Text>{'FAX：' + invoiceFromFax}</Text>
          <Text>{'E-Mail：' + invoiceFromWhoInChargeEmail}</Text>
          <Text>
            {intl.get('billing_invoice_text_in_charge') +
              invoiceFromWhoInChargeName}
          </Text>
        </View>
      </View>
    </Fragment>
  );
};

InvoiceFromAndTo.propTypes = propTypes;
export default InvoiceFromAndTo;

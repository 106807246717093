import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import MDTypography from 'components/MDTypography';

const propTypes = {
  organizationName: PropTypes.string,
};

const ProjectBoardHeader = ({ organizationName }) => (
  <Fragment>
    {/* <Header> */}
    {/* <BoardName>My Tasks</BoardName> */}
    <MDTypography>
      {organizationName} -- {intl.get('kanban_text_task_management')}
    </MDTypography>
    {/* </Header> */}
  </Fragment>
);

ProjectBoardHeader.propTypes = propTypes;

export default ProjectBoardHeader;

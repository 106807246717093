import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import { Grid, Button, Modal } from '@material-ui/core';
import Card from '@mui/material/Card';
import useStyles from './styles';
import api from 'shared/utils/api';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import FormField from 'pages/parts/FormField';
import pxToRem from 'assets/theme/functions/pxToRem';

const propTypes = {
  organizationInfo: PropTypes.object,
};

const DashboardOrganizationSettingsPreference = ({ organizationInfo }) => {
  const classes = useStyles();

  const [showConfirmBeforeSend, setShowConfirmBeforeSend] = useState(false);
  const [formState, setFormState] = useState({
    name: organizationInfo.name,
    zipCode: organizationInfo.contact.zipCode,
    addressLine1: organizationInfo.contact.addressLine1,
    addressLine2: organizationInfo.contact.addressLine2,
    tel: organizationInfo.contact.tel,
    fax: organizationInfo.contact.fax,
    bank: organizationInfo.paymentMethod.bank,
    branch: organizationInfo.paymentMethod.branch,
    accountType: organizationInfo.paymentMethod.accountType,
    accountNo: organizationInfo.paymentMethod.accountNo,
    accountName: organizationInfo.paymentMethod.accountName,
  });

  const handleChange = (key) => async (event) => {
    const newFormState = { ...formState, [key]: event.target.value };
    setFormState({ ...newFormState });
  };

  const handleUpdateClick = async (event) => {
    setShowConfirmBeforeSend(true);
  };

  const handleConfirmClick = async (event) => {
    setShowConfirmBeforeSend(false);
    await sendToServer();
  };

  const wasChanged = (formerValue, currentValue) => {
    if (formerValue.toString() === currentValue.toString()) {
      return false;
    }
    return true;
  };

  const generateConfirmWindowContents = () => {
    return (
      <Grid
        spacing={3}
        container
        style={{ marginTop: '15px', marginLeft: '8px' }}>
        {wasChanged(organizationInfo.name, formState.name) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get(
                'organization_settings_preference_label_organization_name'
              ) +
                ': ' +
                organizationInfo.name +
                ' ==> ' +
                formState.name}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(organizationInfo.contact.zipCode, formState.zipCode) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get('organization_settings_preference_label_zip_code') +
                ': ' +
                organizationInfo.contact.zipCode +
                ' ==> ' +
                formState.zipCode}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(
          organizationInfo.contact.addressLine1,
          formState.addressLine1
        ) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get('organization_settings_preference_label_addressLine1') +
                ': ' +
                organizationInfo.contact.addressLine1 +
                ' ==> ' +
                formState.addressLine1}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(
          organizationInfo.contact.addressLine2,
          formState.addressLine2
        ) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get('organization_settings_preference_label_addressLine2') +
                ': ' +
                organizationInfo.contact.addressLine2 +
                ' ==> ' +
                formState.addressLine2}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(organizationInfo.contact.tel, formState.tel) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get('organization_settings_preference_label_tel') +
                ': ' +
                organizationInfo.contact.tel +
                ' ==> ' +
                formState.tel}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(organizationInfo.contact.fax, formState.fax) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get('organization_settings_preference_label_fax') +
                ': ' +
                organizationInfo.contact.fax +
                ' ==> ' +
                formState.fax}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(organizationInfo.paymentMethod.bank, formState.bank) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get('organization_settings_preference_label_bank') +
                ': ' +
                organizationInfo.paymentMethod.bank +
                ' ==> ' +
                formState.bank}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(
          organizationInfo.paymentMethod.branch,
          formState.branch
        ) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get('organization_settings_preference_label_branch') +
                ': ' +
                organizationInfo.paymentMethod.branch +
                ' ==> ' +
                formState.branch}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(
          organizationInfo.paymentMethod.accountType,
          formState.accountType
        ) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get('organization_settings_preference_label_account_type') +
                ': ' +
                organizationInfo.paymentMethod.accountType +
                ' ==> ' +
                formState.accountType}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(
          organizationInfo.paymentMethod.accountNo,
          formState.accountNo
        ) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get('organization_settings_preference_label_account_no') +
                ': ' +
                organizationInfo.paymentMethod.accountNo +
                ' ==> ' +
                formState.accountNo}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(
          organizationInfo.paymentMethod.accountName,
          formState.accountName
        ) && (
          <Grid xs={12}>
            <MDTypography>
              {intl.get('organization_settings_preference_label_accountName') +
                ': ' +
                organizationInfo.paymentMethod.accountName +
                ' ==> ' +
                formState.accountName}
            </MDTypography>
          </Grid>
        )}
      </Grid>
    );
  };

  const sendToServer = async () => {
    const variables = {
      params: {
        organizationId: organizationInfo.id,
      },
      data: { ...formState },
    };

    api.post('/api/organizations/organization_info', variables).then(
      (data) => {
        window.location.reload();
      },
      (error) => {
        console.log('ERROR in ORGANIZATION INFO');
      }
    );
  };

  return (
    <MDBox>
      <Grid className={classes.block} spacing={3} container xs={12}>
        <Grid item xs={12}>
          <Grid container xs={12}>
            <MDBox mb={3}>
              <MDTypography variant="h5">
                {intl.get('organization_settings_preference_text_general')}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            justify="center"
            xs={12}>
            <Grid item xs={12}>
              <FormField
                // disabled
                id="organizationCode"
                name="organizationCode"
                label={intl.get(
                  'organization_settings_preference_label_organization_code'
                )}
                variant="outlined"
                // variant="standard"
                value={organizationInfo.organizationCode}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                // disabled
                id="organizationName"
                name="organizationName"
                label={intl.get(
                  'organization_settings_preference_label_organization_name'
                )}
                variant="outlined"
                // variant="standard"
                value={formState.name}
                inputProps={{ maxLength: 17 }}
                // onChange={handleChange('name')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.block} spacing={3} container xs={12}>
        <Grid item xs={12}>
          <Grid container xs={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5" gutterBottom>
                {intl.get('organization_settings_preference_text_contact')}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            justify="center"
            xs={12}>
            <Grid item xs={6}>
              <FormField
                id="zipCode"
                name="zipCode"
                label={intl.get(
                  'organization_settings_preference_label_zip_code'
                )}
                fullWidth
                autoComplete="no name"
                variant="outlined"
                color="secondary"
                value={formState.zipCode}
                onChange={handleChange('zipCode')}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <FormField
                id="addressLine1"
                name="addressLine1"
                label={intl.get(
                  'organization_settings_preference_label_address_line_1'
                )}
                fullWidth
                autoComplete="no name"
                variant="outlined"
                color="secondary"
                value={formState.addressLine1}
                onChange={handleChange('addressLine1')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                id="addressLine2"
                name="addressLine2"
                label={intl.get(
                  'organization_settings_preference_label_address_line_2'
                )}
                fullWidth
                autoComplete="no name"
                variant="outlined"
                color="secondary"
                value={formState.addressLine2}
                onChange={handleChange('addressLine2')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                id="tel"
                name="tel"
                label={intl.get('organization_settings_preference_label_tel')}
                fullWidth
                autoComplete="no name"
                variant="outlined"
                color="secondary"
                value={formState.tel}
                onChange={handleChange('tel')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                id="fax"
                name="fax"
                label={intl.get('organization_settings_preference_label_fax')}
                fullWidth
                autoComplete="no name"
                variant="outlined"
                color="secondary"
                value={formState.fax}
                onChange={handleChange('fax')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <Divider /> */}

      <Grid className={classes.block} spacing={3} container xs={12}>
        <Grid item xs={12}>
          <Grid container xs={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5" gutterBottom>
                {intl.get(
                  'organization_settings_preference_text_receiving_account'
                )}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            justify="center"
            xs={12}>
            <Grid item xs={6}>
              <FormField
                id="bank"
                name="bank"
                label={intl.get('organization_settings_preference_label_bank')}
                fullWidth
                autoComplete="no name"
                variant="outlined"
                color="secondary"
                value={formState.bank}
                onChange={handleChange('bank')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                id="branch"
                name="branch"
                label={intl.get(
                  'organization_settings_preference_label_branch'
                )}
                fullWidth
                autoComplete="no name"
                variant="outlined"
                color="secondary"
                value={formState.branch}
                onChange={handleChange('branch')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                id="accountType"
                name="accountType"
                label={intl.get(
                  'organization_settings_preference_label_account_type'
                )}
                fullWidth
                autoComplete="no name"
                variant="outlined"
                color="secondary"
                value={formState.accountType}
                onChange={handleChange('accountType')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                id="accountNo"
                name="accountNo"
                label={intl.get(
                  'organization_settings_preference_label_account_no'
                )}
                fullWidth
                autoComplete="no name"
                variant="outlined"
                color="secondary"
                value={formState.accountNo}
                onChange={handleChange('accountNo')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                id="accountName"
                name="accountName"
                label={intl.get(
                  'organization_settings_preference_label_account_name'
                )}
                fullWidth
                autoComplete="no name"
                variant="outlined"
                color="secondary"
                value={formState.accountName}
                onChange={handleChange('accountName')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className={classes.block}
        spacing={3}
        container
        alignItems="flex-start"
        justify="center"
        xs={12}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateClick}>
            {intl.get('organization_settings_preference_button_update')}
          </Button>
        </Grid>
      </Grid>

      <Modal
        open={showConfirmBeforeSend}
        onClose={() => setShowConfirmBeforeSend(false)}
        className={classes.modalModal}>
        {/* <Card sx={{margin: 4, marginTop: 3, width: 800}}> */}
        <Card sx={{ width: 800 }}>
          <MDBox pl={5} m={1}>
            <Grid
              spacing={3}
              container
              justify="center"
              alignItems="flex-end"
              style={{ marginTop: '20px' }}>
              <Grid xs={12} item>
                <MDTypography mb={1}>
                  {intl.get(
                    'organization_settings_preference_modal_text_confirm_title'
                  )}
                </MDTypography>
                <MDTypography>
                  {intl.get(
                    'organization_settings_preference_modal_text_confirm_hint'
                  )}
                </MDTypography>

                {showConfirmBeforeSend && generateConfirmWindowContents()}
              </Grid>
              <Grid xs={6} item>
                <MDButton
                  variant="gradient"
                  color="info"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    marginBottom: '15px',
                    width: '50%',
                    marginLeft: '25%',
                  }}
                  onClick={handleConfirmClick}>
                  {intl.get(
                    'organization_settings_preference_modal_button_submit'
                  )}
                </MDButton>
              </Grid>

              <Grid xs={6} item>
                <MDButton
                  variant="gradient"
                  color="info"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    marginBottom: '15px',
                    width: '50%',
                    marginLeft: '25%',
                  }}
                  onClick={() => {
                    setShowConfirmBeforeSend(false);
                  }}>
                  {intl.get(
                    'organization_settings_preference_modal_button_cancel'
                  )}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </Modal>
    </MDBox>
  );
};

DashboardOrganizationSettingsPreference.propTypes = propTypes;

export default withRouter(DashboardOrganizationSettingsPreference);

import intl from 'react-intl-universal';
import { MultiButtonModal } from './MultiButtonModal/multiButtonModal';
import PropTypes from 'prop-types';

const propTypes = {
  isOpened: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  cancelButtonColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const ConfirmModal = ({
  isOpened,
  onCancel,
  onConfirm,
  title,
  bodyText,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor,
  cancelButtonColor,
  children,
}) => {
  const buttons = [];
  buttons.push({
    label: confirmButtonText ?? intl.get('') ?? '',
    color: confirmButtonColor ?? 'info',
    onClick: onConfirm,
  });
  if (cancelButtonText) {
    buttons.push({
      label: cancelButtonText ?? intl.get('') ?? '',
      color: cancelButtonColor ?? 'error',
      close: true,
    });
  }

  return (
    <MultiButtonModal
      title={title}
      bodyText={bodyText}
      isOpened={isOpened}
      onClose={onCancel ? onCancel : onConfirm}
      actionButtons={buttons}
      style={{ minWidth: '50vw' }}>
      {children}
    </MultiButtonModal>
  );
};

ConfirmModal.propTypes = propTypes;

import React, { createContext, useContext, useMemo, useReducer } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';
import { Queue } from 'shared/utils/misc';

const initQueue = (qLength) => {
  const q = new Queue();
  for (let index = 0; index < qLength; index++) {
    const timestamp = Date.now();
    q.enqueue({ timestamp: timestamp, value: 0 });
  }
  return q;
};

const UploadMissionsContext = createContext();

// display name on react dev tools
UploadMissionsContext.displayName = 'UploadMissionsContext';

const getNewStateWithUpdatedValue = (state, action, keyName) => {
  const { missionId, value } = action.value;
  const currentMissions = state.missions;
  const thisMission = currentMissions[missionId];
  thisMission[keyName] = value;

  if (keyName === 'status' && value === 'PROCESS_COMPLETE') {
    return {
      ...state,
      newCompletedIssue: thisMission.issueIdentifierId,
      missions: { ...currentMissions, [missionId]: thisMission },
    };
  }

  return {
    ...state,
    missions: { ...currentMissions, [missionId]: thisMission },
  };
};

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_NEW_ISSUE': {
      const {
        issueId,
        issueIdentifierId,
        issueCreatedAt,
        formState,
        checkboxForm,
        structuredAttachments,
        batchTotalBytes,
      } = action.value;

      const missionId = issueIdentifierId;

      const newMission = {
        type: 'ISSUE',
        status: 'INIT',
        issueId: issueId,
        issueIdentifierId: issueIdentifierId,
        issueCreatedAt: issueCreatedAt,
        formState: formState,
        checkboxForm: checkboxForm,
        structuredAttachments: structuredAttachments,
        uploadingFileName: '',
        uploadingProgress: 0,
        uploadedBytes: 0,
        timestampAtUploadedBytes: Date.now(),
        uploadingSpeed: 0,
        uploadController: null,
        batchTotalBytes: batchTotalBytes,
        // 'timestamp': Math.round(Date.now() / 1000),
        uploadedBytesQueue: initQueue(5),
      };
      return {
        ...state,
        newIssueIdentifierId: issueIdentifierId,
        missions: { ...state.missions, [missionId]: newMission },
      };
    }
    case 'ADD_NEW_ATTACHMENT_ON_EXISTING_ISSUE': {
      const {
        issueId,
        issueIdentifierId,
        issueCreatedAt,
        structuredAttachments,
        batchTotalBytes,
        missionId,
      } = action.value;
      const newMission = {
        type: 'NEW_ATTACHMENTS_ON_EXISTING_ISSUE',
        missionId: missionId,
        status: 'INIT',
        issueId: issueId,
        issueIdentifierId: issueIdentifierId,
        issueCreatedAt: issueCreatedAt,
        structuredAttachments: structuredAttachments,
        uploadingFileName: '',
        uploadingProgress: 0,
        uploadedBytes: 0,
        timestampAtUploadedBytes: Date.now(),
        uploadingSpeed: 0,
        uploadController: null,
        batchTotalBytes: batchTotalBytes,
        uploadedBytesQueue: initQueue(5),
      };
      return {
        ...state,
        newAttachmentsMissionId: missionId,
        missions: { ...state.missions, [missionId]: newMission },
      };
    }
    case 'SET_UPLOADING_FILE_NAME': {
      const newState = getNewStateWithUpdatedValue(
        state,
        action,
        'uploadingFileName'
      );
      return newState;
    }
    case 'SET_UPLOADING_SPEED': {
      const newState = getNewStateWithUpdatedValue(
        state,
        action,
        'uploadingSpeed'
      );
      return newState;
    }
    case 'SET_UPLOADING_PROGRESS': {
      const newState = getNewStateWithUpdatedValue(
        state,
        action,
        'uploadingProgress'
      );
      return newState;
    }
    case 'SET_UPLOADED_BYTES': {
      const newState = getNewStateWithUpdatedValue(
        state,
        action,
        'uploadedBytes'
      );
      return newState;
    }
    case 'SET_MISSION_STATUS': {
      const newState = getNewStateWithUpdatedValue(state, action, 'status');
      return newState;
    }
    case 'SET_UPLOAD_CONTROLLER': {
      const newState = getNewStateWithUpdatedValue(
        state,
        action,
        'uploadController'
      );
      return newState;
    }
    case 'UPDATE_UPLOAD_SPEED': {
      // calculate upload speed
      const { missionId } = action.value;
      const currentMissions = state.missions;
      const thisMission = currentMissions[missionId];
      const tsNow = Date.now();
      const item0 = thisMission['uploadedBytesQueue'].dequeue();
      thisMission['uploadedBytesQueue'].enqueue({
        timestamp: tsNow,
        value: thisMission['uploadedBytes'],
      });

      const bytesPerSecond =
        (1000 * (thisMission['uploadedBytes'] - item0.value)) /
        (tsNow - item0.timestamp);
      thisMission['uploadingSpeed'] = bytesPerSecond;

      return {
        ...state,
        missions: { ...currentMissions, [missionId]: thisMission },
      };
    }
    case 'ABORT_UPLOAD': {
      const { missionId } = action.value;
      const currentMissions = state.missions;
      const thisMission = currentMissions[missionId];
      if (thisMission.uploadController) {
        thisMission.uploadController.abort();
      }
      return state;
    }
    case 'REMOVE_MISSION': {
      const { missionId } = action.value;
      const currentMissions = { ...state.missions };
      delete currentMissions[missionId];
      return { ...state, missions: currentMissions };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// context provider
function UploadMissionContextControllerProvider({ children }) {
  const initialState = {
    newIssueIdentifierId: '',
    newAttachmentsMissionId: '',
    newCompletedIssue: null,
    missions: {},
  };

  const [controllerUploadMissions, dispatchUploadMissions] = useReducer(
    reducer,
    initialState
  );

  const value = useMemo(
    () => [controllerUploadMissions, dispatchUploadMissions],
    [controllerUploadMissions, dispatchUploadMissions]
  );

  return (
    <UploadMissionsContext.Provider value={value}>
      {children}
    </UploadMissionsContext.Provider>
  );
}

// custom hook for using context
function useUploadMissionsContextController() {
  const context = useContext(UploadMissionsContext);

  if (!context) {
    throw new Error(
      'useUploadMissionsContextController should be used inside the UploadMissionContextControllerProvider.'
    );
  }

  return context;
}

// Typechecking props for the UploadMissionContextControllerProvider
UploadMissionContextControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context functions
const addNewMission = (dispatch, value) =>
  dispatch({ type: 'ADD_NEW_ISSUE', value });
const addNewAttachmentOnExistingIssue = (dispatch, value) =>
  dispatch({ type: 'ADD_NEW_ATTACHMENT_ON_EXISTING_ISSUE', value });
const setUploadingProgressContext = (dispatch, value) =>
  dispatch({ type: 'SET_UPLOADING_PROGRESS', value });
const setUploadingSpeedContext = (dispatch, value) =>
  dispatch({ type: 'SET_UPLOADING_SPEED', value });
const setUploadingFileNameContext = (dispatch, value) =>
  dispatch({ type: 'SET_UPLOADING_FILE_NAME', value });
const setUploadedBytesContext = (dispatch, value) =>
  dispatch({ type: 'SET_UPLOADED_BYTES', value });
const setMissionStatusContext = (dispatch, value) =>
  dispatch({ type: 'SET_MISSION_STATUS', value });
const setUploadControllerContext = (dispatch, value) =>
  dispatch({ type: 'SET_UPLOAD_CONTROLLER', value });
const contextUpdateUploadingSpeed = (dispatch, value) =>
  dispatch({ type: 'UPDATE_UPLOAD_SPEED', value });
const abortUpload = (dispatch, value) =>
  dispatch({ type: 'ABORT_UPLOAD', value });
const removeMissionFromContext = (dispatch, value) =>
  dispatch({ type: 'REMOVE_MISSION', value });

export {
  UploadMissionContextControllerProvider,
  useUploadMissionsContextController,
  addNewMission,
  addNewAttachmentOnExistingIssue,
  setUploadingFileNameContext,
  setUploadingSpeedContext,
  setUploadingProgressContext,
  setUploadedBytesContext,
  setMissionStatusContext,
  setUploadControllerContext,
  contextUpdateUploadingSpeed,
  abortUpload,
  removeMissionFromContext,
};

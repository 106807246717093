import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import FormField from 'pages/parts/FormField';
// import pxToRem from 'assets/theme/functions/pxToRem';

import api from 'shared/utils/api';

import useStyles from './styles';
import { ConfirmModal } from 'shared/modals/confirmModal';

const propTypes = {
  organizationInfo: PropTypes.object,
  fetchOrganizationInfo: PropTypes.func,
};

const DashboardOrganizationSettingsUserManagement = ({
  organizationInfo,
  fetchOrganizationInfo,
}) => {
  const classes = useStyles();

  const [showConfirmBeforeSend, setShowConfirmBeforeSend] = useState(false);
  const [formState, setFormState] = useState({
    staffUsers: organizationInfo.users.filter(
      (user) => user.userType === 'staff'
    ),
    doctorUsers: organizationInfo.users.filter(
      (user) => user.userType === 'doctor'
    ),
  });
  const [newStaffUser, setNewStaffUser] = useState('');
  const [newDoctorUser, setNewDoctorUser] = useState('');
  const [operationData, setOperationData] = useState({
    operationType: '',
    userType: '',
    username: '',
  });

  useEffect(() => {
    setFormState({
      staffUsers: organizationInfo.users.filter(
        (user) => user.userType === 'staff'
      ),
      doctorUsers: organizationInfo.users.filter(
        (user) => user.userType === 'doctor'
      ),
    });
  }, [organizationInfo]);

  const handleRemoveClick = (user) => async () => {
    setOperationData({
      operationType: 'remove',
      userType: user.userType,
      username: user.username,
    });

    setShowConfirmBeforeSend(true);
  };

  const handleAddStaffClick = () => {
    setOperationData({
      operationType: 'add',
      userType: 'staff',
      username: newStaffUser,
    });
    setShowConfirmBeforeSend(true);
  };

  const handleAddDoctorClick = () => {
    setOperationData({
      operationType: 'add',
      userType: 'doctor',
      username: newDoctorUser,
    });
    setShowConfirmBeforeSend(true);
  };

  const handleNewStaffUserChange = (event) => {
    setNewStaffUser(event.target.value);
  };

  const handleNewDoctorUserChange = (event) => {
    setNewDoctorUser(event.target.value);
  };

  const generateConfirmWindowContents = () => {
    let contents = '';
    if (operationData.operationType === 'remove') {
      if (operationData.userType === 'staff') {
        contents =
          intl.get(
            'organization_settings_user_management_modal_text_confirm_remove_staff'
          ) +
          ' ' +
          operationData.username;
      } else if (operationData.userType === 'doctor') {
        contents =
          intl.get(
            'organization_settings_user_management_modal_text_confirm_remove_doctor'
          ) +
          ' ' +
          operationData.username;
      }
    } else if (operationData.operationType === 'add') {
      if (operationData.userType === 'staff') {
        contents =
          intl.get(
            'organization_settings_user_management_modal_text_confirm_add_staff'
          ) +
          ' ' +
          operationData.username;
      } else if (operationData.userType === 'doctor') {
        contents =
          intl.get(
            'organization_settings_user_management_modal_text_confirm_add_doctor'
          ) +
          ' ' +
          operationData.username;
      }
    }
    return (
      <Grid
        spacing={3}
        container
        style={{ marginTop: '15px', marginLeft: '8px' }}>
        <Grid xs={12}>
          <MDTypography>{contents}</MDTypography>
        </Grid>
      </Grid>
    );
  };

  const handleConfirmClick = async () => {
    setShowConfirmBeforeSend(true);
    await sendToServer();
    setShowConfirmBeforeSend(false);
  };

  const sendToServer = async () => {
    const variables = {
      params: {
        organizationId: organizationInfo.id,
      },
      data: {
        ...operationData,
      },
    };

    api.put('/api/organizations/user_management', variables).then(
      (data) => {
        fetchOrganizationInfo();
      },
      (error) => {
        alert('Failed');
      }
    );
  };

  return (
    <MDBox>
      <Grid className={classes.block} spacing={3} container xs={12}>
        <Grid item xs={12}>
          <Grid container xs={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">
                {intl.get('organization_settings_user_management_text_staffs')}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            justify="center"
            xs={12}>
            {formState.staffUsers.map((staff, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Grid
                    container
                    spacing={3}
                    alignItems="flex-start"
                    justify="center"
                    xs={12}>
                    <Grid item xs={3}>
                      <FormField
                        variant="outlined"
                        label={intl.get(
                          'organization_settings_user_management_label_username'
                        )}
                        value={staff.username}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormField
                        variant="outlined"
                        label={intl.get(
                          'organization_settings_user_management_label_first_name'
                        )}
                        value={staff.firstName}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormField
                        variant="outlined"
                        label={intl.get(
                          'organization_settings_user_management_label_last_name'
                        )}
                        value={staff.lastName}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDButton
                        variant="contained"
                        color="info"
                        fullWidth
                        onClick={handleRemoveClick(staff)}>
                        {intl.get(
                          'organization_settings_user_management_button_remove'
                        )}
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                alignItems="flex-start"
                justify="center"
                xs={12}>
                <Grid item xs={3}>
                  <FormField
                    variant="outlined"
                    label={intl.get(
                      'organization_settings_user_management_label_username'
                    )}
                    value={newStaffUser.username}
                    onChange={handleNewStaffUserChange}
                  />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <MDButton
                    variant="contained"
                    color="info"
                    fullWidth
                    onClick={handleAddStaffClick}>
                    {intl.get(
                      'organization_settings_user_management_button_add'
                    )}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.block} spacing={3} container xs={12}>
        <Grid item xs={12}>
          <Grid container xs={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">
                {intl.get('organization_settings_user_management_text_doctors')}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            justify="center"
            xs={12}>
            {formState.doctorUsers.map((doctor, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Grid
                    container
                    spacing={3}
                    alignItems="flex-start"
                    justify="center"
                    xs={12}>
                    <Grid item xs={3}>
                      <FormField
                        variant="outlined"
                        label={intl.get(
                          'organization_settings_user_management_label_username'
                        )}
                        value={doctor.username}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormField
                        variant="outlined"
                        label={intl.get(
                          'organization_settings_user_management_label_first_name'
                        )}
                        value={doctor.firstName}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormField
                        variant="outlined"
                        label={intl.get(
                          'organization_settings_user_management_label_last_name'
                        )}
                        value={doctor.lastName}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDButton
                        variant="contained"
                        color="info"
                        fullWidth
                        onClick={handleRemoveClick(doctor)}>
                        {intl.get(
                          'organization_settings_user_management_button_remove'
                        )}
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                alignItems="flex-start"
                justify="center"
                xs={12}>
                <Grid item xs={3}>
                  <FormField
                    variant="outlined"
                    label={intl.get(
                      'organization_settings_user_management_label_username'
                    )}
                    value={newDoctorUser.username}
                    onChange={handleNewDoctorUserChange}
                  />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <MDButton
                    variant="contained"
                    color="info"
                    fullWidth
                    onClick={handleAddDoctorClick}>
                    {intl.get(
                      'organization_settings_user_management_button_add'
                    )}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmModal
        isOpened={showConfirmBeforeSend}
        onCancel={() => setShowConfirmBeforeSend(false)}
        onConfirm={handleConfirmClick}
        title={intl.get(
          'organization_settings_user_management_modal_text_confirm_title'
        )}
        bodyText={intl.get(
          'organization_settings_user_management_modal_text_confirm_hint'
        )}
        confirmButtonText={intl.get(
          'organization_settings_user_management_modal_button_submit'
        )}
        cancelButtonText={intl.get(
          'organization_settings_user_management_modal_button_cancel'
        )}>
        <MDBox>
          <Grid
            spacing={3}
            container
            justify="center"
            alignItems="flex-end"
            style={{ marginTop: '20px' }}>
            <Grid xs={12} item>
              {showConfirmBeforeSend && generateConfirmWindowContents()}
            </Grid>
          </Grid>
        </MDBox>
      </ConfirmModal>
    </MDBox>
  );
};

DashboardOrganizationSettingsUserManagement.propTypes = propTypes;

export default withRouter(DashboardOrganizationSettingsUserManagement);

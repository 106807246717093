import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Grid, Modal, TextField } from '@material-ui/core';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { TextEditedContent } from 'components';
import PropTypes from 'prop-types';
import api from 'shared/utils/api';
import pxToRem from 'assets/theme/functions/pxToRem';

import useStyles from './styles';

const propTypes = {
  close: PropTypes.func,
  fetchContracts: PropTypes.func,
};

const IWGDashboardContractsNew = ({ close, fetchContracts }) => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    srcOrganizationCode: '',
    srcOrganizationId: -1,
    dstOrganizationCode: '',
    dstOrganizationId: -1,
    quota: 500,
    monthlyInvoiceDate: 25,
  });
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [incompleteWarningModalOpen, setIncompleteWarningModalOpen] =
    useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [options, setOptions] = useState({
    srcOrganizationOptions: [],
    dstOrganizationOptions: [],
  });

  useEffect(() => {
    const getOptions = async () => {
      const organizations = await api.get('/api/admin/orgs', {});
      const organizationOptions = organizations.map((org) => {
        return {
          label: org.organizationCode + ' (' + org.name + ')',
          value: {
            organizationId: org.id,
            organizationCode: org.organizationCode,
          },
        };
      });
      setOptions({
        srcOrganizationOptions: organizationOptions,
        dstOrganizationOptions: organizationOptions,
      });
    };
    getOptions();
  }, []);

  const keyToCheck = ['srcOrganizationCode', 'dstOrganizationCode'];

  const keyToLabel = {
    srcOrganizationCode: 'Source Organization Code',
    dstOrganizationCode: 'Destination Organization Code',
  };

  const handleChange2 = (key, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const sendToServer = async () => {
    const data = { ...formState };
    await api.post('/api/admin/contracts', {
      data: {
        createdData: [data],
      },
    });
    await fetchContracts();
    close();
  };

  const checkIfFormCompleted = () => {
    const _incompleteFields = [];

    keyToCheck.forEach((key) => {
      const value = formState[key];
      if (value.length === 0) {
        _incompleteFields.push(keyToLabel[key]);
      }
    });

    if (_incompleteFields.length > 0) {
      setIncompleteFields(_incompleteFields);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();
    if (!checkIfFormCompleted()) {
      setIncompleteWarningModalOpen(true);
    } else {
      setConfirmModalOpen(true);
    }
  };

  return (
    <Fragment>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <Grid spacing={3} container alighItems="flex-start" justify="center">
          <Grid xs={12} item>
            <Autocomplete
              disablePortal
              id="organizationCode"
              onChange={(event, newValue) => {
                if (newValue && newValue.value) {
                  handleChange2(
                    'srcOrganizationCode',
                    newValue.value.organizationCode
                  );
                  handleChange2(
                    'srcOrganizationId',
                    newValue.value.organizationId
                  );
                }
              }}
              options={options.srcOrganizationOptions}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    name="srcOrganizationCode"
                    fullWidth
                    variant="outlined"
                    label="Source Organization Code"
                    color="secondary"
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <Autocomplete
              disablePortal
              id="organizationCode"
              onChange={(event, newValue) => {
                if (newValue && newValue.value) {
                  handleChange2(
                    'dstOrganizationCode',
                    newValue.value.organizationCode
                  );
                  handleChange2(
                    'dstOrganizationId',
                    newValue.value.organizationId
                  );
                }
              }}
              options={options.dstOrganizationOptions}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    name="dstOrganizationCode"
                    fullWidth
                    variant="outlined"
                    label="Destination Organization Code"
                    color="secondary"
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid spacing={3} container alignItems="flex-start" justify="center">
          <Grid xs={6} item>
            <MDButton
              variant="gradient"
              color="info"
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '5px',
                marginBottom: '45px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={handleSubmitClick}>
              Submit
            </MDButton>
          </Grid>
          <Grid xs={6} item>
            <MDButton
              variant="gradient"
              color="info"
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '5px',
                marginBottom: '45px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={close}>
              Cancel
            </MDButton>
          </Grid>
        </Grid>

        {/* Modal for warning */}
        <Modal
          open={incompleteWarningModalOpen}
          onClose={() => setIncompleteWarningModalOpen(false)}
          className={classes.modal}>
          <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <MDTypography mb={1}>Warning</MDTypography>
              <MDTypography>
                The following information is incomplete!
              </MDTypography>
              <br />
              {incompleteFields &&
                incompleteFields.map((field, index) => (
                  <p style={{ color: 'red' }} key={index}>
                    {field}
                  </p>
                ))}
              <br />
            </MDBox>
          </Card>
        </Modal>
        {/* Modal for confirm */}
        <Modal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          className={classes.modal}>
          <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <MDTypography mb={1}>Confirm</MDTypography>
              <MDTypography>
                You are going to create a new user as below:
              </MDTypography>
              <TextEditedContent
                content={JSON.stringify(formState, undefined, 4)}
              />
              <Grid
                spacing={3}
                container
                justify="flex-end"
                alignItems="flex-end"
                style={{ marginTope: '20px' }}>
                <Grid xs={6} item>
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{
                      color: 'white',
                      fontSize: pxToRem(18),
                      marginTop: '5px',
                      marginBottom: '45px',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    onClick={sendToServer}>
                    Confirm
                  </MDButton>
                </Grid>
                <Grid xs={6} item>
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{
                      color: 'white',
                      fontSize: pxToRem(18),
                      marginTop: '5px',
                      marginBottom: '45px',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    onClick={() => {
                      setIncompleteWarningModalOpen(false);
                      setConfirmModalOpen(false);
                    }}>
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Modal>
      </MDBox>
    </Fragment>
  );
};
IWGDashboardContractsNew.propTypes = propTypes;

export default withRouter(IWGDashboardContractsNew);

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Star from './Star';
import Question from './Question';

import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.object,
  data: PropTypes.array,
  setData: PropTypes.func,
};

const DashboardBillingForSentCasesDetailedTableCustomToolbarSelect = ({
  selectedRows,
  data,
  setData,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Star selectedRows={selectedRows} data={data} setData={setData} />
      <Question selectedRows={selectedRows} data={data} setData={setData} />
    </div>
  );
};

DashboardBillingForSentCasesDetailedTableCustomToolbarSelect.propTypes =
  propTypes;

export default withRouter(
  DashboardBillingForSentCasesDetailedTableCustomToolbarSelect
);

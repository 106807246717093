const createPricesDictHelper = (pricesList, level, maxLevel) => {
  if (level === maxLevel) {
    return pricesList[0][level];
  }

  const obj = {};

  const keyList = [];
  pricesList.forEach((price) => {
    keyList.push(price[level]);
  });

  const uniqueKeyList = [...new Set(keyList)];

  uniqueKeyList.forEach((key) => {
    const subPricesList = pricesList.filter((price) => price[level] === key);
    obj[key] = createPricesDictHelper(subPricesList, level + 1, maxLevel);
  });

  return obj;
};

// create a dict for prices
// pricesDict[srcOrgId][dstOrgId][modality][bodypart] = price
export const createPricesDict = (prices) => {
  const pricesList = prices.map((item) => [
    item.srcOrgId,
    item.dstOrgId,
    item.modality,
    item.bodypart,
    item.price,
  ]);

  const pricesDict = createPricesDictHelper(pricesList, 0, 4);

  return pricesDict;
};

// create a dict for mapping source organization id to organization name
export const createSrcOrgNamesDict = (issues, orgs) => {
  const srcOrgIds = [];
  issues.forEach((issue) => {
    srcOrgIds.push(issue.srcOrgId);
  });

  const uniqueSrcOrgIds = new Set(srcOrgIds);

  const srcOrgs = orgs.filter((item) => uniqueSrcOrgIds.has(item.id));
  const srcOrgNamesDict = srcOrgs.reduce((acc, srcOrg) => {
    acc[srcOrg.id] = srcOrg.name;
    return acc;
  }, {});

  return srcOrgNamesDict;
};

// create a dict for mapping destination organization id to organization name
export const createDstOrgNamesDict = (issues, orgs) => {
  const dstOrgIds = [];
  issues.forEach((issue) => {
    dstOrgIds.push(issue.dstOrgId);
  });

  const uniqueDstOrgIds = new Set(dstOrgIds);

  const dstOrgs = orgs.filter((item) => uniqueDstOrgIds.has(item.id));
  const dstOrgNamesDict = dstOrgs.reduce((acc, dstOrg) => {
    acc[dstOrg.id] = dstOrg.name;
    return acc;
  }, {});

  return dstOrgNamesDict;
};

export const createQuotaSrcOrgsDict = (srcOrgQuotas) => {
  const srcOrgQuotasDict = srcOrgQuotas.reduce((acc, srcOrgQuota) => {
    acc[srcOrgQuota.srcOrgId] = {
      monthlyInvoiceDate: srcOrgQuota.monthlyInvoiceDate,
      lastInvoiceIssueDate: srcOrgQuota.lastInvoiceIssueDate,
    };
    return acc;
  }, {});

  return srcOrgQuotasDict;
};

export const getPrice = (issue, pricesDict) => {
  let p = 0;
  try {
    p =
      pricesDict[issue.srcOrgId][issue.dstOrgId][issue.modality][
        issue.bodypart
      ];
  } catch {
    console.log(
      `undefined price for issue: ${JSON.stringify(
        issue.srcOrgId
      )}  ${JSON.stringify(issue.dstOrgId)} ${JSON.stringify(
        issue.modality
      )} ${JSON.stringify(issue.bodypart)} `
    );
  }
  return p;
};

// calculate the total price given a series of issues and the price dict
export const calculateTotalPrice = (issues, pricesDict) => {
  let totalPrice = 0;
  issues.forEach((issue) => {
    const p = getPrice(issue, pricesDict);
    totalPrice += issue.waive ? 0 : p * issue.discount + issue.difference;
  });

  return totalPrice;
};

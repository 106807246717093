import 'date-fns';
import React from 'react';
import intl from 'react-intl-universal';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

const propType = {
  selectedDate: PropTypes.any,
  setSelectedDate: PropTypes.func.isRequired,
};

export default function MaterialUIPickers({ selectedDate, setSelectedDate }) {
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <DatePicker
          variant="inline"
          openTo="month"
          views={['year', 'month']}
          label={intl.get('billing_label_month_selector_note')}
          value={selectedDate}
          onChange={handleDateChange}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

MaterialUIPickers.propTypes = propType;

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  triggerIconButton: {},
  closeIconButton: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    zIndex: '1000',
  },
  popoverContentRoot: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '320px',
  },
  popoverContent: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  input: {
    width: '150px',
  },
}));

export default useStyles;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IssuePriority } from 'shared/constants/issues';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

const propTypes = {
  priority: PropTypes.string.isRequired,
};

const IssuePriorityIcon2 = ({ priority }) => {
  // TODO: optimize code: too much duplicate code!  make a new component with the specified props;
  switch (priority) {
    case IssuePriority.HIGHEST:
    case IssuePriority.HIGH:
      return (
        <Fragment>
          <div>
            <FontAwesomeIcon
              icon={faCaretRight}
              size="lg"
              color="red"
              style={{ marginRight: '3px', marginLeft: '3px' }}
            />
            <FontAwesomeIcon
              icon={faCaretRight}
              size="lg"
              color="red"
              style={{ marginRight: '3px' }}
            />
            <FontAwesomeIcon
              icon={faCaretRight}
              size="lg"
              color="red"
              style={{ marginRight: '3px' }}
            />
          </div>
        </Fragment>
      );
    case IssuePriority.MEDIUM:
      return (
        <Fragment>
          <FontAwesomeIcon
            icon={faCaretRight}
            size="lg"
            color="orange"
            style={{ marginRight: '3px', marginLeft: '3px' }}
          />
          <FontAwesomeIcon
            icon={faCaretRight}
            size="lg"
            color="orange"
            style={{ marginRight: '3px' }}
          />
        </Fragment>
      );
    case IssuePriority.LOW:
      return (
        <Fragment>
          <FontAwesomeIcon
            icon={faCaretRight}
            size="lg"
            color="green"
            style={{ marginRight: '3px', marginLeft: '3px' }}
          />
        </Fragment>
      );
    default:
      return;
  }
};

IssuePriorityIcon2.propTypes = propTypes;

export default IssuePriorityIcon2;

import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
// import { Avatar, Select, Icon } from 'components';
import { Select } from 'components';
import MDTypography from 'components/MDTypography';
import { Filters, SearchInput, User, Username } from './Styles';

const propTypes = {
  sourceOrganizations: PropTypes.array.isRequired,
  projectUsers: PropTypes.array.isRequired,
  defaultFilters: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  mergeFilters: PropTypes.func.isRequired,
};

const ProjectBoardFilters = ({
  sourceOrganizations,
  projectUsers,
  defaultFilters,
  filters,
  mergeFilters,
}) => {
  // const { searchTerm, userIds, recent } = filters; // we may need "recent" in future
  const { searchTerm } = filters;

  // const areFiltersCleared = !searchTerm && userIds.length === 0 && !recent; // we may need "recent" in future

  const doctors = projectUsers
    .filter((u) => u.userType === 'doctor')
    .map((u) => ({ value: u.id, label: `${u.lastName} ${u.firstName}` }));
  // const doctorsUserIds = projectUsers.filter(u => u.userType === 'doctor').map(u => u.id);
  const getUserById = (userId) =>
    projectUsers.find((user) => user.id === userId);
  const onSelectDoctor = (inputUserId) => {
    inputUserId === -1
      ? mergeFilters({ userIds: [] })
      : mergeFilters({ userIds: [inputUserId] });
  };

  const sourceOrgOptions = sourceOrganizations.map((item) => ({
    value: item.srcOrgId,
    label: item.srcOrgName,
  }));
  const getOrgById = (orgId) =>
    sourceOrganizations.find((item) => item.srcOrgId === orgId);
  const onSelectOrganization = (inputOrgId) => {
    if (inputOrgId === -1) {
      mergeFilters({ srcOrgNames: [] });
    } else {
      const srcOrg = getOrgById(inputOrgId);
      mergeFilters({ srcOrgNames: [srcOrg.srcOrgName] });
    }
  };
  return (
    <Filters data-testid="board-filters">
      <Stack direction="row" alignItems="center">
        <SearchInput
          icon="search"
          value={searchTerm}
          onChange={(value) => mergeFilters({ searchTerm: value })}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <MDTypography variant="h6" marginLeft="20px">
            {intl.get('kanban_filter_text_filter_by_doctor')}
          </MDTypography>
          <Select
            singleSelectRemovable
            variant="empty"
            dropdownWidth={200}
            placeholder={intl.get('kanban_filter_text_click_to_select')}
            options={doctors}
            onChange={onSelectDoctor}
            renderValue={({ value: userId }) =>
              renderSelectedDoctor(getUserById(userId))
            }
            renderOption={({ label: userLabel }) =>
              renderDoctorOptions(userLabel)
            }
          />
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <MDTypography variant="h6" marginLeft="20px">
            {intl.get('kanban_filter_text_filter_by_organization')}
          </MDTypography>
          <Select
            singleSelectRemovable
            variant="empty"
            dropdownWidth={400}
            placeholder={intl.get('kanban_filter_text_click_to_select')}
            options={sourceOrgOptions}
            onChange={onSelectOrganization}
            renderValue={({ value: orgId }) =>
              renderSelectedOrganization(getOrgById(orgId))
            }
            renderOption={({ label: orgLabel }) =>
              renderOrganizationOptions(orgLabel)
            }
          />
        </Stack>

        {/* // we may need "recent" in future */}
        {/* <StyledButton
          variant="empty"
          isActive={recent}
          onClick={() => mergeFilters({ recent: !recent })}
        >
          Recently Updated  
        </StyledButton> */}

        {/* {!areFiltersCleared && (
          <ClearAll onClick={() => mergeFilters(defaultFilters)} style={{color: '#F3F3F3'}}>{intl.get('kanban_button_clear_all')}</ClearAll>
        )} */}
      </Stack>
    </Filters>
  );
};

const renderSelectedDoctor = (user) => {
  return (
    <User isSelectValue>
      <Username>{`${user.lastName} ${user.firstName}`}</Username>
    </User>
  );
};

const renderDoctorOptions = (userLabel) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>{userLabel}</div>
  );
};

const renderSelectedOrganization = (org) => {
  return (
    <User isSelectValue>
      <Username>{`${org.srcOrgName}`}</Username>
    </User>
  );
};

const renderOrganizationOptions = (orgLabel) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>{orgLabel}</div>
  );
};

ProjectBoardFilters.propTypes = propTypes;

export default ProjectBoardFilters;

import { useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import List from '@mui/material/List';
// components for Sidenav
import SidenavCollapse from '../SidenavCollapse';
import SidenavList from '../SidenavList';
import SidenavItem from '../SidenavItem';
import PersonIcon from '@mui/icons-material/Person';

import localData from 'shared/utils/localData';

const propTypes = {
  color: PropTypes.string,
  userInfo: PropTypes.object,
  handleClickLogout: PropTypes.func,
};
function ProfileMenu({ color, userInfo, handleClickLogout }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const items = pathname.split('/').slice(1);
  const itemName = items[items.length - 1];

  const key = 'profile';

  const userFirstName = userInfo.firstName;
  const userLastName = userInfo.lastName;
  const preferredLanguage = userInfo.preferredLanguage;
  const userFullName =
    preferredLanguage === 'en-US'
      ? userFirstName + ' ' + userLastName
      : userLastName + ' ' + userFirstName;
  var OmittedUserFullName = userFullName;
  const byteSize = (str) => new Blob([str]).size;
  if (
    byteSize(OmittedUserFullName[0]) === 1 &&
    OmittedUserFullName.length > 15
  ) {
    OmittedUserFullName = userFullName.substring(0, 14) + '...';
  } else if (
    byteSize(OmittedUserFullName[0]) === 3 &&
    OmittedUserFullName.length > 9
  ) {
    OmittedUserFullName = userFullName.substring(0, 8) + '...';
  }

  const currentOrganization = localData.get('currentOrganization');
  const organizations = userInfo.organizations;

  const changeCurrentOrganization = (newOrganization, newOrganizationId) => {
    localData.set('currentOrganization', newOrganization);
    localData.set('currentOrganizationId', newOrganizationId);
    // window.location.reload();
  };

  return (
    <List>
      <SidenavCollapse
        key={key}
        name={OmittedUserFullName}
        // icon={<MDAvatar src={profilePicture} alt={name} size="md" />}
        // icon={<MDAvatar src={profilePicture} alt={name} size="ld" />}
        // icon={<Icon fontSize="medium">person</Icon>}
        icon={<PersonIcon fontSize="large" />}
        active={openCollapse}
        open={openCollapse}
        onClick={() =>
          openCollapse ? setOpenCollapse(false) : setOpenCollapse(true)
        }>
        <SidenavList key={'personal-settings'}>
          <NavLink
            to={'/personal-settings'}
            key={'personal-settings'}
            sx={{ textDecoration: 'none' }}>
            <SidenavItem
              color={color}
              name={intl.get('dashboard_button_personal_settings')}
              active={key === itemName}
            />
          </NavLink>
        </SidenavList>

        <SidenavList key={'organization'}>
          <SidenavItem
            key={'organization'}
            color={color}
            name={
              currentOrganization !== 'null'
                ? currentOrganization
                : intl.get('dashboard_button_no_organization')
            }
            active={openNestedCollapse}
            open={openNestedCollapse}
            onClick={({ currentTarget }) =>
              openNestedCollapse &&
              currentTarget.classList.contains('MuiListItem-root')
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(true)
            }>
            {organizations &&
              organizations.map((org, index) => (
                <SidenavItem
                  key={org.id}
                  name={org.name}
                  active={false}
                  nested
                  onClick={() => changeCurrentOrganization(org.name, org.id)}
                />
              ))}
          </SidenavItem>
        </SidenavList>

        <SidenavList key={'logout'}>
          <SidenavItem
            color={color}
            name={intl.get('dashboard_button_logout')}
            onClick={handleClickLogout}
          />
        </SidenavList>
      </SidenavCollapse>
    </List>
  );
}

// Setting default values for the props of Sidenav
ProfileMenu.defaultProps = {
  color: 'info',
  brand: '',
};

// Typechecking props for the Sidenav
ProfileMenu.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ProfileMenu.propTypes = propTypes;
export default ProfileMenu;

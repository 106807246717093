import intl from 'react-intl-universal';

const queryLocalCode = (lang) => {
  if (lang) {
    if (lang.startsWith('en')) {
      return 'en-US';
    }
    if (lang.startsWith('zh')) {
      return 'zh-CN';
    }
    if (lang.startsWith('ja')) {
      return 'ja-JP';
    }
  }
  return 'en-US';
};

const getTranslationData = (locale) => {
  try {
    return require(`locales/${locale}`);
  } catch (e) {
    return require('locales/en-US.json');
  }
};

const injectLocale = (locale) => {
  const data = getTranslationData(locale);
  intl.get = (key) => {
    return data[key];
  };
};

export { injectLocale, queryLocalCode };

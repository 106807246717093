import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import axios from 'axios';

import api from 'shared/utils/api';
import BodyForm from '../BodyForm';
import ProTip from './ProTip';
import { Create, UserAvatar, Right, FakeTextarea } from './Styles';
import localData from 'shared/utils/localData';
import { generate8DigitsIdentifier } from 'shared/utils/randomIdGenerator';

const propTypes = {
  issueId: PropTypes.number.isRequired,
  fetchIssue: PropTypes.func.isRequired,
  isClient: PropTypes.bool,
  allowReport: PropTypes.bool,
  updateLocalProjectIssues: PropTypes.func,
  quoteAttachmentName: PropTypes.string,
  refreshQuote: PropTypes.number,
  commentEditorRef: PropTypes.shape({
    current: PropTypes.instanceOf(HTMLInputElement),
  }),
  isCommentFormOpen: PropTypes.bool,
  setIsCommentFormOpen: PropTypes.func,
  handleAddAttachmentButtonClick: PropTypes.func,
};

const ProjectBoardIssueDetailsCommentsCreate = ({
  issueId,
  fetchIssue,
  isClient,
  allowReport,
  updateLocalProjectIssues,
  quoteAttachmentName,
  refreshQuote,
  commentEditorRef,
  isCommentFormOpen,
  setIsCommentFormOpen,
  handleAddAttachmentButtonClick,
}) => {
  const [isCreating, setCreating] = useState(false);

  const defaultFormState = {
    commentBody: '',
    submitType: 'global',
    file: undefined,
    fileName: '',
    progressInfos: { val: [] },
  };
  const [formState, setFormState] = useState({ ...defaultFormState });

  const preferredLanguage = localData.get('userPreferredLanguage');
  const username = localData.get('username');
  const userId = localData.get('userId');
  const userType = localData.get('userType');
  const userFirstName = localData.get('userFirstName');
  const userLastName = localData.get('userLastName');
  // const userAvatarUrl = localData.get('userAvatarUrl');

  const handleCommentCreate = async () => {
    try {
      setCreating(true);
      // First, get the S3 bucket PostObject pre-signed URL to upload
      const formData = new FormData();
      const info = {
        issueId: issueId,
        username: username,
        userId: userId,
        userType: userType,
        uid: `${generate8DigitsIdentifier()}`,
      };
      formData.append('info', JSON.stringify(info));
      formData.append('commentBody', formState.commentBody);
      formData.append('submitType', formState.submitType);
      formData.append('fileName', formState.fileName);

      const apiVariables = {
        'Content-Type': 'multipart/form-data',
        data: formData,
        params: {
          organizationId: localData.get('currentOrganizationId'),
        },
      };

      const data = await api.post('/api/comments/new', apiVariables);

      // Now we have the AWS S3 presigned url, we can upload the file directly to S3 bucket;
      if (data.PutObjectURL !== 'none') {
        const res = await axios.put(data.PutObjectURL, formState.file, {
          // onUploadProgress: data => {
          //     // this.setState({
          //     //     Loaded: (ProgressEvent.loaded / ProgressEvent.total*100)
          //     // })
          //     // let v = Math.round((100 * data.loaded) / data.total);
          //     // setUploadingProgress(v)
          // }
          // console.log(`----- res: ${JSON.stringify(res)}`);
        });
        console.log(res);
      }

      setFormState({ ...defaultFormState });
      if (data.message === 'REPORTREADY') {
        updateLocalProjectIssues(issueId, { status: 'REPORTREADY' });
      }

      await fetchIssue();
      setIsCommentFormOpen(false);
      setCreating(false);
    } catch (error) {
      console.log('handleCommentCreate: ', error);
      setIsCommentFormOpen(false);
      setCreating(false);
    }
  };

  return (
    <Create>
      {username && (
        <UserAvatar
          name={preferredLanguage === 'ja-JP' ? userLastName : userFirstName}
        />
      )}
      <Right>
        {isCommentFormOpen ? (
          <BodyForm
            isClient={isClient}
            allowReport={allowReport}
            isWorking={isCreating}
            formState={formState}
            setFormState={setFormState}
            onSubmit={handleCommentCreate}
            onCancel={() => setIsCommentFormOpen(false)}
            quoteAttachmentName={quoteAttachmentName}
            refreshQuote={refreshQuote}
            commentEditorRef={commentEditorRef}
            handleAddAttachmentButtonClick={handleAddAttachmentButtonClick}
          />
        ) : (
          <Fragment>
            <FakeTextarea onClick={() => setIsCommentFormOpen(true)}>
              {intl.get('issue_details_placeholder_add_a_comment')}
            </FakeTextarea>
            <ProTip setFormOpen={setIsCommentFormOpen} />
          </Fragment>
        )}
      </Right>
    </Create>
  );
};

ProjectBoardIssueDetailsCommentsCreate.propTypes = propTypes;

export default ProjectBoardIssueDetailsCommentsCreate;

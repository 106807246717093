import intl from 'react-intl-universal';
// import { withStyles, makeStyles } from "@material-ui/core/styles";
// import { ThemeProvider } from '@material-ui/core/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography, Grid } from '@material-ui/core';
import { Button } from 'components';
import MDBox from 'components/MDBox';
// import MDButton from "components/MDButton";
import { formatBytes } from 'shared/utils/misc';
import { generate8DigitsIdentifier } from 'shared/utils/randomIdGenerator';
// import { treeItemTheme } from 'pages/parts/CustomThemes';
// import localData from 'shared/utils/localData';
// import api from 'shared/utils/api';

export const generateAttachmentList = ({
  srcOrDst,
  orgId,
  attachments,
  dicomFilePaths,
  darkMode,
  handleCommentButtonClick,
  handleDownloadButtonClick,
  handleLinkButtonClick,
  handlePreviewButtonClick,
}) => {
  let filteredAttachments;

  if (srcOrDst === 'src') {
    filteredAttachments = attachments.filter(
      (item) => !item.organizationId || item.organizationId === orgId
    );
  } else {
    filteredAttachments = attachments.filter(
      (item) => item.organizationId === orgId
    );
  }

  const res = filteredAttachments.map((item, index) => {
    let dicoms;
    if (item.title in dicomFilePaths) {
      dicoms = dicomFilePaths[item.title];
    } else {
      dicoms = item.dicomFilesInfo;
    }
    const structureJson = item.structureJson;
    const label =
      structureJson.type === 'directory'
        ? structureJson.name
        : `${structureJson.name}  -  ${formatBytes(structureJson.size)}`;

    return (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {structureJson.type === 'file' ? (
              <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                {label}
              </Typography>
            ) : (
              // <ThemeProvider theme={treeItemTheme}>
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                style={{
                  // color: darkMode ? 'white' : 'black',
                  // backgroundColor: darkMode ? '#202940' : 'white',
                  color: 'black',
                  backgroundColor: 'white',
                }}
                sx={{
                  pt: 0.5,
                  maxheight: 400,
                  flexGrow: 1,
                  maxWidth: 500,
                  overflowY: 'auto',
                }}>
                {generateTreeView(structureJson)}
              </TreeView>
              // </ThemeProvider>
            )}
          </Grid>

          <Grid item xs={2}>
            <Button
              icon="page"
              iconSize={20}
              variant="empty"
              onClick={() => handleCommentButtonClick(item)}>
              {intl.get('issue_details_quote_filename_in_comment')}
            </Button>
          </Grid>

          <Grid item xs={2}>
            <Button
              icon="arrow-down"
              iconSize={20}
              variant="empty"
              onClick={() => handleDownloadButtonClick(item)}>
              {intl.get('issue_details_button_download')}
            </Button>
          </Grid>

          {isObject(dicoms) && Object.keys(dicoms).length > 0 && (
            <Grid item xs={2}>
              <Button
                icon="board"
                iconSize={20}
                variant="empty"
                onClick={() => handleLinkButtonClick(dicoms)}>
                {intl.get('issue_details_button_jump_to_viewer')}
              </Button>
            </Grid>
          )}

          {structureJson.type === 'file' && !isObject(dicoms) && (
            <Grid item xs={2}>
              <Button
                icon="board"
                iconSize={20}
                variant="empty"
                onClick={() => handlePreviewButtonClick(item)}>
                {intl.get('issue_details_button_preview')}
              </Button>
            </Grid>
          )}
        </Grid>
      </MDBox>
    );
  });

  return res;
};

export const generateTreeView = (structureObject) => {
  const labelString =
    structureObject.type === 'directory'
      ? structureObject.name
      : `${structureObject.name}  -  ${formatBytes(structureObject.size)}`;
  const label = (
    <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
      {labelString}
    </Typography>
  );

  return (
    <TreeItem
      nodeId={`${structureObject.name}-${generate8DigitsIdentifier()}`}
      label={label}>
      {structureObject.type === 'directory' &&
        structureObject.children.map((item) => generateTreeView(item))}
    </TreeItem>
  );
};

export const generateAttchmentList_OLD = (
  attachments,
  dicomFilePaths,
  darkMode,
  handleDownloadButtonClick,
  handleLinkButtonClick
) => {
  const res = attachments.map((item, index) => {
    // the only way to distinguish between dicomZip and non dicomZip is by checking the dicomFilePaths; Bad!
    const dicoms = dicomFilePaths[item.title] || null;
    return (
      <MDBox style={{ marginBottom: '15px' }} key={index}>
        <Grid container spacing={3} alignItems="flex-start" justify="left">
          <Grid item xs={6}>
            <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
              {item.title}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Button
              icon="arrow-down"
              iconSize={20}
              variant="empty"
              onClick={() => handleDownloadButtonClick(item.id)}>
              {intl.get('issue_details_button_download')}
            </Button>
          </Grid>

          {isObject(dicoms) && Object.keys(dicoms).length > 0 && (
            <Grid item xs={2}>
              <Button
                icon="board"
                iconSize={20}
                variant="empty"
                onClick={() => handleLinkButtonClick(dicoms)}>
                {intl.get('issue_details_button_jump_to_viewer')}
              </Button>
            </Grid>
          )}
        </Grid>
      </MDBox>
    );
  });

  return res;
};

export const isObject = (objValue) => {
  return (
    objValue && typeof objValue === 'object' && objValue.constructor === Object
  );
};

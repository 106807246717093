import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import { Grid, Modal, TextField } from '@material-ui/core';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { TextEditedContent } from 'components';
import api from 'shared/utils/api';
import pxToRem from 'assets/theme/functions/pxToRem';

import useStyles from './styles';

const propTypes = {
  close: PropTypes.func.isRequired,
  fetchOrgs: PropTypes.func.isRequired,
};

const IWGDashboardOrganizationsNew = ({ close, fetchOrgs }) => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    name: '',
    organizationCode: '',
    contact: {
      zipCode: '110-1010',
      addressLine1: '東京都新宿区新宿1-2-3',
      addressLine2: '新宿第1ビル2階',
      tel: '070-0070-0700',
      fax: '070-7000-0700',
    },
    paymentMethod: {
      bank: '○○銀行',
      branch: '△△支店',
      accountType: '当座',
      accountNo: '0123456',
      accountName: 'サンプル(カ',
    },
  });
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [incompleteWarningModalOpen, setIncompleteWarningModalOpen] =
    useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const keyToCheck = ['name', 'organizationCode'];

  const keyToLabel = {
    name: 'Organization Name',
    organizationCode: 'Organization Code',
  };

  const handleChange = (key) => async (event) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  const sendToServer = async () => {
    const data = { ...formState };
    await api.post('/api/admin/orgs', {
      data: {
        createdData: [data],
      },
    });
    await fetchOrgs();
    close();
  };

  const checkIfFormCompleted = () => {
    const _incompleteFields = [];

    keyToCheck.forEach((key) => {
      const value = formState[key];
      if (value.length === 0) {
        _incompleteFields.push(keyToLabel[key]);
      }
    });

    if (_incompleteFields.length > 0) {
      setIncompleteFields(_incompleteFields);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();
    if (!checkIfFormCompleted()) {
      setIncompleteWarningModalOpen(true);
    } else {
      setConfirmModalOpen(true);
    }
  };

  return (
    <Fragment>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <Grid spacing={3} container alighItems="flex-start" justify="center">
          <Grid xs={12} item>
            <TextField
              id="name"
              name="name"
              fullWidth
              variant="outlined"
              label="Organization Name"
              onChange={handleChange('name')}
              value={formState.name}
              // color="primary"
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              id="organizationCode"
              name="organizationCode"
              fullWidth
              variant="outlined"
              label="Organization Code"
              onChange={handleChange('organizationCode')}
              value={formState.organizationCode}
              // color="primary"
            />
          </Grid>
        </Grid>
        <Grid spacing={3} container alignItems="flex-start" justify="center">
          <Grid xs={6} item>
            <MDButton
              variant="gradient"
              color="info"
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '5px',
                marginBottom: '45px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={handleSubmitClick}>
              Submit
            </MDButton>
          </Grid>
          <Grid xs={6} item>
            <MDButton
              variant="gradient"
              color="info"
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '5px',
                marginBottom: '45px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={close}>
              Cancel
            </MDButton>
          </Grid>
        </Grid>

        {/* Modal for warning */}
        <Modal
          open={incompleteWarningModalOpen}
          onClose={() => setIncompleteWarningModalOpen(false)}
          className={classes.modal}>
          <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <MDTypography mb={1}>Warning</MDTypography>
              <MDTypography>
                The following information is incomplete!
              </MDTypography>
              <br />
              {incompleteFields &&
                incompleteFields.map((field, index) => (
                  <p style={{ color: 'red' }} key={index}>
                    {field}
                  </p>
                ))}
              <br />
            </MDBox>
          </Card>
        </Modal>
        {/* Modal for confirm */}
        <Modal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          className={classes.modal}>
          <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <MDTypography mb={1}>Confirm</MDTypography>
              <MDTypography>
                You are going to create a new organization as below:
              </MDTypography>
              <TextEditedContent
                content={JSON.stringify(formState, undefined, 4)}
              />
              <Grid
                spacing={3}
                container
                justify="flex-end"
                alignItems="flex-end"
                style={{ marginTope: '20px' }}>
                <Grid xs={6} item>
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{
                      color: 'white',
                      fontSize: pxToRem(18),
                      marginTop: '5px',
                      marginBottom: '45px',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    onClick={sendToServer}>
                    Confirm
                  </MDButton>
                </Grid>
                <Grid xs={6} item>
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{
                      color: 'white',
                      fontSize: pxToRem(18),
                      marginTop: '5px',
                      marginBottom: '45px',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    onClick={() => {
                      setIncompleteWarningModalOpen(false);
                      setConfirmModalOpen(false);
                    }}>
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Modal>
      </MDBox>
    </Fragment>
  );
};

IWGDashboardOrganizationsNew.propTypes = propTypes;

export default withRouter(IWGDashboardOrganizationsNew);

import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  invoiceAmountListItems: PropTypes.array,
};

const InvoiceAmountList = ({ invoiceAmountListItems }) => {
  return (
    <Fragment>
      <View style={styles.invoiceAmountListTable}>
        <View style={styles.invoiceAmountListHeader}>
          <Text style={styles.invoiceAmountListNo}>
            {intl.get('billing_invoice_amount_list_label_no')}
          </Text>
          <Text style={styles.invoiceAmountListItem}>
            {intl.get('billing_invoice_amount_list_label_item')}
          </Text>
          <Text style={styles.invoiceAmountListItemUnitPrice}>
            {intl.get('billing_invoice_amount_list_label_item_unit_price')}
          </Text>
          <Text style={styles.invoiceAmountListItemCount}>
            {intl.get('billing_invoice_amount_list_label_item_count')}
          </Text>
          <Text style={styles.invoiceAmountListItemTotalPrice}>
            {intl.get('billing_invoice_amount_list_label_item_total_price')}
          </Text>
        </View>
        {invoiceAmountListItems.map((item, index) => {
          return (
            <View style={styles.invoiceAmountListRow} key={index}>
              <Text style={styles.invoiceAmountListNo}>{index + 1}</Text>
              <Text style={styles.invoiceAmountListItem}>{item.item}</Text>
              <Text style={styles.invoiceAmountListItemUnitPrice}>
                {item.unitPrice}
              </Text>
              <Text style={styles.invoiceAmountListItemCount}>
                {item.count}
              </Text>
              <Text style={styles.invoiceAmountListItemTotalPrice}>
                {item.totalPrice}
              </Text>
            </View>
          );
        })}
      </View>
      <View style={styles.invoiceAmountListTableFooter}>
        <View style={styles.invoiceAmountListTableFooterRow}>
          <Text style={styles.invoiceAmountListTableFooterLabel}>
            {intl.get('billing_invoice_amount_list_label_sub_total')}
          </Text>
          <Text style={styles.invoiceAmountListTableFooterText}>
            {invoiceAmountListItems[0].unitPrice}
          </Text>
        </View>
        <View style={styles.invoiceAmountListTableFooterRow}>
          <Text style={styles.invoiceAmountListTableFooterLabel}>
            {intl.get('billing_invoice_amount_list_label_consumption_tax')}
          </Text>
          <Text style={styles.invoiceAmountListTableFooterText}>
            {parseInt(invoiceAmountListItems[0].unitPrice * 0.1)}
          </Text>
        </View>
        <View style={styles.invoiceAmountListTableFooterRow}>
          <Text style={styles.invoiceAmountListTableFooterLabel}>
            {intl.get('billing_invoice_amount_list_total_price')}
          </Text>
          <Text style={styles.invoiceAmountListTableFooterText}>
            {parseInt(invoiceAmountListItems[0].unitPrice * 1.1)}
          </Text>
        </View>
      </View>
    </Fragment>
  );
};

InvoiceAmountList.propTypes = propTypes;
export default InvoiceAmountList;

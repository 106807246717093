import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // appBar: {
  //   position: 'relative',
  // },
  // layout: {
  //   width: 'auto',
  //   marginLeft: theme.spacing(2),
  //   marginRight: theme.spacing(2),
  //   [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
  //     width: 600,
  //     marginLeft: 'auto',
  //     marginRight: 'auto',
  //   },
  // },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(20),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },

  modalModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },

  modalPaper: {
    backgroundColor: '#FFFFF3',
    border: '2px solid #000',
    position: 'absolute',
    width: 800,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  modalPaperNarrow: {
    backgroundColor: '#FFFFF3',
    border: '2px solid #000',
    position: 'absolute',
    width: 500,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  modalPaperWide: {
    backgroundColor: '#FFFFF3',
    border: '2px solid #000',
    position: 'absolute',
    width: 800,
    height: 500,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  description: {
    backgroundColor: '#fafafa',
    height: 185,
  },
}));

export default useStyles;

import {
  InvalidFileExtensions,
  InvalidFileTypeNames,
} from 'shared/constants/invalidFileTypes';

export const fileTypeIsValid = (file) => {
  var ans = true;
  const ext = file.name.split('.').pop().toLowerCase();
  if (InvalidFileExtensions.has(ext) || InvalidFileTypeNames.has(file.type)) {
    ans = false;
  }
  return ans;
};

// TODO: unit-test
/**
 *  Given an array of file objects, iterate and analyze the fileFullPath of each file, and
 *  return an object with nested layers according to the hierarchical folder structure;
 *
 *  @param {[object]}  items an array of file objects; each object must contain a `fileFullPath` attribute;
 */
export const getStructuredFiles = (items) => {
  let result = [];
  let level = { result };

  items.forEach((item) => {
    let path = item.fileFullPath;
    path.split('/').reduce((r, name, i, a) => {
      if (!r[name]) {
        r[name] = { result: [] };
        const type = i === a.length - 1 ? 'file' : 'directory';
        if (type === 'file') {
          r.result.push(item);
        } else {
          if (item.Bucket && item.Key) {
            const folderKey = `${item.Key.split(name)[0] || ''}${name}`;
            r.result.push({
              name,
              type,
              Bucket: item.Bucket,
              Key: folderKey,
              children: r[name].result,
            });
          } else {
            r.result.push({ name, type, children: r[name].result });
          }
        }
      }
      return r[name];
    }, level);
  });

  return result;
};

/**
 *  This is the reverse operation of `getStructuredFiles` above;
 *  Given an object with nested layers corresponding to hierarchical folder structure,
 *  recursively go through the layers, and return an array of objects. Each object in the array
 *  represents a simple file with no nested layer.
 *
 * @param {object} item an object with nested layers corresponding to hierarchical folder structure,
 *  each node must contain an attribute named `type`
 *
 */
export const flattenStructuredAttachmentsObject = (item) => {
  const res = [];
  if (item.type === 'directory') {
    item.children.forEach(function (child) {
      const tmpList = flattenStructuredAttachmentsObject(child);
      res.push(...tmpList);
    });
  } else {
    res.push({ ...item });
  }

  return res;
};

/**
 *  Given an object representing a file or a folder, return the size of it
 *   - If a file, simply return the size;
 *   - If a folder, calculate the total size for all files under it;
 *
 * @param {object} item object must contain attribute `type`; If `type` is "directory",
 *  then it also must contain attribute `size`;
 */
export const calculateFolderSize = (item) => {
  let size = 0;
  if (item.type === 'directory') {
    item.children.forEach((item) => {
      size += calculateFolderSize(item);
    });
    item.size = size;
  } else {
    size = item.size;
  }
  return size;
};

/**
 *  Given an array of objects, with each object representing a folder or a file,
 *  calculate the total size
 *
 * @param {[object]} structuredAttachments an array of objects, with each object representing a folder or a file.
 */
export const calcBatchTotalSize = (structuredAttachments) => {
  let attachments = [];
  for (var i = 0; i < structuredAttachments.length; i++) {
    const item = structuredAttachments[i];
    attachments.push(...flattenStructuredAttachmentsObject(item));
  }
  const size = attachments.reduce((accumulate, item) => {
    return accumulate + item.size;
  }, 0);
  return size;
};

export const findDuplicateAttachments = (attachments, newAttachments) => {
  const dups = newAttachments.filter((item) =>
    attachments.some((x) => item.name === x.name)
  );
  return dups;
};

import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import {
  InfoLabel,
  InfoValue,
  InfoContent,
  InfoContainer,
  InfoContainerTags,
} from './styles';
// import { makeTagString } from 'src/pages/Dashboard/New/utils';
import { makeTagString } from 'pages/Dashboard/New/utils';

const propTypes = {
  issue: PropTypes.object.isRequired,
  isClient: PropTypes.bool,
};

const ProjectBoardIssueDetailsInfo = ({ issue, isClient }) => {
  const [CTTags, setCTTags] = useState([]);
  const [MRITags, setMRITags] = useState([]);
  const [PhyExamTags, setPhyExamTags] = useState([]);

  useEffect(() => {
    listTags(issue.tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listTags = (tags) => {
    const CTTagsArray = [];
    const MRITagsArray = [];
    const PhyExamTagsArray = [];

    // create the tagsObject to use the imported "makeTagString" function, in the form of {tagKeyName: true, ...};
    const tagsObject = {};
    for (var i = 0; i < tags.length; i++) {
      tagsObject[tags[i]] = true;
    }

    for (var j = 0; j < tags.length; j++) {
      const key = tags[j];
      const res = makeTagString(key, tagsObject);
      if (res) {
        if (key.startsWith('CT')) CTTagsArray.push(res);
        if (key.startsWith('MRI')) MRITagsArray.push(res);
        if (key.startsWith('PhyExam')) PhyExamTagsArray.push(res);
      }
    }

    setCTTags(CTTagsArray);
    setMRITags(MRITagsArray);
    setPhyExamTags(PhyExamTagsArray);
  };

  return (
    <Fragment>
      <InfoContent>
        <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_patient_name')}
          </InfoLabel>
          <InfoValue>{issue.patientName}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_patient_gender')}
          </InfoLabel>
          <InfoValue>{issue.patientGender}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_department')}
          </InfoLabel>
          <InfoValue>{issue.department}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_doctor_in_charge')}
          </InfoLabel>
          <InfoValue>{issue.doctorsInCharge.join(', ')}</InfoValue>
        </InfoContainer>
        <InfoContainerTags>
          <InfoLabel>{intl.get('issue_details_text_tags')}</InfoLabel>
          <InfoValue>CT: {CTTags.join(', ')}</InfoValue>
          <InfoValue>MRI: {MRITags.join(', ')}</InfoValue>
          <InfoValue>生理検査: {PhyExamTags.join(', ')}</InfoValue>
        </InfoContainerTags>
      </InfoContent>
    </Fragment>
  );
};

ProjectBoardIssueDetailsInfo.propTypes = propTypes;

export default ProjectBoardIssueDetailsInfo;

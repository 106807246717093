import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// @mui material components
import Card from '@mui/material/Card';
// import Switch from "@mui/material/Switch";
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDLoadingButton from 'components/MDLoadingButton';

// Authentication layout components
import BasicLayout from './components/BasicLayout';

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import bgImage from 'assets/images/background/bg-0.png';
import newBgImage from 'assets/images/bg-login-bai.jpeg';

import { CVIC_USERS } from 'shared/constants/cvicUsers';

// For change input background color
const useStyles = makeStyles((theme) => ({
  input: {
    background: '#FFFFFF !important',
    color: 'black !important',
  },
}));

function Basic() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    OTP: '',
  });
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [showWrongPasswordWarning, setShowWrongPasswordWarning] =
    useState(false);
  const [showWrongOTPWarning, setShowWrongOTPWarning] = useState(false);
  const [wrongOTPFor3Times, setWrongOTPFor3Times] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPInputBox, setShowOTPInputBox] = useState({
    ifShow: false,
    otpType: '',
  });
  const [cognitoUserObject, setCognitoUserObject] = useState({});

  const classes = useStyles();

  useEffect(() => {
    // when wrongOTPFor3Times, refresh page after 3 seconds;
    if (wrongOTPFor3Times) {
      setTimeout(() => {
        resetStates();
      }, 3000);
    }
  }, [wrongOTPFor3Times]);

  const resetStates = () => {
    setFormData({ username: '', password: '', OTP: '' });
    setShowWrongPasswordWarning(false);
    setShowWrongOTPWarning(false);
    setWrongOTPFor3Times(false);
    setShowPassword(false);
    setShowOTPInputBox(false);
    setCognitoUserObject({});
  };

  const refreshPage = () => {
    setShowWrongPasswordWarning(false);
    window.location.reload(false);
  };

  const cognitoSignIn = async () => {
    setIsSigningIn(true);

    if (showOTPInputBox.ifShow) {
      // text input box for One-Time-Password is shown; user needs to verify the OTP;

      try {
        // send the One-Time-Password as the answer of the custom challenge
        let res = null;
        if (
          !cognitoUserObject.signInUserSession &&
          cognitoUserObject.authenticationFlowType === 'CUSTOM_AUTH' &&
          cognitoUserObject.challengeName === 'CUSTOM_CHALLENGE'
        ) {
          res = await Auth.sendCustomChallengeAnswer(
            cognitoUserObject,
            formData.OTP
          );
        } else if (
          !cognitoUserObject.signInUserSession &&
          cognitoUserObject.authenticationFlowType === 'CUSTOM_AUTH' &&
          cognitoUserObject.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          res = await Auth.confirmSignIn(
            cognitoUserObject,
            formData.OTP,
            'SOFTWARE_TOKEN_MFA'
          );
        }
        if (res.signInUserSession === null) {
          // OTP is wrong; auth failed;
          setShowWrongOTPWarning(true);
        } else if (res.signInUserSession.accessToken) {
          // Auth is successful;
          // await rememberDevice(); // not really useful for now;
          refreshPage();
        }
      } catch (error) {
        if (
          error.code &&
          ['NotAuthorizedException', 'CodeMismatchException'].includes(
            error.code
          )
        ) {
          // User has exceeded the limit of trials for verification code.
          setShowWrongOTPWarning(false);
          setWrongOTPFor3Times(true);
        }
      }
    } else {
      // Verify username and password as a first step, without One-Time-Password;
      try {
        const user = await Auth.signIn(formData.username, formData.password);
        // if (user.authenticationFlowType === 'USER_SRP_AUTH' && user.username === 'cvic_super' && user.signInUserSession.accessToken) { // Auth is successful;
        if (
          user.authenticationFlowType === 'USER_SRP_AUTH' &&
          CVIC_USERS.has(user.username) &&
          user.signInUserSession.accessToken
        ) {
          // Auth is successful;
          refreshPage();
        } else if (
          user.authenticationFlowType === 'USER_SRP_AUTH' &&
          user.preferredMFA === 'NOMFA' &&
          user.signInUserSession.accessToken
        ) {
          // preferred mfa type is no mfa, login success
          refreshPage();
        } else {
          if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            setShowOTPInputBox({
              ifShow: true,
              otpType: 'TOTP',
            });
          } else if (user.challengeName === 'CUSTOM_CHALLENGE') {
            setShowOTPInputBox({
              ifShow: true,
              otpType: 'EMAIL',
            });
          }

          setCognitoUserObject(user);
        }
        // setShowOTPInputBox(true);
        // setCognitoUserObject(user);
      } catch (error) {
        if (
          error.code &&
          ['UserNotFoundException', 'NotAuthorizedException'].includes(
            error.code
          )
        ) {
          setShowWrongPasswordWarning(true);
        }
      }
    }

    setIsSigningIn(false);
  };

  const handleSignInButtonClick = (event) => {
    event.preventDefault();
    cognitoSignIn();
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setShowWrongPasswordWarning(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loginMode = 0;
  if (loginMode === 0) {
    return (
      <BasicLayout image={newBgImage} loginMode={loginMode}>
        <Card
          id="BasicLayoutChildrenCard"
          sx={{ backgroundColor: 'rgba(52, 52, 52, 0.8)' }}>
          <MDBox
            variant="gradient"
            // bgColor="info"
            sx={{ backgroundColor: '#194796' }}
            borderRadius="lg"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center">
            <MDTypography variant="h5" fontWeight="bold" color="white" mt={1}>
              医療情報クラウドプラットフォーム
            </MDTypography>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              sx={{ mt: 1, mb: 2 }}>
              <Grid item xs={6}>
                <MDTypography
                  variant="h5"
                  fontWeight="medium"
                  color="white"
                  mt={1}></MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={3} sx={{ width: '100%' }}>
            <MDBox
              component="form"
              role="form"
              onSubmit={handleSignInButtonClick}>
              <MDBox mb={1}>
                <MDTypography style={{ color: 'white' }}>
                  ユーザーID:
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="username"
                  required
                  fullWidth
                  id="username"
                  name="username"
                  value={formData.username}
                  autoComplete="username"
                  onChange={handleInput}
                  sx={{ input: { color: 'black !important' } }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MDBox>
              <MDBox mb={1}>
                <MDTypography style={{ color: 'white' }}>
                  パスワード:
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  required
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  value={formData.password}
                  autoComplete="current-password"
                  onChange={handleInput}
                  sx={{ input: { color: 'black !important' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          color="info">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: classes.input,
                  }}
                />
              </MDBox>
              {showWrongPasswordWarning && (
                <Typography
                  variant="body2"
                  fontWeight="light"
                  style={{ color: 'red' }}>
                  ユーザーID・パスワードが一致しません。
                </Typography>
              )}
              {showOTPInputBox.ifShow && (
                <div>
                  <MDBox mb={1}>
                    {showOTPInputBox.otpType === 'EMAIL' && (
                      <MDTypography style={{ color: 'white' }}>
                        お客様のメールアドレスにコードが送信されました。
                      </MDTypography>
                    )}
                    {showOTPInputBox.otpType === 'EMAIL' && (
                      <MDTypography style={{ color: 'white' }}>
                        以下にコードを入力してください：
                      </MDTypography>
                    )}
                    {showOTPInputBox.otpType === 'TOTP' && (
                      <MDTypography style={{ color: 'white' }}>
                        以下にワンタイムパスワードを入力してください：
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      required
                      fullWidth
                      id="OTP"
                      name="OTP"
                      autoComplete=""
                      onChange={handleInput}
                      sx={{ input: { color: 'black !important' } }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </MDBox>
                  {showWrongOTPWarning && (
                    <div>
                      <Typography
                        variant="body2"
                        fontWeight="light"
                        style={{ color: 'red' }}>
                        認証コードが無効です。正しい認証コードを入力してください。
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="light"
                        style={{ color: 'red' }}>
                        認証コードの試行回数は3回までです。
                      </Typography>
                    </div>
                  )}
                  {wrongOTPFor3Times && (
                    <Typography
                      variant="body2"
                      fontWeight="light"
                      style={{ color: 'red' }}>
                      認証コードが無効です。もう一度やり直してください。
                    </Typography>
                  )}
                </div>
              )}
              <MDBox mt={4} mb={1}>
                <MDLoadingButton
                  loadingPosition="start"
                  loading={isSigningIn}
                  disabled={isSigningIn}
                  type="submit"
                  variant="gradient"
                  // color="info"
                  fullWidth
                  // sx={{backgroundColor: "#C81414"}}
                  sx={{
                    backgroundColor: '#194796',
                    '&:hover': {
                      backgroundColor: '#194796',
                    },
                    '&:disabled': {
                      backgroundColor: '#194796',
                    },
                  }}>
                  <MDTypography variant="h5" fontWeight="bold" color="white">
                    {isSigningIn ? 'お待ちください...' : 'ログイン'}
                  </MDTypography>
                </MDLoadingButton>
              </MDBox>
              {!showOTPInputBox.ifShow && (
                <MDBox mt={2} mb={1}>
                  <Link to="/forgot-password">
                    <MDTypography
                      component="span"
                      variant="body2"
                      style={{ color: 'white' }}>
                      パスワードをお忘れの場合
                    </MDTypography>
                  </Link>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        </Card>
        <br />
      </BasicLayout>
    );
  } else {
    return (
      <BasicLayout image={bgImage} loginMode={loginMode}>
        <Card id="BasicLayoutChildrenCard" sx={{ backgroundColor: '#8B8B8B' }}>
          <MDBox
            variant="gradient"
            // bgColor="info"
            sx={{ backgroundColor: '#C81414' }}
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center">
            <MDTypography variant="h5" fontWeight="bold" color="white" mt={1}>
              医療情報クラウドプラットフォーム
            </MDTypography>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              sx={{ mt: 1, mb: 2 }}>
              <Grid item xs={6}>
                <MDTypography
                  variant="h5"
                  fontWeight="medium"
                  color="white"
                  mt={1}></MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={3} sx={{ width: '100%' }}>
            <MDBox
              component="form"
              role="form"
              onSubmit={handleSignInButtonClick}>
              <MDBox mb={1}>
                <MDTypography style={{ color: 'white' }}>
                  ユーザーID:
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="username"
                  required
                  fullWidth
                  id="username"
                  name="username"
                  value={formData.username}
                  autoComplete="username"
                  onChange={handleInput}
                  sx={{ input: { color: 'black !important' } }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MDBox>
              <MDBox mb={1}>
                <MDTypography style={{ color: 'white' }}>
                  パスワード:
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  required
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  value={formData.password}
                  autoComplete="current-password"
                  onChange={handleInput}
                  sx={{ input: { color: 'black !important' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          color="info">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: classes.input,
                  }}
                />
              </MDBox>
              {showWrongPasswordWarning && (
                <Typography
                  variant="body2"
                  fontWeight="light"
                  style={{ color: 'red' }}>
                  ユーザーID・パスワードが一致しません。
                </Typography>
              )}
              {showOTPInputBox.ifShow && (
                <div>
                  <MDBox mb={1}>
                    {showOTPInputBox.otpType === 'EMAIL' && (
                      <MDTypography style={{ color: 'white' }}>
                        お客様のメールアドレスにコードが送信されました。
                      </MDTypography>
                    )}
                    {showOTPInputBox.otpType === 'EMAIL' && (
                      <MDTypography style={{ color: 'white' }}>
                        以下にコードを入力してください：
                      </MDTypography>
                    )}
                    {showOTPInputBox.otpType === 'TOTP' && (
                      <MDTypography style={{ color: 'white' }}>
                        以下にワンタイムパスワードを入力してください：
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      required
                      fullWidth
                      id="OTP"
                      name="OTP"
                      autoComplete=""
                      onChange={handleInput}
                      sx={{ input: { color: 'black !important' } }}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </MDBox>
                  {showWrongOTPWarning && (
                    <div>
                      <Typography
                        variant="body2"
                        fontWeight="light"
                        style={{ color: 'red' }}>
                        認証コードが無効です。正しい認証コードを入力してください。
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="light"
                        style={{ color: 'red' }}>
                        認証コードの試行回数は3回までです。
                      </Typography>
                    </div>
                  )}
                  {wrongOTPFor3Times && (
                    <Typography
                      variant="body2"
                      fontWeight="light"
                      style={{ color: 'red' }}>
                      認証コードが無効です。もう一度やり直してください。
                    </Typography>
                  )}
                </div>
              )}
              <MDBox mt={4} mb={1}>
                <MDLoadingButton
                  loadingPosition="start"
                  loading={isSigningIn}
                  disabled={isSigningIn}
                  type="submit"
                  variant="gradient"
                  // color="info"
                  fullWidth
                  // sx={{backgroundColor: "#C81414"}}
                  sx={{
                    backgroundColor: '#C81414',
                    '&:hover': {
                      backgroundColor: '#C81414',
                    },
                    '&:disabled': {
                      backgroundColor: '#C81414',
                    },
                  }}>
                  <MDTypography variant="h5" fontWeight="bold" color="white">
                    {isSigningIn ? 'お待ちください...' : 'ログイン'}
                  </MDTypography>
                </MDLoadingButton>
              </MDBox>
              {!showOTPInputBox.ifShow && (
                <MDBox mt={2} mb={1}>
                  <Link to="/forgot-password">
                    <MDTypography
                      component="span"
                      variant="body2"
                      style={{ color: 'white' }}>
                      パスワードをお忘れの場合
                    </MDTypography>
                  </Link>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        </Card>
        <br />
        <MDBox alignItems="center" justifyContent="center">
          {/* <MDTypography variant="body2">
            本実証実験で提供するサービスは政府情報システムのための
          </MDTypography>
          <MDTypography variant="body2">
            セキュリティ評価制度（ISMAP：Information system Security Management and Assessment Program）で認定された
          </MDTypography>
          <MDTypography variant="body2">
            安全なクラウドサービスプロバイダー基盤で稼働しています
          </MDTypography> */}
          <p style={{ textAlign: 'center', fontSize: '60%' }}>
            本実証実験で提供するサービスは政府情報システムのためのセキュリティ評価制度（ISMAP：Information
            system Security Management and Assessment
            Program）で認定された安全なクラウドサービスプロバイダー基盤で稼働しています
          </p>
        </MDBox>
      </BasicLayout>
    );
  }
}

export default Basic;

import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';
const propTypes = {
  invoiceTotalAmount: PropTypes.object,
  invoiceDue: PropTypes.string,
};

const InvoiceTotalAmountAndDue = ({ invoiceTotalAmount, invoiceDue }) => {
  const invoiceTotalAmountPrice = invoiceTotalAmount.totalPrice;

  return (
    <Fragment>
      <View style={styles.invoiceTotalAmountAndDue}>
        <View style={styles.invoiceTotalAmountAndDueLeft}>
          <Text>
            {intl.get('billing_invoice_text_billing_amount') +
              invoiceTotalAmountPrice +
              intl.get('billing_invoice_text_tax_included')}
          </Text>
        </View>
        <View style={styles.invoiceTotalAmountAndDueRight}>
          <Text>{intl.get('billing_invoice_text_due_date') + invoiceDue}</Text>
        </View>
      </View>
    </Fragment>
  );
};
InvoiceTotalAmountAndDue.propTypes = propTypes;
export default InvoiceTotalAmountAndDue;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React examples
import DefaultNavbar from 'pages/parts/Navbar/DefaultNavbar';
import PageLayout from 'pages/parts/LayoutContainers/PageLayout';

// Authentication pages components
import Footer from 'pages/Welcome/components/Footer';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import themeDark from 'assets/theme-dark';

function BasicLayout({ image, loginMode, children }) {
  let MarubeniHeader;
  let MarubeniMaxHeight;
  let Marubenisx;
  if (loginMode === 1) {
    MarubeniHeader = (
      <DefaultNavbar id="BasicLayoutPageLayoutDefaultNavbar" transparent />
    );
    MarubeniMaxHeight = '70vh';
    Marubenisx = { background: '#F0F0F0' };
  }
  return (
    <ThemeProvider theme={themeDark}>
      <CssBaseline />
      <PageLayout>
        {MarubeniHeader}
        <MDBox
          id="BasicLayoutBackgourndBox"
          position="absolute"
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.0),
                rgba(gradients.dark.state, 0.0)
              )}, url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: '-1',
          }}
        />

        {/* <MDBox id="BasicLayoutChildrenContainerBox" px={1} width="100%" height="100vh" mx="auto"> */}
        <MDBox
          id="BasicLayoutChildrenContainerBox"
          px={0}
          py={'15vh'}
          width="100%"
          height="100vh"
          mx="auto">
          {/* <Grid id="BasicLayoutChildrenGridOuter" container spacing={1} justifyContent="center" alignItems="center" height="100%"> */}
          <Grid
            id="BasicLayoutChildrenGridOuter"
            container
            maxHeight={MarubeniMaxHeight}
            sx={Marubenisx}
            spacing={1}
            justifyContent="center"
            alignItems="center"
            height="100%">
            {/* <Grid id="BasicLayoutChildrenGridInner" item xs={11} sm={9} md={5} lg={4} xl={3}> */}
            <Grid id="BasicLayoutChildrenGridInner" item xs={11} sm={9} md={5}>
              {children}
            </Grid>
            {/* {children} */}
          </Grid>
        </MDBox>
        <Footer id="Footer" loginMode={loginMode} light />
      </PageLayout>
    </ThemeProvider>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  loginMode: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;

import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import {
  Grid,
  Tooltip,
  IconButton,
  Popover,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@material-ui/core';
import MoneyIcon from '@mui/icons-material/Money';
import CloseIcon from '@mui/icons-material/Close';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ExposureIcon from '@mui/icons-material/Exposure';

import { updateIssue } from 'pages/Dashboard/Billing/shared/api';

import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.object,
  data: PropTypes.object,
  setData: PropTypes.func,
};

const DashboardBillingForReceivedCasesDetailedTableCustomToolbarSelectFeeAdjustment =
  ({ selectedRows, data, setData }) => {
    const classes = useStyles();

    const [values, setValues] = useState({
      difference: 0,
      discount: 1,
    });
    const [popoverOpen, setPopoverOpen] = useState(false);
    const anchorEl = useRef(null);

    const handleIconButtonClick = (event) => {
      anchorEl.current = event.currentTarget;
      setPopoverOpen(true);
    };

    const handlePopoverClose = () => {
      setPopoverOpen(false);
    };

    const transformOriginSpecs = {
      vertical: 'top',
      horizontal: 'center',
    };

    const anchorOriginSpecs = {
      vertical: 'bottom',
      horizontal: 'center',
    };

    const handleOnExit = () => {};

    const handleApply = () => {
      // alert('values ' + values.difference + values.discount);
      const dataIndice = selectedRows.data.map((row) => {
        return row.dataIndex;
      });

      const newData = data;
      dataIndice.forEach((dataIndex) => {
        newData[dataIndex][6].currentPrice =
          newData[dataIndex][6].originalPrice * values.discount +
          values.difference;
        const price = newData[dataIndex][6].originalPrice;
        const waive = newData[dataIndex][6].waive;
        const discount = values.discount;
        const difference = values.difference;
        newData[dataIndex][6].currentPrice = waive
          ? 0
          : price * discount + difference;
        const issueId = newData[dataIndex][7];
        updateIssue(issueId, { discount: discount, difference: difference });
      });

      setData([...newData]);
    };

    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: Number(event.target.value) });
    };

    return (
      <Fragment>
        <Tooltip
          className={classes.tooltip}
          title={intl.get(
            'billing_for_received_cases_toolbar_select_tooltip_fee_adjustment'
          )}>
          <IconButton
            className={classes.iconButton}
            onClick={(event) => {
              handleIconButtonClick(event);
            }}>
            <MoneyIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        <Popover
          elevation={2}
          open={popoverOpen}
          TransitionProps={{ onExited: handleOnExit }}
          onClose={handlePopoverClose}
          anchorEl={anchorEl.current}
          anchorOrigin={anchorOriginSpecs}
          transformOrigin={transformOriginSpecs}>
          <IconButton
            aria-label="Close"
            onClick={handlePopoverClose}
            className={classes.closeIconButton}>
            <CloseIcon />
          </IconButton>
          <Grid container className={classes.popoverContentRoot}>
            <Grid
              container
              item
              className={classes.popoverContent}
              xs={12}
              spacing={1}>
              <Grid item>
                <Tooltip
                  className={classes.tooltip}
                  title={intl.get(
                    'billing_for_received_cases_toolbar_select_tooltip_invoice'
                  )}>
                  <ExposureIcon className={classes.icon} fontSize="large" />
                </Tooltip>
              </Grid>
              <Grid item className={classes.input}>
                <FormControl size="small">
                  <OutlinedInput
                    size="small"
                    type="number"
                    placeholder={values.difference}
                    defaultValue={values.difference}
                    startAdornment={
                      <InputAdornment position="start">￥</InputAdornment>
                    }
                    onChange={handleChange('difference')}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleApply}>
                  Apply
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              item
              className={classes.popoverContent}
              xs={12}
              spacing={1}>
              <Grid item>
                <Tooltip
                  className={classes.tooltip}
                  title={intl.get(
                    'billing_for_received_cases_toolbar_select_tooltip_invoice'
                  )}>
                  <HighlightOffIcon className={classes.icon} fontSize="large" />
                </Tooltip>
              </Grid>
              <Grid item className={classes.input}>
                <FormControl size="small">
                  <OutlinedInput
                    size="small"
                    type="number"
                    placeholder={values.discount}
                    defaultValue={values.discount}
                    startAdornment={
                      <InputAdornment position="start">×</InputAdornment>
                    }
                    onChange={handleChange('discount')}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleApply}>
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
      </Fragment>
    );
  };

DashboardBillingForReceivedCasesDetailedTableCustomToolbarSelectFeeAdjustment.propTypes =
  propTypes;

export default withRouter(
  DashboardBillingForReceivedCasesDetailedTableCustomToolbarSelectFeeAdjustment
);

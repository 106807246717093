import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
// import ReactDOM from "react-dom";
import ReactHtmlParser from 'react-html-parser'; // processNodes // convertNodeToElement,

import api from 'shared/utils/api';
// import { formatBytes } from "shared/utils/misc";
import { formatDateTimeConversational } from 'shared/utils/dateTime';
import { ConfirmModal } from 'components';
// import { Button } from 'components';
import localData from 'shared/utils/localData';
// import { Grid, Typography } from '@mui/material';
// import BodyForm from '../BodyForm';

import {
  Comment,
  UserAvatar,
  Content,
  Username,
  CreatedAt,
  Body,
  // EditLink,
  DeleteLink,
  // Attachment,
} from './Styles';
// import axios from 'axios';

import './styles.css';

const propTypes = {
  comment: PropTypes.object.isRequired,
  fetchIssue: PropTypes.func.isRequired,
  handleDownloadButtonClick: PropTypes.func,
  handlePreviewButtonClick: PropTypes.func,
  handleBlockQuoteTextClick: PropTypes.func,
};

const ProjectBoardIssueDetailsComment = ({
  comment,
  fetchIssue,
  handleDownloadButtonClick,
  handlePreviewButtonClick,
  handleBlockQuoteTextClick,
}) => {
  // const [ GetObjectPresignedURL, setGetObjectPresignedURL ] = useState('');
  // const [ IsDownloading, setIsDownloading ] = useState(false);
  const username = localData.get('username');
  const currentOrganizationId = localData.get('currentOrganizationId');
  const preferredLanguage = localData.get('userPreferredLanguage');
  const userFullName =
    preferredLanguage === 'en-US'
      ? comment.user.firstName + ' ' + comment.user.lastName
      : comment.user.lastName + ' ' + comment.user.firstName;

  const handleCommentDelete = async () => {
    try {
      await api.delete(`/api/comments/${comment.id}`, {
        username: username,
        organizationId: currentOrganizationId,
        params: {
          organizationId: currentOrganizationId,
        },
      });
      await fetchIssue();
    } catch (error) {
      // toast.error(error);
    }
  };

  const onBlockquoteTextClick = (e) => {
    handleBlockQuoteTextClick(e.target.innerText);
  };

  const transform = (node, index) => {
    // return null to block certain elements.
    // e.g. don't allow <span> elements:
    if (node.type === 'tag' && node.name === 'span') {
      return null;
    }
    if (node.type === 'tag' && node.name === 'blockquote') {
      return (
        <blockquote className="blockquote">
          {/* {processNodes(node.children, transform)} */}
          <div className="blockquoteText" onClick={onBlockquoteTextClick}>
            {node.children[0].data}
          </div>
        </blockquote>
      );
    }
  };

  const ReactHtmlParserOptions = {
    decodeEntities: true,
    transform,
  };

  // const handleDownloadButtonClick = async (attachment) => {
  //   try {
  //     setIsDownloading(true);
  //     const apiVariables = {
  //       params: {
  //         issueId: issue.id,
  //         username: username,
  //         organizationId: currentOrganizationId,
  //       }
  //     };

  //     const data = await api.get(`/api/comments/download-attachment/${comment.id}`, apiVariables);

  //     axios({
  //       url: data.GetObjectPresignedURL,
  //       method: 'GET',
  //       responseType: 'blob', // important
  //     }).then((response) => {
  //         const filename = attachment.title
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', filename);
  //         document.body.appendChild(link);
  //         link.click();
  //         setIsDownloading(false);
  //     });

  //   } catch (error) {
  //     setIsDownloading(false);
  //     alert(`Problem when downloading attachment:\n
  //           message: ${JSON.stringify(error.message)} \n
  //           status: ${JSON.stringify(error.status)} \n`);
  //   }
  // }

  // const isObject = (objValue) => {
  //   return objValue && typeof objValue === 'object' && objValue.constructor === Object;
  // }

  // const dicomFilePaths = {};

  return (
    <Comment data-testid="issue-comment">
      {/* <UserAvatar name={comment.user.username} avatarUrl={comment.user.avatarUrl} /> */}
      <UserAvatar name={userFullName} />
      <Content>
        <Username>
          {userFullName +
            ' ' +
            (comment.domainType === 'LOCAL'
              ? intl.get('issue_details_comment_label_published_locally')
              : intl.get('issue_details_comment_label_published_globally'))}
        </Username>
        <CreatedAt>{formatDateTimeConversational(comment.createdAt)}</CreatedAt>

        <Fragment>
          <Body>{ReactHtmlParser(comment.body, ReactHtmlParserOptions)}</Body>

          <br />
          {username === comment.user.username && (
            <ConfirmModal
              title={intl.get(
                'issue_details_confirm_modal_text_delete_comment_title'
              )}
              message={intl.get(
                'issue_details_confirm_modal_text_delete_comment_message'
              )}
              confirmText={intl.get(
                'issue_details_confirm_modal_button_delete_comment_confirm_text'
              )}
              cancelText={intl.get(
                'issue_details_confirm_modal_button_delete_comment_cancel_text'
              )}
              onConfirm={handleCommentDelete}
              renderLink={(modal) => (
                <DeleteLink onClick={modal.open}>Delete</DeleteLink>
              )}
            />
          )}
        </Fragment>
      </Content>
    </Comment>
  );
};

ProjectBoardIssueDetailsComment.propTypes = propTypes;

export default ProjectBoardIssueDetailsComment;
